.d-flex{display: flex !important;}
.p-relative{position: relative !important;}
.c-pointer{cursor: pointer !important;}
.c-help{cursor: help !important;}
.d-block{display: block !important;}
.l-height-normal{line-height: normal !important;}

/*----Font Sizes----*/
.breadcrum-title{font-size: 18px !important;font-weight: 600 !important;color: var(--textWhite) !important;}
.breadcrum-screen{color: var(--textRegular) !important;font-weight: 500 !important;font-size: 18px !important;}
.card-title{color: var(--textPrimary) !important;}
.input-text{font-size: 60px !important;font-weight: 600 !important;color: var(--textWhite) !important;}
.placeholder-text{font-size: 22px !important;font-weight: 700 !important;color: var(--placeholderText)!important;}
.rp-balance{font-size: 30px !important;font-weight: 600 !important;color: var(--textWhite)!important;}
.btn-style{font-weight: 600 !important;text-transform: uppercase !important;background: linear-gradient(89.35deg,  #6613BA 19.31%,   #2C00AA 51.88%);
  border-radius: 8px !important;height: 67px !important;border: 0;color: var(--textWhite);display: flex;justify-content: space-between;padding: 0 30px;align-items: center;margin: 10px auto 13px;}
.summary-label{color: var(--textSecondary) !important;font-size: 16px !important;font-weight: 400 !important;text-align: left;width: 200px;}
.summary-text{font-size: 16px !important;font-weight: 400 !important;color: var(--textPrimary) !important;text-align: right;word-break: break-all;}
.summary-total{font-size: 18px !important;font-weight: 600 !important;color: var(--textPrimary)!important}
.sumamry-totaltext{font-size: 24px !important;font-weight: 700 !important;color: var(--textPrimary)!important}
.note-title{font-size: 18px !important;font-weight: 600 !important;color: var(--textPrimary) !important;}
.note-text{font-weight: 500 !important;font-size: 14px !important;color: var(--textSecondary) !important;}
.thankyou-title{font-weight: 700 !important;font-size: 40px !important;color: var( --textGreen) !important;}
.thankyou-description{font-size: 16px !important;font-weight: 400 !important; color: #F1F1F1 !important;}
.thankyou-link{font-size: 16px !important;font-weight: 600 !important;text-transform: uppercase !important;color: var(--textLink) !important;}
.estimate-text{font-size: 16px !important;font-weight: 400 !important;border: 1px solid #f1f1f1;padding: 4px 12px ;margin: 8px 0;display: inline-block;border-radius: 8px;cursor: pointer;}
.apps-menu-title{font-size: 18px !important;font-weight: 600 !important;}
.apps-menu-description{font-size: 14px !important;font-weight: 400 !important;}

/*--Font Weights--*/
.fw-100{font-weight: 100 !important;}
.fw-200{font-weight: 200 !important;}
.fw-300{font-weight: 300 !important;}
.fw-400{font-weight: 400 !important;}
.fw-500{font-weight: 500 !important;}
.fw-600{font-weight: 600 !important;}

/*--Alignments--*/
.text-center{text-align: center !important;}
.text-right{text-align: right !important;}
.text-left{text-align: left !important;}
.text-upper{text-transform: uppercase !important;}
.text-captz{text-transform: capitalize !important;}
.align-center{align-items: center;}
.justify-content{justify-content: space-between;}

/*--Text Colors--*/
.text-primary{color: var(--textPrimary) !important;}
.text-secondary{color: var(--textSecondary) !important;}
.text-white{color: var(--textWhite) !important;}
.text-textDark{color: var(--textBlack) !important;}
.text-green{color: var(--textGreen) !important;}
.text-red{color: var(--textRed) !important;}
.text-blue{color: var(--textBlue) !important;}
.bggrey{background-color: var(--bgGrey) !important;}


/*----Padding Utilities----*/
.p-0{padding: 0 !important;}
.pr-0{padding-right: 0 !important;}
.pb-0{padding-bottom: 0 !important;}
.p-16{padding: 16px !important;}
.p-24{padding: 24px !important;}
.px-8{padding-left: 8px !important;padding-right: 8px !important;}
.px-15{padding-left: 15px !important;padding-right: 15px !important;}
.py-24{padding-top: 24px !important;padding-bottom: 24px !important;}

/*----Width Utilities----*/
.w-fit{width: fit-content !important;}
.w-75{width: 75% !important;}
/*----Margin Utilities----*/
.m-0{margin: 0 !important;}
.mb-0{margin-bottom: 0 !important;}
.mb-24{margin-bottom: 24px !important;}
.mb-8{margin-bottom: 8px !important;}
.m-16{margin: 16px !important;}
.mt-16{margin-top: 16px !important;}
.mt-30{margin-top: 16px !important;}
.m-24{margin: 24px !important;}
.mx-15{margin-left: 15px !important;margin-right: 15px !important;}
.my-24{margin-top: 24px !important;margin-bottom: 24px !important;}
.my-10{margin-top: 10px !important;margin-bottom: 10px !important;}
.gap-16{gap: 16px;}
.mr-16{margin-right: 16px;}
.mr-8{margin-right: 8px;}

@media (max-width: 991px){
    .visible-xs{visibility: visible;}
    /* .hidden-xs{visibility: hidden;} */
}