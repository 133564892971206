/* @import url('../fonts/stylesheet.css'); */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,400&display=swap');
* {
	scrollbar-width: thin;
	scrollbar-color: var(--bgGrey) var(--bgDarkGrey);
  }
  *::-webkit-scrollbar {
	width: 12px;
	height: 10px;
  }
  *::-webkit-scrollbar-thumb {
	border-radius: 20px;
  }
  html,
  .auto-scroll,
  .ant-table-body,
  .ant-drawer-body,
  .mobile-list,
  .k-grid-content {
	scrollbar-color: #757f9c transparent;border-radius: 8px;
  }
body, h1, h2, h3, h4, h5, h6, a, p div{font-family: 'Lato', sans-serif !important;}
.ant-layout, body { background: url(../images/body-bg.svg); background-attachment: fixed; background-position: 50%; background-repeat: no-repeat; background-size: cover;}
.icon, .ant-page-header-back .anticon-arrow-left,
.ant-custumcheck .ant-checkbox-input + span,
.custom-checkbox input[type="checkbox"] + span,
.custom-checkbox input[type="checkbox"]:checked + span,
.k-grouping-row .k-icon,
.k-icon.k-i-more-vertical,.ant-modal-close-x,
.checkbox-active input[type="checkbox"] + span,
.checkbox-active input[type="checkbox"]:checked + span, .ant-checkbox-inner, .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {display: inline-block;background: var(--darkThemeIcons);vertical-align: middle;}
/* xs */
.icon.xs {width: 12px;height: 12px;}
.icon.xs.greenarrow {background-position: -183px -146px;}
.coin, .icon{flex-shrink: 0;}
/* sm */
.icon.sm {width: 18px;height: 18px;}
.icon.sm.switch-icon{background-position: -6px -32px;}
.icon.sm.bc-arrow{background-position: -314px -92px;}
.icon.sm.card{background-position:-63px -7px;}
.icon.sm.file{background-position: -92px -5px;}
.icon.sm.copy {background-position:-321px -117px;}
.icon.sm.open-window{background-position:-57px -143px;}
.icon.sm.close-window{background-position:-31px -143px;}
.icon.sm.down-angle{background-position: -226px -123px; transform: rotate(180deg);}
.ant-modal-close-x{background-position: -346px -187px;width: 26px;height: 26px;}

/* md */
.icon.md{width: 20px;height: 20px;}
.icon.md.arrow {background-position: -6px -116px;}
.icon.md.historyarrow.Cancelled{background-position: -59px -30px;}
.icon.md.historyarrow.Success{background-position: -30px -30px;}
.icon.md.historyarrow.Pending{background-position: -88px -30px;}
.icon.md.uparrow{background-position:-416px -190px;transform: scale(1.3);}
/* .icon.md.deposit{background-position:-479px -190px;} */
/* .icon.md.deposit:hover{background-position:-479px -222px;} */
.icon.md.withdraw { background-position: -478px -89px; -webkit-transform: rotate(-36deg); transform: rotate(-36deg);}
/* .icon.md.withdraw{background-position:-504px -192px;}
.icon.md.withdraw:hover{background-position:-504px -224px;} */
.icon.md.checkoutline{background-position: -118px -303px;transform: scale(0.7);width: 26px;height: 26px;margin-right: 8px;}
.icon.md.checkoutline.active{background-position: -159px -303px;}
/* lg */
/* .icon.lg {width: 26px;height: 26px;} */
.icon.lg.right-arrow{background-position:-302px -186px;}
.icon.lg.settings-icon {background-position:-49px -356px;}
.icon.lg.refresh {background-position: -100px -82px;}
.icon.lg.bell {background-position:-73px -86px;}
.icon.lg.hamburg-icon{background-position: -43px -89px;}
.icon.lg.menu{background-position: -8px -9px;}
.icon.lg.add-icon{background-position: -187px -82px;transform: rotate(45deg);}
.icon.lg.close {background-position: -346px -187px;transform: scale(0.8);}
.icon.lg.copy{background-position: -120px -10px; height: 22px; width: 18px;}
.icon.lg.wallet{background-position:-81px -247px;transform: scale(1.5);margin-right: 16px;margin-left: 8px;}
/* xxl */
.icon.xxl {width: 46px;height: 46px;}
.icon.xxl.in{background-position: 0px -170px;}
.icon.xxl.google{background-position: -102px -170px;}
.icon.xxl.twitter{background-position: -40px -170px;}
.icon.lg.whatsapp{background-position: -382px -145px;}
.icon.lg.whatsapp:hover{  background-position: -628px -145px;}
.icon.lg.mail{background-position: -447px -146px;}
.icon.lg.mail:hover{background-position: -693px -146px;}
.icon.xxl.copy{background-position: -187px -170px;}
.ant-page-header-back .anticon-arrow-left{width: 20px;height:20px;background-position: -4px -140px; transform: scale(1.2);}
.ant-page-header-back .anticon-arrow-left svg{display: none;}
.add-transfer{float: right;background: var(--bgLightGray);padding: 10px;border-radius: 8px;margin: 12px 16px 12px 0}
.coin{background: var(--cryptoIcons) no-repeat;display: inline-block;}
.icon.md.pending-arrow {background-position: -63px -32px;}
.icon.md.success-arrow { background-position: -31px -38px;}
.icon.sm.filter{ background-position: -428px -92px;}
.icon.sm.wallet{background-position:-81px -248px;margin-right: 16px;width: 25px;height: 25px;}
.icon.lg.withdraw-arrow{background-position: -475px -188px;margin-right: 16px;}
.icon.lg.deposit-arrow{background-position: -502px -188px;margin-right: 30px;}
.icon.xxl.uparrow{background-position:-95px -617px;transform: scale(0.5);transform: rotate(138deg) !important;}
.customicon:hover .icon.xxl.uparrow{background-position:-95px -550px;transform: scale(0.5);}
.icon.xxl.downarrow{background-position:-266px -618px;transform: scale(0.5); transform: rotate(142deg) !important;}
.customicon:hover .icon.xxl.downarrow{background-position:-266px -551px;transform: scale(0.5);}
.icon.xxl.transactions{background-position:-415px -623px}
.customicon:hover .icon.xxl.transactions{background-position:-415px -558px}
.icon.xxl.batchpayout{background-position:-498px -619px}
.customicon:hover .icon.xxl.batchpayout{background-position:-498px -562px}
.icon.md.menubar { background-position: -725px -147px;}
.icon.md.buy { background-position: -453px -89px;}
.icon.md.sell {background-position: -423px -89px;}
.icon.md.deposit {background-position: -502px -89px;transform: rotate(-36deg); }
.icon.lg.notification { background-position: -77px -88px;position: relative;}
.head-menutogle .count-note:last-child {background: #ff0000; color: #fff;  display: flex;justify-content: center;align-items: center; border-radius: 15px;font-size: 10px;height: 18px;width: 18px;position: absolute;top: 6px;right: -4px;}
/* .icon.lg.withdraw-arrow:hover{background-position: -475px -220px;}
.icon.lg.deposit-arrow:hover{background-position: -502px -220px;} */
/* sm */
.coin.sm.btc.static{background-position: -11.2px -533px; transform: scale(1.2);}
/* .coin.sm{width: 25px;height: 25px;} */
.coin.sm.btc{background-position: -11.2px -533px;}
.coin.sm.eth{background-position:-44px -749px;}
.coin.sm.xrp{background-position:-175px -749px;}
.coin.sm.usdt{background-position:  -76px -749px;}
.coin.sm.usdc{background-position: -109px -749px;}
.coin.sm.usd{background-position: -172px -3px;}
.coin.sm.chf{background-position: -174.3px -571px;}
.coin.sm.eur{background-position: -194px -3px;}
.coin.sm.gbp{background-position: -501.3px -533px;}
.coin.sm.ltc{background-position:-142px -789px;}
.coin.sm.mkr{background-position: -371px -831px;}
.coin.sm.dot{background-position:-240px -749px;}
.coin.sm.doge{background-position:-273px -749px;}
.coin.sm.ada{background-position: -207px -749px;}
.coin.sm.kraken{background-position: -532px -533px;}
.icon.sm.green-arrow{background-position: -332px -86px;}
.cust-xxl.crypto.coin.sm.kraken{background-position: -532px -533px;transform: scale(1.8);}


.coin-item.active .coin.sm.eth{background-position:  -44px -533px;}
.coin-item.active .coin.sm.btc{background-position:  -11px -533px;}
.coin-item.active .coin.sm.xrp{background-position:-175px -533px;}
.coin-item.active .coin.sm.usdt{background-position:  -76.5px -533px;}
.coin-item.active .coin.sm.usdc{background-position: -109.3px -533px;}
.coin-item.active .coin.sm.usd{background-position: -44px -571px;}
.coin-item.active .coin.sm.gbp{background-position: -501.3px -533px;}
.coin-item.active .coin.sm.eur{background-position: -469px -533px;}
.coin-item.active .coin.sm.ltc{background-position:-142px -571px;}
.coin-item.active .coin.sm.mkr{background-position: -371px -612.5px;}
.coin-item.active .coin.sm.dot{background-position: -240px -533px;}
.coin-item.active .coin.sm.doge{background-position: -273px -533px;}
.coin-item.active .coin.sm.ada{background-position: -207.5px -533px;}
.coin-item.active .icon.sm.green-arrow{background-position: -332px -86px;}


/*Coin -  md */
.coin.md ,.coin-droupdown .ant-select-selection-item .coin.sm{width: 36px;height: 36px;}
.coin.md.btc ,.coin-droupdown .ant-select-selection-item .coin.sm.btc{background-position: -4px -169px;}
.coin.md.eth,.coin-droupdown .ant-select-selection-item .coin.sm.eth{background-position: -81px -167px;}
.coin.md.xrp,.coin-droupdown .ant-select-selection-item .coin.sm.xrp{background-position: -674px -167px}
.coin.md.usdt,.coin-droupdown .ant-select-selection-item .coin.sm.usdt{background-position: -437px -167px;}
.coin.md.usdc,.coin-droupdown .ant-select-selection-item .coin.sm.usdc{background-position: -398px -167px;}
.coin.md.btc{background-position: -4px -739px;transform: scale(2);}
/* .icon.md.search{background-position:-147px -113px;} */
.cust-lg.coin.md.eth{background-position: -83px -169px;transform: scale(1.5);} 
.cust-lg.md.btc{background-position: -3px -745px;transform: scale(2);margin-right: 10px;}
.cust-lg.md.usd{background-position: -400px -169px; transform: scale(1.5);margin-right: 10px;}  
.cust-lg.md.gbp{background-position: -360px -169px;transform: scale(1.5);margin-right: 10px;} 
.cust-lg.md.eur{background-position: -281px -169px;transform: scale(1.5);margin-right: 10px;}
.cust-lg.md.usdc{background-position: -201px -169px;transform: scale(1.5);margin-right: 10px;} 
.cust-lg.md.usdt{background-position: -241px -167px;transform: scale(1.5);margin-right: 10px;}
.cust-lg.coin.md.mkr{background-position: -403px -118px;transform: scale(1.5);margin-right: 10px;}
.buy-sctcoin.coin.md.eur{background-position: -259px -38px;} 
.buy-sctcoin.coin.md.usd{background-position: -367px -38px;} 
/* .buy-sctcoin.coin.md.undefined{background-position: -9px -39px;}
.cust-lg.coin.md.undefined{background-position: -9px -39px;transform: scale(1.5);margin-right: 10px;} */
.buy-sctcoin.coin.md.eth{background-position: -78px -39px;}
.buy-sctcoin.coin.md.btc{background-position: -9px -39px;}
.buy-sctcoin.coin.md.usdt{background-position: -404px -39px;}
.buy-sctcoin.coin.md.usdc{background-position: -473px -76px;}
.buy-sctcoin.coin.md.xrp{background-position: -619px -39px;}
.buy-sctcoin.coin.md.ada{background-position: -403px -76px;}
.buy-sctcoin.coin.md.ltc{background-position: -9px -76px;}
.buy-sctcoin.coin.md.mkr{background-position: -403px -118px;}
.buy-sctcoin.coin.md.dot{background-position: -113px -39px;}
.buy-sctcoin.coin.md.doge{background-position: -508px -39px;}

/* lg */
.coin.lg{width: 52px;height: 52px; }
/* .coin.lg.btc{background-position: -9px -383px}
.coin.lg.etc{background-position: -9px -383px;}
.coin.lg.eur{background-position: -506px -383px;}
.coin.lg.usd{background-position: -721px -383px;}
.coin.lg.gbp{background-position: -650px -383px;}  */
/* .cust-lg.coin.lg.undefined{background-position: -8px -385px;} */
.cust-lg.coin.lg.btc{background-position: 16px -733px;transform: scale(2); margin-right: 36px;}
.cust-lg.coin.lg.eth{background-position: -151px -385px;}
.cust-lg.coin.lg.usdt{background-position: -79px -452px}
.cust-lg.coin.lg.usdc{background-position: -722px -383px;}
.cust-lg.coin.lg.xrp{background-position: -6px -452px;}
.cust-lg.coin.lg.ada{background-position: -221px -452px;}
.cust-lg.coin.lg.ltc{background-position: -293px -452px}
.cust-lg.coin.lg.mkr{background-position: -148px -452px;}
.cust-lg.coin.lg.dot{background-position: -222px -383px;}
.cust-lg.coin.lg.doge{background-position: -365px -452px;}
/* Icons End*/
/* Upload File */
.upload-style{min-height: 160px;width: 100%;border-radius: 12px;border: 1px dashed #ccc;color: #fff;overflow: hidden;background-color: #3f3e3e !important;padding: 16px;display: block !important;}
.upload-style:hover{color: #fFF !important;border-color: #fFF  !important;background: #3f3e3e !important;}
.card-upload .ant-upload-select{width: 100%;}
.gallery-image{margin: auto;display: block;}
.gallery-icon{margin: auto;display: block;width: 42px;filter: contrast(0.1);}
.upload-style:hover .gallery-icon{filter: contrast(1);}
.card-upload .ant-upload-list-item a{text-decoration: none;}
.card-upload .ant-upload-list-item-actions .anticon-delete svg{fill: #22D7FF !important;}
.card-upload .ant-upload-list-item-action{opacity: 1 !important;}
.card-upload .ant-upload-list-item {background-color: rgba(255, 255, 255, 0.2) !important;padding: 8px 16px ;border-radius: 16px;height: 52px !important;}

/*crypto coin icons  */
.active-coin.crypto.coin.sm.btc{background-position:-11px -533px;}
.active-coin.crypto.coin.sm.eth{background-position:-43px -533px;}
.active-coin.crypto.coin.sm.usdt{background-position:-76.5px -533px;}
.active-coin.crypto.coin.sm.usdc{background-position:-109.3px -533px;}
.active-coin.crypto.coin.sm.name{background-position:-142px -533px;}
.active-coin.crypto.coin.sm.xrp{background-position:-174.8px -533px;}
.active-coin.crypto.coin.sm.ada{background-position:-207.5px -533px;}
.active-coin.crypto.coin.sm.dot{background-position:-240px -533px;}
.active-coin.crypto.coin.sm.doge{background-position:-273px -533px;}
.active-coin.crypto.coin.sm.chz{background-position:-306px -533px;}
.active-coin.crypto.coin.sm.aurora{background-position:-338px -533px;}
.active-coin.crypto.coin.sm.xlm{background-position:-371px -533px;}
.active-coin.crypto.coin.sm.bch{background-position:-404px -533px;}
.active-coin.crypto.coin.sm.dnt{background-position:-436px -533px;}
.active-coin.crypto.coin.sm.eur{background-position:-469.3px -533px;}
/* .active-coin.crypto.coin.sm.name{background-position:-502px -533px;} */

/* .active-coin.crypto.coin.sm.name{background-position:-11px -571px;} */
.active-coin.crypto.coin.sm.usd{background-position:-43.9px -571px;}
.active-coin.crypto.coin.sm.gusd{background-position:-77px -571px;}
.active-coin.crypto.coin.sm.zil{background-position:-109px -571px;}
.active-coin.crypto.coin.sm.ltc{background-position:-142px -571px;}
.active-coin.crypto.coin.sm.fill{background-position:-175px -571px;}
.active-coin.crypto.coin.sm.amp{background-position:-207px -571px;}
.active-coin.crypto.coin.sm.vet{background-position:-240px -571px;}
.active-coin.crypto.coin.sm.busd{background-position:-273px -571px;}
.active-coin.crypto.coin.sm.theta{background-position:-306px -571px;}
/* .active-coin.crypto.coin.sm.name{background-position:-338px -571px;} */
.active-coin.crypto.coin.sm.link{background-position:-371px -571px;}
/* .active-coin.crypto.coin.sm.btcst{background-position:-404px -571px;} */
.active-coin.crypto.coin.sm.miota{background-position:-436px -571px;}
.active-coin.crypto.coin.sm.xdc{background-position:-502px -571px;}

/* .active-coin.crypto.coin.sm.name{background-position:-11px -613px;} */
.active-coin.crypto.coin.sm.eos{background-position:-44px -613px;}
.active-coin.crypto.coin.sm.shib{background-position:-77px -613px;}
.active-coin.crypto.coin.sm.aave{background-position:-109px -613px;}
.active-coin.crypto.coin.sm.noia{background-position:-142px -613px;}
/* .active-coin.crypto.coin.sm.name{background-position:-175px -613px;} */
.active-coin.crypto.coin.sm.ksm{background-position:-207px -613px;}
.active-coin.crypto.coin.sm.algo{background-position:-240px -613px;}
/* .crypto.coin.sm.atom{background-position:-273px -613px;} */
.active-coin.crypto.coin.sm.cake{background-position:-306px -613px;}
.active-coin.crypto.coin.sm.tfuel{background-position:-338px -613px;}
.active-coin.crypto.coin.sm.mkr{background-position:-371px -612.5px;}
.active-coin.crypto.coin.sm.rune{background-position:-404px -613px;}
/* .active-coin.crypto.coin.sm.name{background-position:-436px -613px;} */
.active-coin.crypto.coin.sm.dai{background-position:-469px -613px;}
.active-coin.crypto.coin.sm.xmr{background-position:-502px -613px;}

/* .active-coin.crypto.coin.sm.xmr{background-position:-11px -655px;} */
.active-coin.crypto.coin.sm.lunc{background-position:-44px -655px;}
.active-coin.crypto.coin.sm.avax{background-position:-77px -655px;}
.active-coin.crypto.coin.sm.saito{background-position:-109px -655px;}
/* .active-coin.crypto.coin.sm.btcst{background-position:-142px -655px;} */
.active-coin.crypto.coin.sm.undefined{background-position:-174px -873px;}
/* .active-coin.crypto.coin.sm.btcst{background-position:-142px -655px;} */
.active-coin.crypto.coin.sm.btcst{background-position:-142px -655px;}

/*HEADER xxl crypto icons md transform start*/
/* .cust-xxl.crypto.coin.md.btc{background-position: -5px -528px;transform: scale(1.8);} */

.cust-xxl.crypto.coin.sm.btc{background-position:-11.2px -533px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.eth{background-position:-44px -533px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.usdt{background-position:-76.5px -533px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.usdc{background-position:-109.3px -533px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.name{background-position:-142px -533px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.xrp{background-position:-174.8px -533px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.ada{background-position:-207.5px -533px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.dot{background-position:-240px -533px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.doge{background-position:-273px -533px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.chz{background-position:-306px -533px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.aurora{background-position:-338px -533px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.xlm{background-position:-371px -533px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.bch{background-position:-404px -533px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.dnt{background-position:-436px -533px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.eur{background-position:-469.3px -533px;transform: scale(1.8);}
/* .cust-xxl.crypto.coin.sm.name{background-position:-502px -533px;transform: scale(1.8);} */

/* .cust-xxl.crypto.coin.sm.name{background-position:-11px -571px;transform: scale(1.8);} */
.cust-xxl.crypto.coin.sm.usd{background-position:-43.9px -571px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.gusd{background-position:-77px -571px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.zil{background-position:-109px -571px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.ltc{background-position:-142px -571px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.fill{background-position:-175px -571px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.amp{background-position:-207px -571px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.vet{background-position:-240px -571px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.busd{background-position:-273px -571px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.theta{background-position:-306px -571px;transform: scale(1.8);}
/* .cust-xxl.crypto.coin.sm.name{background-position:-338px -571px;transform: scale(1.8);} */
.cust-xxl.crypto.coin.sm.link{background-position:-371px -571px;transform: scale(1.8);}
/* .cust-xxl.crypto.coin.sm.btcst{background-position:-404px -571px;transform: scale(1.8);} */
.cust-xxl.crypto.coin.sm.miota{background-position:-436px -571px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.xdc{background-position:-502px -571px;transform: scale(1.8);}

/* .cust-xxl.crypto.coin.sm.name{background-position:-11px -613px;transform: scale(1.8);} */
.cust-xxl.crypto.coin.sm.eos{background-position:-44px -613px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.shib{background-position:-77px -613px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.aave{background-position:-109px -613px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.noia{background-position:-142px -613px;transform: scale(1.8);}
/* .cust-xxl.crypto.coin.sm.name{background-position:-175px -613px;transform: scale(1.8);} */
.cust-xxl.crypto.coin.sm.ksm{background-position:-207px -613px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.algo{background-position:-240px -613px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.atom{background-position:-273px -613px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.cake{background-position:-306px -613px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.tfuel{background-position:-338px -613px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.mkr{background-position:-371px -612.5px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.rune{background-position:-404px -613px;transform: scale(1.8);}
/* .cust-xxl.crypto.coin.sm.name{background-position:-436px -613px;transform: scale(1.8);} */
.cust-xxl.crypto.coin.sm.dai{background-position:-469px -613px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.xmr{background-position:-502px -612px;transform: scale(1.8);}

/* .cust-xxl.crypto.coin.sm.xmr{background-position:-11px -655px;transform: scale(1.8);} */
.cust-xxl.crypto.coin.sm.lunc{background-position:-44px -655px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.avax{background-position:-77px -655px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.saito{background-position:-109px -655px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.btcst{background-position:-142px -655px;transform: scale(1.8);}
.cust-xxl.crypto.coin.sm.undefined{background-position:-174px -873px;transform: scale(1.8);}
/* .cust-xxl.crypto.coin.sm.btcst{background-position:-142px -655px;transform: scale(1.8);} */
/* .cust-xxl.crypto.coin.sm.btcst{background-position:-142px -655px;transform: scale(1.8);} */
/*HEADER crypto icons md transform end*/
/* list cryptos start */
.crypto.coin.sm.cust-sm.btc{background-position:-10.2px -533px;}
.crypto.coin.sm.cust-sm.eth{background-position:-43px -533px;}
.crypto.coin.sm.cust-sm.usdt{background-position:-76.5px -533px;}
.crypto.coin.sm.cust-sm.usdc{background-position:-107.3px -533px;}
/* .crypto.coin.sm.cust-sm.name{background-position:-142px -533px;} */
.crypto.coin.sm.cust-sm.xrp{background-position:-174.8px -533px;}
.crypto.coin.sm.cust-sm.ada{background-position:-206.5px -533px;}
.crypto.coin.sm.cust-sm.dot{background-position:-240px -533px;}
.crypto.coin.sm.cust-sm.doge{background-position:-273px -533px;}
.crypto.coin.sm.cust-sm.chz{background-position:-306px -533px;}
.crypto.coin.sm.cust-sm.aurora{background-position:-338px -533px;}
.crypto.coin.sm.cust-sm.xlm{background-position:-371px -533px;}
.crypto.coin.sm.cust-sm.bch{background-position:-404px -533px;}
.crypto.coin.sm.cust-sm.dnt{background-position:-436px -533px;}
.crypto.coin.sm.cust-sm.eur{background-position:-469.3px -533px;}

/* .crypto.coin.sm.cust-sm.name{background-position:-502px -533px;} */
/* .crypto.coin.sm.cust-sm.name{background-position:-11px -571px;} */
.crypto.coin.sm.cust-sm.usd{background-position:-43.9px -571px;}
.crypto.coin.sm.cust-sm.gusd{background-position:-77px -571px;}
.crypto.coin.sm.cust-sm.zil{background-position:-109px -571px;}
.crypto.coin.sm.cust-sm.ltc{background-position:-142px -571px;}
.crypto.coin.sm.cust-sm.fill{background-position:-175px -571px;}
.crypto.coin.sm.cust-sm.amp{background-position:-207px -571px;}
.crypto.coin.sm.cust-sm.vet{background-position:-240px -571px;}
.crypto.coin.sm.cust-sm.busd{background-position:-273px -571px;}
.crypto.coin.sm.cust-sm.theta{background-position:-306px -571px;}
/* .crypto.coin.sm.cust-sm.name{background-position:-338px -571px;} */
.crypto.coin.sm.cust-sm.link{background-position:-371px -571px;}
/* .crypto.coin.sm.cust-sm.btcst{background-position:-404px -571px;} */
.crypto.coin.sm.cust-sm.miota{background-position:-436px -571px;}
.crypto.coin.sm.cust-sm.xdc{background-position:-502px -571px;}

/* .crypto.coin.sm.cust-sm.name{background-position:-11px -613px;} */
.crypto.coin.sm.cust-sm.eos{background-position:-44px -613px;}
.crypto.coin.sm.cust-sm.shib{background-position:-77px -613px;}
.crypto.coin.sm.cust-sm.aave{background-position:-109px -613px;}
.crypto.coin.sm.cust-sm.noia{background-position:-142px -613px;}
/* .crypto.coin.sm.cust-sm.name{background-position:-175px -613px;} */
.crypto.coin.sm.cust-sm.ksm{background-position:-207px -613px;}
.crypto.coin.sm.cust-sm.algo{background-position:-240px -613px;}
.crypto.coin.sm.cust-sm.atom{background-position:-273px -613px;}
.crypto.coin.sm.cust-sm.cake{background-position:-306px -613px;}
.crypto.coin.sm.cust-sm.tfuel{background-position:-338px -613px;}
.crypto.coin.sm.cust-sm.mkr{background-position:-371px -612.5px;}
.crypto.coin.sm.cust-sm.rune{background-position:-404px -613px;}
/* .crypto.coin.sm.cust-sm.name{background-position:-436px -613px;} */
.crypto.coin.sm.cust-sm.dai{background-position:-469px -613px;}
.crypto.coin.sm.cust-sm.xmr{background-position:-502px -613px;}

/* .crypto.coin.sm.cust-sm.xmr{background-position:-11px -655px;} */
.crypto.coin.sm.cust-sm.lunc{background-position:-44px -655px;}
.crypto.coin.sm.cust-sm.avax{background-position:-77px -655px;}
.crypto.coin.sm.cust-sm.saito{background-position:-109px -655px;}
/* .crypto.coin.sm.cust-sm.btcst{background-position:-142px -655px;} */
.crypto.coin.sm.cust-sm.undefined{background-position:-174px -873px;}
/* .crypto.coin.sm.cust-sm.btcst{background-position:-142px -655px;} */
.crypto.coin.sm.cust-sm.btcst{background-position:-142px -655px;}
.crypto.coin.sm.cust-sm.trx{background-position:-142px -655px;}

/*left panel on hover crypto icons start*/
/* .coin.sm{width: 25px;height: 25px;} */
.coin-item.active .crypto.coin.sm.btc{background-position:-11px -533px;}
.coin-item.active .crypto.coin.sm.eth{background-position:-43px -533px;}
.coin-item.active .crypto.coin.sm.usdt{background-position:-76.5px -533px;}
.coin-item.active.crypto.coin.sm.usdc{background-position:-109.3px -533px;}
/* .coin-item.active .crypto.coin.sm.name{background-position:-142px -533px;} */
.coin-item.active .crypto.coin.sm.xrp{background-position:-174.8px -533px;}
.coin-item.active .crypto.coin.sm.ada{background-position:-206.5px -533px;}
.coin-item.active .crypto.coin.sm.dot{background-position:-240px -533px;}
.coin-item.active .crypto.coin.sm.doge{background-position:-273px -533px;}
.coin-item.active .crypto.coin.sm.chz{background-position:-306px -533px;}
.coin-item.active .crypto.coin.sm.aurora{background-position:-338px -533px;}
.coin-item.active .crypto.coin.sm.xlm{background-position:-371px -533px;}
.coin-item.active .crypto.coin.sm.bch{background-position:-404px -533px;}
.coin-item.active .crypto.coin.sm.dnt{background-position:-436px -533px;}
.coin-item.active .crypto.coin.sm.eur{background-position:-469.3px -533px;}
/* .coin-item.active .crypto.coin.sm.name{background-position:-502px -533px;} */

/* .coin-item.active .crypto.coin.sm.name{background-position:-11px -571px;} */
.coin-item.active .crypto.coin.sm.usd{background-position:-43.9px -571px;}
.coin-item.active .crypto.coin.sm.gusd{background-position:-77px -571px;}
.coin-item.active .crypto.coin.sm.zil{background-position:-109px -571px;}
.coin-item.active .crypto.coin.sm.ltc{background-position:-142px -571px;}
.coin-item.active .crypto.coin.sm.fill{background-position:-175px -571px;}
.coin-item.active .crypto.coin.sm.amp{background-position:-207px -571px;}
.coin-item.active .crypto.coin.sm.vet{background-position:-240px -571px;}
.coin-item.active .crypto.coin.sm.busd{background-position:-273px -571px;}
.coin-item.active .crypto.coin.sm.theta{background-position:-306px -571px;}
/* .coin-item.active .crypto.coin.sm.name{background-position:-338px -571px;} */
.coin-item.active .crypto.coin.sm.link{background-position:-371px -571px;}
.coin-item.active .crypto.coin.sm.btcst{background-position:-404px -571px;}
.coin-item.active .crypto.coin.sm.miota{background-position:-436px -571px;}
.coin-item.active .crypto.coin.sm.xdc{background-position:-502px -571px;}

/* .coin-item.active .crypto.coin.sm.name{background-position:-11px -613px;} */
.coin-item.active .crypto.coin.sm.eos{background-position:-44px -613px;}
.coin-item.active .crypto.coin.sm.shib{background-position:-77px -613px;}
.coin-item.active .crypto.coin.sm.aave{background-position:-109px -613px;}
.coin-item.active .crypto.coin.sm.noia{background-position:-142px -613px;}
/* .coin-item.active .crypto.coin.sm.name{background-position:-175px -613px;} */
.coin-item.active .crypto.coin.sm.ksm{background-position:-207px -613px;}
.coin-item.active .crypto.coin.sm.algo{background-position:-240px -613px;}
.coin-item.active .crypto.coin.sm.atom{background-position:-273px -613px;}
.coin-item.active .crypto.coin.sm.cake{background-position:-306px -613px;}
.coin-item.active .crypto.coin.sm.tfuel{background-position:-338px -613px;}
.coin-item.active .crypto.coin.sm.mkr{background-position:-371px -612.5px;}
.coin-item.active .crypto.coin.sm.rune{background-position:-404px -613px;}
/* .coin-item.active .crypto.coin.sm.name{background-position:-436px -613px;} */
.coin-item.active .crypto.coin.sm.dai{background-position:-469px -613px;}
.coin-item.active .crypto.coin.sm.xmr{background-position:-502px -612px;}

/* .coin-item.active .crypto.coin.sm.xmr{background-position:-11px -655px;} */
.coin-item.active .crypto.coin.sm.lunc{background-position:-44px -655px;}
.coin-item.active .crypto.coin.sm.avax{background-position:-77px -655px;}
.coin-item.active .crypto.coin.sm.saito{background-position:-109px -655px;}
/* .coin-item.active .crypto.coin.sm.btcst{background-position:-142px -655px;} */
.coin-item.active .crypto.coin.sm.undefined{background-position:-174px -873px;}
/* .coin-item.active .crypto.coin.sm.btcst{background-position:-142px -655px;} */
/* .coin-item.active .crypto.coin.sm.btcst{background-position:-272px -657px;} */
/*left panel on hover crypto icons end*/
.cust-modal-coin.cust-sm{transform: scale(0.99) !important;}
/*left panel crypto icons start*/
.coin.sm{width: 28px;height: 26px;vertical-align: middle;}
.crypto.coin.sm.btc{background-position:-11px -749px;}
.crypto.coin.sm.eth{background-position:-44px -749px;}
.crypto.coin.sm.usdt{background-position:-76.5px -749px;}
.crypto.coin.sm.usdc{background-position:-109.3px -749px;}
/* .crypto.coin.sm.name{background-position:-142px -749px;} */
.crypto.coin.sm.xrp{background-position:-173.8px -749px}
.crypto.coin.sm.ada{background-position:-205.5px -749px;}
.crypto.coin.sm.dot{background-position:-240px -749px;}
.crypto.coin.sm.doge{background-position:-273px -749px;}
.crypto.coin.sm.chz{background-position:-306px -749px;}
.crypto.coin.sm.aurora{background-position:-338px -749px;}
.crypto.coin.sm.xlm{background-position:-371px -749px;}
.crypto.coin.sm.bch{background-position:-404px -749px;}
.crypto.coin.sm.dnt{background-position:-436px -749px;}
.crypto.coin.sm.eur{background-position:-469.3px -749px;}
/* .crypto.coin.sm.name{background-position:-502px -749px;} */
.crypto.coin.sm.xeth{background-position:-44px -749px;transform: scale(1.2);}
.crypto.coin.sm.ethw{background-position:-44px -749px;transform: scale(1.2);}
.crypto.coin.sm.zeur{background-position:-469.3px -749px;transform: scale(1.2);}
.crypto.coin.sm.zusd{background-position: -44px -789px;transform: scale(1.2);}
.crypto.coin.sm.bnb{ background-position: -140px -532px;transform: scale(1.2);}
.crypto.coin.sm.matic{background-position:-762px -749px;}
.crypto.coin.sm.trx{background-position: -304px -532px;transform: scale(1.2);}
.crypto.coin.sm.xxbt{background-position: -141px -749px;transform: scale(1.2);}
.crypto.coin.sm.xxdg{background-position: -271.5px -533px;transform: scale(1.2);}
.crypto.coin.sm.ybt { background-position: -797px -749px;}
/* .crypto.coin.sm.name{background-position:-11px -789px;} */
.crypto.coin.sm.usd{background-position:-43.9px -789.2px;}
.crypto.coin.sm.gusd{background-position:-77px -789px;}
.crypto.coin.sm.zil{background-position:-109px -789px;}
.crypto.coin.sm.ltc{background-position:-142px -789px;}
.crypto.coin.sm.fill{background-position:-175px -789px;}
.crypto.coin.sm.amp{background-position:-207px -789px;}
.crypto.coin.sm.vet{background-position:-240px -789px;}
.crypto.coin.sm.busd{background-position:-273px -789px;}
.crypto.coin.sm.theta{background-position:-306px -789px;}
/* .crypto.coin.sm.name{background-position:-338px -789px;} */
.crypto.coin.sm.link{background-position:-371px -789px;}
/* .crypto.coin.sm.btcst{background-position:-404px -789px;} */
.crypto.coin.sm.miota{background-position:-436px -789px;}
.crypto.coin.sm.uni{background-position:-469px -789px;}
.crypto.coin.sm.xdc{background-position:-502px -789px;}

/* .crypto.coin.sm.name{background-position:-11px -831px;} */
.crypto.coin.sm.eos{background-position:-44px -831px;}
.crypto.coin.sm.shib{background-position:-77px -831px;}
.crypto.coin.sm.aave{background-position:-109px -831px;}
.crypto.coin.sm.noia{background-position:-142px -831px;}
/* .crypto.coin.sm.name{background-position:-175px -831px;} */
.crypto.coin.sm.ksm{background-position:-207px -831px;}
.crypto.coin.sm.algo{background-position:-240px -831px;}
.crypto.coin.sm.atom{background-position:-273px -831px;}
.crypto.coin.sm.cake{background-position:-306px -831px;}
.crypto.coin.sm.tfuel{background-position:-338px -831px;}
.crypto.coin.sm.mkr{background-position:-371px -830.5px;}
.crypto.coin.sm.rune{background-position:-404px -831px;}
/* .crypto.coin.sm.name{background-position:-436px -831px;} */
.crypto.coin.sm.dai{background-position:-469px -831px;}
.crypto.coin.sm.xmr{background-position:-502px -831px;}

/* .crypto.coin.sm.xmr{background-position:-11px -873px;} */
.crypto.coin.sm.lunc{background-position:-44px -873px;}
.crypto.coin.sm.avax{background-position:-77px -873px;}
.crypto.coin.sm.saito{background-position:-109px -873px;}
/* .crypto.coin.sm.btcst{background-position:-142px -873px;} */
.crypto.coin.sm.undefined{background-position:-174px -873px;}
/* .crypto.coin.sm.btcst{background-position:-142px -873px;} */
.crypto.coin.sm.btcst{background-position:-142px -873px;}
.coin.sm.undefined{background-position: -173px -655px;}
/*left panel crypto icons end*/
/* .login-user-img {width: 44px;height: 44px;border-radius: 50%;} */
/* .dropdown-img{width: 112px; height: 112px; border-radius: 50%; display: block;margin: 0 auto;} */
.menu-dropdown .login-user-img {border-radius: 50%;border: 2px solid #1c2122;}
.custom-header{ display: flex; justify-content: space-between; background: transparent;z-index: 100;padding: 8px 0px;align-items: center;}
/* .menu-item{display: inline-flex; list-style: none;margin-bottom: 0 !important;align-items: center;} */
/* .menu-item .list-item {margin: 0px 20px;font-size: 16px;font-weight: 400;} */
.menu-item .list-item a,.menu-item .list-item {color: var(--textWhite);}
.custom-header ul{background: transparent;border: 0;padding-left:  0 !important;}
/* .main-container,.main-menu .list-item{position: relative;} */
body::after{ content: '';background: url(../images/Ellipse.png);position:absolute;bottom: 0;right: 0; width: 100%;height: 100vh;z-index: 0;
background-repeat: no-repeat;}
/* body::before{content: '';background: url(../images/Ellipsecircle.png);position:absolute; top:0px; left:0px;width: 100%;height: 100vh;z-index: 0;
	background-repeat: no-repeat;} */
/* .main-menu .list-item:first-child:hover::after{content: none;} */
.overlay{ background: url(../images/bg-img.png);position:absolute; top:-47px; left:0px;width: 100%;height: 279px;z-index: 1;}
.ant-layout-content{z-index: 999;}

/* .profile-dropdown{display: flex; align-items: center;} */
/* .profile-dropdown-menu{padding: 12px 34px;text-align: center;} */
/* .header-right.ant-menu-horizontal > .ant-menu-item::after{content: none;} */
/* .secureDropdown .profile-dropdown-menu{background-color:var(--bgWhite); border-radius: 10px; min-width: 300px; max-width: 300px; padding: 24px 0;} */
/* .secureDropdown .ant-dropdown-arrow{border-color: var(--borderGrey) !important;} */
/* .drpdwn-list{list-style: none;} */
/* .profile-value{ font-size: 16px;font-weight: 500;margin-top: 8px;} */
.profile-btn, .profile-btn:active, .profile-btn:focus, .profile-btn:hover {background: var(--bgDarkGrey); border: 1px solid #ed4b9e;height: 38px; 
	border-radius: 19px;color: #1c2122;text-transform: uppercase;   font-size: 12px;   font-weight: 500; margin: 0 0 24px; padding: 10px 14px; 
	width: 200px;}
.banner_title{color: #ffff !important; font-weight: bold; font-size: 99px !important; line-height: 78px !important;opacity: 0.15 !important;
	margin-bottom: 0 !important;}
.layout-bg{border-radius: 8px;}
/* .layout-bg.left-panel{background-color: var(--bgLightGray);} */
.layout-bg.right-panel{background: #040015;border: 1px solid #204483;box-shadow: 0px 10px 20px rgb(57 126 246 / 15%);border-radius: 8px;padding:24px 12px;margin-bottom: 15px !important;margin-left: 0px;margin-right: 15px;flex: 1 1;}
.layout-bg.right-panel.dashboard-portfolio{margin-right: 0 !important;}
.currency-panel {margin-left: 16px;}
/* .coinBal-flex {justify-content: space-between !important;} */
.coin-margin {margin-left: 12px;}
.custom-tabs .ant-tabs-tab{padding-top: 0 !important; color: var(--textSecondary);font-size: 16px;font-weight: 500;}
.custom-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{color: var(--textBlue);}
.ant-tabs-ink-bar{height:3px !important;border-top-left-radius: 3px; border-top-right-radius: 3px;background:var(--textBlue)}
.coin-search-input .ant-input-group{height: 44px; line-height: 40px;}
.coin-search-input .ant-input-search-button{border: transparent !important;}
.coin-search-input .ant-btn{background-color: transparent; box-shadow: none;}
.coin-item{border-radius: 8px; margin-bottom: 8px;padding: 8px 12px;background: #131024;cursor: pointer;}
/* .coin-item .ant-list-item-meta-content{text-align: left;} */
/* .coin-item .ant-list-item-meta-title{margin-bottom: 0;line-height: 18px;} */
.coin-item .ant-list-item-meta{align-items: center !important;}
.coin-item .ant-list-item-action{margin-left:34px !important}
.coin-item .ant-list-item-action li{padding: 0;}
/* .coin-item.active{border: 1px solid var(--bgBlue) !important;} */
.coin-item:hover,.coin-item.active {background: #2C2653;box-shadow: 0 2px 8px rgba(0,0,0,4%);}
.coin-itemslist-scroll{height: 612px;overflow-y: auto;}
.qr-image {background: #fff;margin:0 0 10px 0;padding: 10px;}
.mb-0,.layout-bg.right-panel.mb-0{margin-bottom: 0 !important;}
.mb36{margin-bottom: 36px;}
/* common css */
.inside-btn{width: 465px;}
/* Breadcrumb start */
.app-breadcrumb.ant-breadcrumb .ant-breadcrumb-link .breadcrumb-item,.app-breadcrumb.ant-breadcrumb .ant-breadcrumb-link .breadcrumb-link{color: #fff ;font-weight: 500 !important;font-size: 18px !important;}
.app-breadcrumb .ant-breadcrumb .breadcrumb-link:hover{color: #397ef6 !important;}
.app-breadcrumb a.c-pointer:hover{color: #397ef6 !important;}
.app-breadcrumb .ant-breadcrumb-separator{color: var(--textWhite);}
.breadcrumb-margin{margin-bottom: 22px;margin-left: 0px;margin-right: 10px;}
/* Breadcrumb end */
/* Header Start */
.head-menutogle{margin-left: 8px;}
.head-username{margin-bottom: 0px !important;line-height: 18px;color:var(--textWhite);font-weight: 600;font-size: 14px;text-align: left;}
.head-businesname{margin-bottom: 0 !important;font-weight: 400;font-size: 12px;line-height: 17px;color:var(--textWhite); text-align: left;}
.head-textfield{margin: 0px 14px 0 4px;}
/* .head-button{border: 1px solid var(--bgSelect);padding: 10px;border-radius: 40px;} */
/* .head-button:hover{border: 0;} */
.header-right.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .header-right.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu{padding-right: 0 !important;padding-left: 26px !important;}
.login-user-img {width: 44px;height: 44px;border-radius: 50%;object-fit: cover;}
.dropdown-img{width: 112px; height: 112px; border-radius: 50%; display: block;margin: 0 auto;object-fit: cover;}
/* .menu-dropdown .login-user-img {border-radius: 50%;border: 2px solid var(--bgTableGray);} */
/* .custom-header{ display: flex; justify-content: space-between; background: transparent;z-index: 100;padding-top: 6px;align-items: center;} */
.profile-dropdown{display: flex; align-items: center;}
.profile-dropdown-menu{padding: 12px 34px;text-align: center;}
.header-right.ant-menu-horizontal > .ant-menu-item::after{content: none;}
.secureDropdown .profile-dropdown-menu{background-color:var(--bgCard);color: var(--textWhite);border-radius: 20px; min-width: 270px; max-width: 270px; padding: 24px 0; border: 1px solid #204483;}
.secureDropdown .ant-dropdown-arrow{border-color: var(--bgCopy) !important;}
.drpdwn-list{padding: 12px;color: var(--textWhite);text-align: center;margin: 0 10px;list-style: none;}
.drpdwn-list .head-exit{color: var(--textWhite);text-transform: uppercase;}
/* .profile-value{ font-size: 16px;font-weight: 600;margin-top: 0px !important;} */
/* .profile-btn, .profile-btn:active, .profile-btn:focus, .profile-btn:hover {background: var(--bgDarkGrey); border: 1px solid #ed4b9e;height: 38px; 
	border-radius: 19px;color: var(--bgTableGray);text-transform: uppercase;   font-size: 12px;   font-weight: 500; margin: 0 0 24px; padding: 10px 14px; 
	width: 200px;} */
/* .custom-header{ display: flex; justify-content: space-between; background: transparent;z-index: 100;align-items: center;padding: 8px 0px;} */
/* .custom-header ul{background: transparent;border: 0;padding-left:  0 !important;} */
.header-right li:first-child{padding-right: 0 !important;}
.header-right .mr{margin-right: -20px !important;}
.menu-item{display: inline-flex; list-style: none;margin-bottom: 0 !important;align-items: center;}
.menu-item .list-item {margin: 0px 20px;font-size: 16px;font-weight: 400;}
/* .menu-item .list-item a,.menu-item .list-item {color: var(--textWhite);} */
.main-container,.main-menu .list-item{position: relative;}
.main-menu .list-item:first-child:hover::after{content: none;}
.custom-header .ant-layout-sider{position: fixed;top: 54px;right: 0;z-index: 9999;}
.header-right.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item.rightmenu-icons{padding-left: 18px !important;}
/* Header End */

/* dashboard start */
.ant-spin-dot-spin .ant-spin-dot-item{display: none;transform: none; -webkit-animation: none;animation: none;}
.ant-spin-nested-loading{border-radius: 20px;}
.ant-spin-dot-spin{background-image:url(../images/loader.svg);transform: none;
    -webkit-animation: none;
    animation: none;width: 70px;height: 70px;}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {top: 20%;left: 40%;}
.db-card{background: #131024;backdrop-filter: blur(10px);border-radius: 12px;margin: 10px 18px;padding: 18px;text-align: center;}
h4.Card-title {font-weight: 500 !important;font-size: 28px !important;line-height: 45px !important;text-transform: uppercase !important;color: var(--textPrimary) !important;margin-top: 12px;}
.card-text{font-weight: 400;font-size: 16px;line-height: 19px;color: var(--placeholderText);text-align: left;}
.btn-text{font-size: 18px;line-height: 22px;color: #FFFFFF;font-weight: 600;margin-top: 30px;}
.db-img{margin-bottom: 16px;margin-top: 12px;}
.ant-btn:hover, .ant-btn:focus{color:#FFFFFF ;}
.btn-style.ant-btn {transition: 0.5s;background-size: 200% auto !important;font-size: 18px ;}
.btn-style.ant-btn:hover {background-position: right center !important;background-image: linear-gradient( to right, #2C00AA 0%, #6613BA 50%, #2C00AA 100%) !important;}
.btn-style.ant-btn:hover, .btn-style.ant-btn:focus, .btn-style.ant-btn:active{background-image: linear-gradient( to right, #2C00AA 0%, #6613BA 50%, #2C00AA 100%) !important;color: var(--textWhite);}
/* .layout-bg.right-panel.main-db{width: 900px; margin: 36px auto   !important;} */
.db-maintitle{font-weight: 600;font-size: 36px;color: #FFFFFF;text-align: center;margin: 0 10px 10px;}
.db-para{font-weight: 400;font-size: 14px;text-align: center;color: #FFFFFF;margin: 0 10px 10px;}
.row-stretch-right{display: block;}
.custodian-dashboard .first-panel{justify-content: center !important;display: block !important;}
.custodian-dblist{display: flex;justify-content: center;}
.custodian-dashboard .db-card{padding: 55px;}
.banner-head{font-weight: 600 !important; font-size: 36px !important; line-height: 43px;text-align: center;color: var(--textWhite) !important;}
.profile-dropdown img{margin-right: 6px;}
.available-card{margin-top: 8px;}
.cust-hr-line{border: 1px solid #393E64;}
.avail-bal{color: #f1f1f1;}
.btn-style.db-height{height: 46px !important;}
/* db-model  */
.dashboard-modal::after .ant-modal-centered{ text-align: center !important;  margin: auto;}
.custodian-dropdown{position: relative;right: 52px;min-width: 385px;}
.card-title.ac-title{margin-bottom: 35px !important;font-size: 20px !important;font-weight: 600 !important;}
.custodian-dropdown .ant-dropdown-menu-item:hover{background: transparent;}
.dashboard-coinselect{background: #221f34 !important;}
.dashboard-coinselect .icon.sm.wallet{margin: 0px 5px;}
/* db-modal  */
/* dashboard end */
/* leftpanel coinlist */
/* .coin-item{background: #131024;cursor: pointer;} */
.ant-list-items .coin-item:active, .ant-list-items .coin-item:focus, .ant-list-items .coin-item:hover {background: #2c2653;}
.coin-item .ant-list-item-meta-avatar{margin-right: 6px;margin-top: 5px;}
.coin-val{font-size: 14px !important;font-weight: 500 !important;text-align: right;color: var(--textPrimary) !important;}
.coin-price{font-weight: 400;font-size: 12px;text-align: right;color: var(--textSecondary);}
.coin-fillname{font-size: 14px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;color: var(--textSecondary);font-weight: 500;}
.coin-item.active .coin-fillname{color: var(--textWhite);}
.coin-item.active .coin-price{color: var(--textWhite);}
.coin-flexgrow{display: flex;}
.coin-flexgrow .ant-list-item-meta{flex: 136px 0 0 ;}
.coin-flexgrow .d-coinlist{flex-grow: 1;flex-basis: 0;}
.coin-flexgrow .coin-li{flex: 150px 0 0; margin-left: 8px;}
/* .ant-list-items .coin-item:hover, .ant-list-items .coin-item:focus, .ant-list-items .coin-item:active{background: #2C2653;} */
/* .coinBal-flex{display: flex; align-items: center;justify-content: center;} */
.coin-droupdown  .ant-select-single .ant-select-selector .ant-select-selection-item{height: 44px !important; line-height: 32px;}
.minmax{border:1px solid #e5e5e5; padding:2px; border-radius: 4px;}
.minmax .ant-btn{padding: 4px 16px; color:var(--textBlue) ;font-size: 12px;font-weight: 500;}
.minmax .ant-btn:hover{padding: 4px 16px; background-color: var(--textBlue);color: var(--textWhite);}
.layout-bg.left-panel {background: #040015;border: 1px solid #204483;box-shadow: 0px 10px 20px rgb(57 126 246 / 15%);border-radius: 8px;padding: 26px 16px;margin-bottom: 16px;}
.row-stretch{display: block !important;}
.coin-droupdown .ant-select-selection-item p{font-size: 26px !important;}
.coin-droupdown .ant-select-selection-item .ant-typography{display: none;}
.coinbal-tabs{color: var(--bgWhite);background: #212646;border-radius: 8px;padding: 8px;height: 50px;align-items: center;display: flex;}
.tabs-button{font-size: 20px;font-weight: 600;background: transparent;border: 0;color: var(--bgWhite);padding: 10px 30px;line-height: 3px;height: 36px;border-radius: 8px;}
.tabs-button.btn-active, .ant-btn.tabs-button:hover, .head-button:hover,.ant-btn.tabs-button:active,.ant-btn.tabs-button:focus{background: linear-gradient(89.35deg, #6613BA 31.31%, #2C00AA 94.88%);border-radius: 8px;color: var(--bgWhite);}
.btn-primary.ant-btn {height: 56px;font-size: 18px;background: var(--textBlue);border-color: var(--textBlue);font-weight: 600;color: var(--textWhite);border-radius: 0 0 8px 8px;text-transform: uppercase;}
/* .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner{background-color: var(--textBlue) !important; border: 1px solid var(--textBlue);} */
.summary-list{border: 1px solid var(--borderGrey);border-radius:8px;padding: 16px;}
.summary-total{background-color: var(--bgLightGray);padding: 8px 16px;border-top: 1px solid var(--borderGrey);border-radius: 0 0 8px 8px;}
.card-align{margin: 10px 52px;}
.asset-coinflex{background-color: #2C2653;}
.ant-empty-description{color:#fff;}
.account-balance{font-weight: 700 !important;font-size: 20px !important;margin: 0 !important;color: #f1f1f1 !important;}
.account-balance-details{display: flex;align-items: center;}
.icon.lg.fb{background-position: -357px -144px;}
.icon.lg.wt{background-position: -382px -143px;}
.icon.lg.tw{background-position: -414px -143px;}
.icon.lg.ms{background-position: -447px -143px;}
.icon.lg.fb:active{background-position: -602px -144px;}
.icon.lg.wt:active{background-position: -627px -143px;}
.icon.lg.tw:active{background-position: -659px -143px;}
.icon.lg.ms:active{background-position: -692px -143px;}
.icon.delete.lg{background-position: -172px -12px !important;}
.media-icons:active{background: #fff;border-radius: 50%;}
.media-icons:hover{background: #fff;border-radius: 50%;}
.media-icons:active .icon.lg.fb{background-position: -602px -144px;}
.media-icons:active .icon.lg.wt{background-position: -627px -143px;}
.media-icons:active .icon.lg.tw{background-position: -659px -143px;}
.media-icons:active .icon.lg.ms{background-position: -692px -143px;}
.icon.preview-icon{background-position: -200px -18px;width: 24px;height: 22px;}
.media-icons:hover .icon.lg.fb{background-position: -602px -144px;}
.media-icons:hover .icon.lg.wt{background-position: -627px -143px;}
.media-icons:hover .icon.lg.tw{background-position: -659px -143px;}
.media-icons:hover .icon.lg.ms{background-position: -692px -143px;}
.media-icons{width: 40px;height: 38px; text-align: center;}
.media-icons span{margin-bottom: 8px;}
.my-accountlist .coin-item .ant-list-item-meta{align-items: center !important;}
/* leftpanel coinlist end */
/* modalpopup */
.bank-listhead{display: flex;align-items: center;}
.custom-modal .ant-empty.ant-empty-normal{background: var(--bgDark);}
.custom-modal .dt-topspace .ant-list-items .coin-item{min-height: 54px;}

.custom-modal{width:650px;}
.ant-modal-close-icon{display: none;}
.custom-modal .coin-container .coin-search-input{background: var( --bgDark);}
.custom-modal .ant-list-items .coin-item{background: var(--bgDark);border: 1px solid #204483 !important;box-shadow: 0px 8px 10px rgba(57, 126, 246, 0.15);border-radius: 12px;}
.custom-modal .ant-list-items .coin-item.active{background-color: #2C2653;}
.custom-modal .ant-modal-content{padding:20px 10px;}
/* .ant-modal-body{padding: 0;} */
.ant-modal-content, .ant-modal-header, .ant-modal-footer{background: #131024;color: var(--textWhite);border: 0;}
.ant-modal-content{border-radius: 8px;padding: 36px;}
.ant-modal-header{border-radius: 8px 8px 0 0;padding: 0;}
.ant-modal-body{padding: 0;max-height: 480px;overflow-y: auto;}
.custom-modal .btn-style{width:465px;}
.left-panel .leftcoin-fullname{white-space: nowrap; overflow: hidden;text-overflow: ellipsis;width: 90px;}
/* modalpopup */
/* my wallets */
.panel-card.buy-card {background-color: var(--bgCard);border-radius: 8px;margin: 30px auto;padding: 35px 20px;}
.ac-asset-align{display: flex;align-items: center;justify-content: space-between;padding: 12px 35px;}
.account-wallets{display: flex;align-items: center;}
.account-wallet-head{display: flex;align-items: center;padding: 0px 0px 12px 8px;justify-content: space-between;margin: 0px 10px;}
/* deposit screen start */
.deposit-lbl label{font-weight: 600;font-size: 14px;color: #99999A	; text-align: center; margin-right: 409px;}
.deposit-lbl .ant-form-item-label{margin:0 auto}
.coin-item h3,h5,p{margin: 0 ;}
.deposit-large{font-weight: 600 !important;font-size: 14px !important;color: #ffffff !important;}
.deposit-small{font-weight: 400 !important;font-size: 12px !important;color: #99999A !important;}
.ref-code{padding: 0px 10px;background: #2C2841	; border-radius: 5px;height: 30px;margin: 0px 15px}
.copy-btn{display: inline-block; border-radius: 16px; padding: 7px 18px 5px 18px; color: #ffffff;text-transform: uppercase;font-weight: 600;
    right: 0;transform: scale(0.8);}
.copy-btn .ant-typography-copy{color: #397EF6;transform: scale(1.5);}
.address-content{font-weight: 700;font-size: 12px;color: #F1F1F1;margin: 0 !important;}
.coin-item.left-deposit:hover{background: #2C2653;}
.share-adrs{background-color: transparent!important; border: none !important; margin: 0;}
.share-adrs .ant-menu-item{display: inline-block; margin: 0px !important; cursor: pointer;background: transparent;}
.share-adrs.ant-menu-vertical .ant-menu-item{ padding: 0 4px 0px !important; height: 32px !important;} 
.address-share .ant-menu-item.ant-menu-item-only-child:active{background: transparent !important;}
.icon-space{margin: 7px 5px 0px 0px;}
.header-depo{padding: 0 5px;}
.deposit-content{margin-top: 24px !important; margin-bottom: 24px !important;}
.deposit-content .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{background-color: transparent !important;}
.deposit-content .ant-menu-item:active, .ant-menu-submenu-title:active{background-color: transparent !important;}
.ant-alert-with-description{padding: 0 !important;align-items: center;}
.deposit-lbl{display: block;}
.mt-34.withdraw-network{margin-top: 34px;}
.buy-token{background: var(--bgSelect);border-radius: 8px;margin: 29px 27px;}
/* .buy-coinselect{background-color: var(--bgLightGray);padding:12px 16px;display: flex;align-items: center;border-radius: 24px;cursor: pointer;} */
.buy-from, .buy-fromcurrency{font-size: 14px;line-height: 35px;text-align: center;margin-left:4px;margin-right: 4px;text-transform:capitalize;color: var(--placeholderText);}
.mbl-ellipse .payee-label{color: var(--placeholderText);}
/* .buy-selectgroup{display: flex;align-items: center;width: 100%;} */
/* .icon.sm.down-angle{background-position: -225px -123px; transform: rotate(180deg);} */
.address-share{font-weight: 600 !important;font-size: 18px !important;color: #99999A !important; text-align: center;}
.depo-note{font-weight: 600 !important;font-size: 18px !important; color: #F1F1F1 !important; text-align: left;}
.depo-content{font-weight: 500;font-size: 14px;line-height: 20px;color: #A8A8A8;opacity: 0.75;text-align: left; }
.note-section{  margin: 35px 0px 20px;}
.ref-code .address{margin-left: auto; margin-right: 10px;}
.coin-search input{color: white;font-size: 16px;}
.withdraw-input.network-drpdwn label{background-color: var(--bgCard);color: var(--textPrimary);z-index: 100; border-radius: 8px;display: inline-block;padding: 2px 0px;}
.deposit-content .network-drpdwn{margin: 0px auto !important; margin-top: 30px !important;}
/* .cust-qr{margin-top: 25px;} */
.ant-select:not(.ant-select-customize-input) .ant-select-selector{background-color: transparent; border: transparent;}
.panel-card.buy-card.deposit-content{padding: 30px;}
.deposit-content .address .address-content{width: 385px;text-overflow: ellipsis; overflow: hidden; white-space: nowrap; display: inline-block;vertical-align: middle;}
.panel-card.buy-card.deposit-content form{padding: 30px 0;}
.deposit-rightpanel{margin: 22px auto !important;}
.buy-token .coin.sm.btc{margin: 0px 4px;}
.val-msg{color:#ff1414;margin-top: -20px;margin-left: -198px;font-size: 14px;font-weight: 600;}
/* .react-share__ShareButton:hover{background-color:#ffffff !important;border-radius: 50px;} */
/* .react-share__ShareButton:hover .icon.lg.whatsapp{background-position: -627px -146px;}
.react-share__ShareButton:hover .icon.lg.mail{background-position: -693px -146px;} */
.react-share__ShareButton{width:26px;}
.buy-selecttoken {height: 142px;margin: 20px auto 0;padding: 10px;text-align: center;}
/* deposit end */
/* withdraw */
.my-accountlist .ant-list-split .ant-list-item {border: none !important;}
.buy-coinselect{background-color: var(--bgLightGray);padding:14px 16px;display: flex;align-items: center;border-radius: 8px;cursor: pointer;height: 54px;}
.buy-selectgroup{display: flex;align-items: center;width: 100%;}
/* .buy-from, .buy-fromcurrency{font-size: 14px;line-height: normal;text-align: center;margin-left:0px;margin-right: 0px;text-transform: capitalize;color: var(--placeholderText) !important;} */
/* .buy-entryinput{font-weight: 500;font-size: 60px;line-height: 72px;text-align: center;color: var(--textWhite);} */
.buy-entryinput.wd-entry{font-size: 40px;}
.withdraw-rightpanel{  background-color: var(--bgCard);border-radius: 20px;}
.withdraw-input .ant-input{background: none !important;color: var(--textWhite);height:54px;text-align: left;}
.withdraw-input .ant-form-item-required {background-color: var(--bgCard); color: var(--textPrimary);z-index: 100;border-radius: 8px;display: inline-block;padding: 4px 10px;}
.withdraw-input .ant-form-item-explain{position: absolute;left: 0px;bottom: -26px;}
.avail-blnc{background: #2C2841; border-radius: 5px; height: 65px; position: relative;font-size: 20px;color: var(--textPrimary);align-items: center;display: flex;justify-content: space-between;padding: 16px;margin-top: 50px;}
.avail-blnc span{font-size: 20px;color: var(--textPrimary);}
.input-maxbtn{position: absolute;top:60px ;right: -8px;padding: 15px 10px;width: -moz-fit-content;width: fit-content;color: #397EF6;font-size: 16px;}
.input-maxbtn span{color: var(--textPrimary);}
.avail-blnc .ant-form-item-control-input-content{display: flex;justify-content: space-between;}
.withdarw-network .cust-input{background: var(--bgSelect);border: transparent; border-radius: 8px;height: 50px;align-items: center;}
.withdarw-network .ant-select-selector{align-items: center;}
.withdarw-network .ant-select-item-option-content{background: var(--bgSelect);}
.withdarw-network .ant-form-item-explain-error{width: fit-content;}
.withdraw-head{margin: 0px 2px 0px 6px;}
.withdarw-network{margin-bottom: 45px;}
.cust-name{font-size: 16px !important;font-weight: 700 !important;color:  var(--textPrimary) !important;}
.test-accounts-list:hover{background: #131024;}
.coin-fullname{font-size: 16px !important;font-weight: 700;color:  var(--textPrimary);margin-bottom: 0px !important;}
.coin-selectlist:hover{background: #2C2653;}
.test-aclist{justify-content: space-between;padding: 8px 12px;align-items: center;}
.cointitle-content{margin-left: 8px;}
.test-accounts-list{border-radius: 8px !important;margin: 8px 0px !important;}
.coin-labelsize{margin: 0px !important;}
.text-white{color:  var(--textPrimary) !important;}
.withdraw-input span>label{display: inline-block;margin-left: 8px;}
.buy-slider .ant-slider-rail { height: 1px;   background-color: #6269A3;}
.ant-slider-track {height: 0;  background: transparent;}
.buy-slider .ant-slider-dot {    display: none;}
.buy-slider .ant-slider-handle {  width: 22px; height: 22px;  margin-top: -10px; background-color: var(--sliderBg); border: solid 2px var(--sliderBordr);}
.bank-totalammount{font-weight: 400;font-size: 16px;color: var(--textSecondary) !important; margin: 14px auto;}
.bank-totalammount span {color: var(--textWhite);}
.buy-toamount{width: 215px;height: 40px;background: var(--amountBg);border-radius: 19px;align-items: center;display: flex;margin: 0 auto;padding: 4px 18px;}
.buy-toamount.wt-verify-code{margin: 0;position:relative;width:auto;}
/* .ant-input-affix-wrapper{background-color: transparent;border: none;}
.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {border-color:none;box-shadow: none;} */
.ant-input-group-addon{vertical-align:bottom !important;background-color: transparent;border:none;font-size: 16px;color: #ffffff;}
.wd-input .buy-entryinput{padding: 0;}
.wd-input .ant-input-group input{text-align: center!important;font-size: 50px;}
/* withdraw  */
/* transactions  */
.transactions-tablegrid .ant-table tbody tr{background-color: var(--bgCard);}
.transactions-tablegrid .ant-table .ant-table-tbody tr td{font-size: 16px;color: var(--textPrimary);font-weight: 400;}
.transactions-tablegrid .ant-table .ant-table-thead tr{background: #040015 !important;}
.transactions-tablegrid .ant-table{background-color: transparent;}
.network-inpt{display: flex; align-items: center;text-align: left !important;}
.network-inpt .ant-select-arrow{color: #ffffff;}
.transactions-tablegrid .ant-table-tbody > tr { border-bottom: 1px solid #1d1833 !important; }
.transactions-tablegrid .ant-table thead th { background-color: #1d1833;color: var(--textSecondary);font-weight: 700;font-size: 16px;text-transform: uppercase; margin-bottom: 0;border-bottom: 1px solid #131024;}
.transactions-tablegrid .ant-table .ant-table-tbody tr:hover{background: #1d1833 !important;}
.transactions-tablegrid .ant-table .ant-table-tbody tr{border-bottom: 1px solid #1d1833 !important;}
.transactions-tablegrid .ant-table .ant-table-tbody > tr.ant-table-row:hover > td{background: transparent;}
.transactions-tablegrid .ant-table-tbody > tr.ant-table-placeholder:hover > td { background: transparent;}
.transactions-tablegrid .ant-table tbody{background: var(--bgCard);}
.transactions-tablegrid .ant-table-pagination.ant-pagination{display: none;}
.transactions-tablegrid .ant-table-tbody > tr > td{border-bottom: 1px solid #1d1833 !important;}
.transactions-rightpanel .rightpanel-card-bg{padding: 0px 0px 36px 0px;border-radius: 12px;}
.person-name{white-space: nowrap; overflow: hidden;text-overflow: ellipsis;width: 160px;}
.coinlist-height{height: 58px;}
/* .transaction-title{padding: 10px 2px;} */
.status-pending{color: var(--textRed) !important;}
.status-complete{color: var(--textGreen) !important;}
.content-right{justify-content: right;}
.content-left{justify-content: left;}
/* .wallet-cardlist .ant-list-item-meta{flex: none;display: flex;} */
.mr-0{margin-right: 0px !important;}
.network-drpdwn .ant-select-arrow{color: #fff;}
.network-drpdwn .ant-select-selector{height: 50px !important;align-items: center;text-align: left;}
.withdraw-input .ant-input.amt-center{text-align: center;}
.amt-center .ant-input{border:none;padding: 15px 0px;}
.amt-center .ant-input:focus{box-shadow: none;}
.amt-center .ant-input-group-addon{background: transparent;border: none;padding-top: 8px;z-index: 999;}
.network-drpdwn .ant-form-item{height: 42px !important;}
.coin-align{vertical-align: middle;color:#fff;margin-right: 5px;}
.transactions-tablegrid .ant-table-container table > thead > tr:first-child th:last-child {border-top-right-radius: 12px;}
.transactions-tablegrid .ant-table-container table > thead > tr:first-child th:first-child{border-top-left-radius: 12px;}
/* transactions  */
/* balances  */
.balances-header{justify-content: space-between !important;padding: 0px 2px !important;}
.balance-title { background: #212646;border-radius: 29px; padding: 8px 24px;align-items: center;margin-bottom: 0px !important;color: var(--textWhite) !important;font-size: 20px !important; font-weight: 600;}
.header-bal{margin-left: 12px;}
.coin-lefttitle{font-size: 16px !important;color:  var(--textWhite) !important;font-weight: 700 !important;margin-bottom: 0px !important;}
.leftcoin-bal{font-size: 14px !important; font-weight: 500 !important; text-align: right;color: var(--textPrimary) !important;margin-bottom: 0px !important;line-height: 20px !important;}
.leftcoin-fullname{ font-size: 12px !important;white-space: nowrap;overflow: hidden; text-overflow: ellipsis;color: var(--textSecondary) !important;margin: 0px !important;line-height: 22px !important;}
.selected-coinbal{  font-weight: 600; color: var(--textWhite) !important; font-size: 26px !important;margin-bottom: 0 !important;line-height: 24px !important;}
.coin-remainingbal{  font-weight: 600; font-size: 26px; line-height: 20px; color: #ffffff;}
.balances-list{background: #131024;}
.card-bal{font-size: 14px;font-weight: 400;color:  var(--textSecondary);}
.balances-rpanel .exchange-bal{padding: 24px 0px 50px;margin-top: 16px;}
/* .input-namefeild.wd-input {  margin: 10px 0px 60px !important;} */
/* .drpdwn-list .head-exit { color: var(--textWhite); text-transform: uppercase;} */
.head-button:hover{background: linear-gradient(89.35deg, #6613BA 31.31%, #2C00AA 94.88%);border:1px solid #6613BA;}
.head-button{border: 1px solid var(--bgSelect); padding: 10px; border-radius:8px;}
 .coin-search.search-width{margin-bottom: 0px;}
 .coinlist-height:nth-child(1) .name-logo, .bgcard-content .bal-card:nth-child(1) .name-logo{background-image: linear-gradient(180deg, #ffafbd, #ffc3a0);color: #f70000;}
 .coinlist-height:nth-child(2) .name-logo, .bgcard-content .bal-card:nth-child(2) .name-logo{background-image: linear-gradient(180deg, #5ba2b4, #90d2e2);color: #045cfd;}
.coinlist-height:nth-child(3) .name-logo, .bgcard-content .bal-card:nth-child(3) .name-logo{background-image: linear-gradient(180deg, #ff7e5f , #feb47b);color: #ff4800;}
.coinlist-height:nth-child(4) .name-logo, .bgcard-content .bal-card:nth-child(4) .name-logo{background-image: linear-gradient(180deg, #ee9ca7 , #ffdde1);color: #ef2121;}
.coinlist-height:nth-child(5) .name-logo, .bgcard-content .bal-card:nth-child(5) .name-logo{background-image: linear-gradient(180deg, #cdb0e7 ,  #ecc2e6);color: #8c0097;}
.coinlist-height:nth-child(6) .name-logo, .bgcard-content .bal-card:nth-child(6) .name-logo{background-image: linear-gradient(180deg, #6BB2A0, #CDE0C9);color: #00a571;}
.coinlist-height:nth-child(7) .name-logo, .bgcard-content .bal-card:nth-child(7) .name-logo{background-image: linear-gradient(180deg, #ee9da6 , #e89182);color: #860918;}
.coinlist-height:nth-child(8) .name-logo, .bgcard-content .bal-card:nth-child(8) .name-logo{background-image: linear-gradient(180deg, #938eeb , #98dae7);color: #080453;}
.coinlist-height:nth-child(9) .name-logo, .bgcard-content .bal-card:nth-child(9) .name-logo{background-image: linear-gradient(180deg, #d4e896 , #a3ecad);color: #3b4d06;}
.coinlist-height:nth-child(10) .name-logo, .bgcard-content .bal-card:nth-child(10) .name-logo{background-image: linear-gradient(180deg, #e36e43 , #e9ce8c);color: #371103;}
.coinlist-height:nth-child(11) .name-logo, .bgcard-content .bal-card:nth-child(11) .name-logo{background-image: linear-gradient(180deg, #ffafbd, #ffc3a0);color: #f70000;}
 .coinlist-height:nth-child(12) .name-logo, .bgcard-content .bal-card:nth-child(12) .name-logo{background-image: linear-gradient(180deg, #5ba2b4, #90d2e2);color: #045cfd;}
.coinlist-height:nth-child(13) .name-logo, .bgcard-content .bal-card:nth-child(13) .name-logo{background-image: linear-gradient(180deg, #ff7e5f , #feb47b);color: #ff4800;}
.coinlist-height:nth-child(14) .name-logo, .bgcard-content .bal-card:nth-child(14) .name-logo{background-image: linear-gradient(180deg, #ee9ca7 , #ffdde1);color: #ef2121;}
.coinlist-height:nth-child(15) .name-logo, .bgcard-content .bal-card:nth-child(15) .name-logo{background-image: linear-gradient(180deg, #cdb0e7 ,  #ecc2e6);color: #8c0097;}
.coinlist-height:nth-child(16) .name-logo, .bgcard-content .bal-card:nth-child(16) .name-logo{background-image: linear-gradient(180deg, #6BB2A0, #CDE0C9);color: #00a571;}
.coinlist-height:nth-child(17) .name-logo, .bgcard-content .bal-card:nth-child(17) .name-logo{background-image: linear-gradient(180deg, #ee9da6 , #e89182);color: #860918;}
.coinlist-height:nth-child(18) .name-logo, .bgcard-content .bal-card:nth-child(18) .name-logo{background-image: linear-gradient(180deg, #938eeb , #98dae7);color: #080453;}
.coinlist-height:nth-child(19) .name-logo, .bgcard-content .bal-card:nth-child(19) .name-logo{background-image: linear-gradient(180deg, #d4e896 , #a3ecad);color: #3b4d06;}
.coinlist-height:nth-child(20) .name-logo, .bgcard-content .bal-card:nth-child(20) .name-logo{background-image: linear-gradient(180deg, #e36e43 , #e9ce8c);color: #371103;}
.bgcard-content .bal-card .name-logo{font-weight: 800;}
.layout-bg.right-panel .name-logo{padding: 14px;}
/* .coin-item:nth-child(1) .name-logo{background-image: linear-gradient(180deg, #ffafbd, #ffc3a0);color: #f90000;} */
/* balances  */
/*Add Assets start*/
.coinBal-flex.summary-page {padding: 0px 30px;}
.coinBal-flex {display: flex;align-items: center;justify-content: space-between;padding: 0px 15px;position: relative;}
/* .coinBal-flex {display: flex;align-items: center;justify-content: center;} */
/* .cust-xxl.crypto.coin.sm.undefined {background-position: -174px -873px;transform: scale(1.8);} */
.cryptocoin-amount{font-weight: 600 !important;font-size: 26px !important;line-height: 31px !important;color: var(--textWhite) !important;margin-bottom: 0 !important;}
.cryptocoin-amount span{font-size: 26px !important;font-weight: 300;color: #A8A8A8 !important;}
.addasset-btn button{height: 48px !important;font-size: 18px !important;}
.profile-btn button{height: 38px !important;font-size: 14px !important;}
.coin-valuecontent{margin-left: 8px;}
.network-field .ant-form-item-control-input-content{padding: 25px 0px;display: flex;justify-content: space-between; border-radius: 8px;}
.network-field .ant-form-item-control-input-content .item-card{color: #ffffff; border: 1px solid var(--bgSelect); padding: 5px 10px; border-radius: 8px; cursor: pointer;width: 150px;margin-right: 13px;height: 42px;display: flex;justify-content: center;align-items: center;}
.item-card input{height: default !important; margin-right: 5px;}
.item-card input:focus{box-shadow: none !important;}
.item-card label{display: flex !important; align-items: center;}
.network-field .item-card, .item-card label{background-color: var(--bgSelect) !important;}
.assets-header{display: flex;justify-content: space-between;align-items: center;}
.network-field .ant-checkbox{margin-bottom: 4px;}
.network-select{margin-right: 26px;}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled){background: transparent;}
.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector{background: transparent;}
.assets-header .custom-flex{margin-bottom: 0px;}
.ant-spin-nested-loading > div > .ant-spin {top:27px !important;}

/*Add Assets end*/
/*Custodian accounts start*/
/* .card-title{font-size: 18px !important; font-weight: 600 !important;} */
.card-head{margin-bottom: 0 !important;}
.custom-flex{display: flex;align-items: center;justify-content: space-between;margin-bottom: 8px;}
.coin-search .ant-input-group-addon{display: none;}
.coin-search-input, .coin-search-input .ant-input-group-addon{background: var(--bgCard); border-radius: 8px;cursor: pointer !important; border: 1px solid #6269a3 !important;}
.dt-topspace{margin-top: 20px;}
.coin-search{margin-bottom: 20px;cursor: pointer;}
.coin-container input{color: white;}
.icon.md.search{background-position: -149px -122px;}
.icon.sm.add-coin{background-position: -464px -123px;}
.icon.sm.add-coin:hover{background-position: -486px -123px;}
.icon.sm.right-arrow{background-position: -437px -124px;}
.icon.md.locked{background-position: -121px -37px;}
/* .coin-search-input .ant-input-group{height: 44px;line-height: 40px;} */
.payee-leftdata{display: flex;align-items: baseline;justify-content: space-between;}
.coin-item .ant-list-item-meta-content {text-align: left;}
.coin-item .ant-list-item-meta-title {margin-bottom: 0;line-height: 18px;}
.coin-title {color: var(--textWhite);font-size: 16px;font-weight: 500;line-height: 19px;overflow: hidden;text-overflow: ellipsis;vertical-align: middle;white-space: nowrap;}
/* .coin-price{font-weight: 400;font-size: 12px;text-align: right;color: var(--textSecondary);} */
/* .coin-item .ant-list-item-meta {align-items: center !important;} */
/* .ant-list-split .ant-list-item {border: none !important;} */
.name-logo{width: 26px; height: 26px; display: flex;border-radius: 100%; align-items: center; justify-content: center; background-color: #212121; border: 1px solid #666666; margin-right: 6px;color: var(--textWhite);font-weight: 500;font-size: 16px !important;line-height: 16px;}
.createac-content .ant-input{text-align: left;}
/*Custodian accounts end*/
/* crete account start */
.rightpanel-card-bg{background-color: var(--bgCard);border-radius: 20px;margin: 24px 0px;padding: 36px 16px;min-height: 620px;}
.createaccount-header{display: flex;justify-content: space-between;align-items: center;}
.payee-field .ant-select-selector { align-items: center; display: flex;}
.input-bordered:focus-visible{ outline: none;}
.p-relative .ant-col.ant-form-item-label{position: absolute;top: -14PX;left: 10PX;font-weight: 400;display: inline-block;}
.createac-btns{display: flex;justify-content: space-between;width: 100%;}
.create-accountpanel .ant-input{background-color: transparent !important;box-shadow: none;font-size: 18px;color: var(--textPrimary);text-align: left;height: 54px;margin: 24px auto;}
.createac-content .ant-form-item-required{background-color: var(--bgCard);color: var(--textPrimary);z-index: 100;border-radius: 8px;display: inline-block;padding: 4px 10px;}
.createac-cancel{background: none !important;border: 1px solid #204483;margin-right: 10px;}
.createac-cancel:hover{border: 1px solid transparent;}
.btn-width{width:100%;}
.cancel-btn{margin-right: 15px;}
.createac-content{margin: 0 auto;}
.create-accountpanel .thankyou-link{margin-bottom: 15px;}
.defaultuser{width: 100px;height: 100px;border-radius: 50%;}
.create-account .buy-entryinput{border-bottom: 1px solid #6269A3 !important;}
.create-account .ant-form-item-explain-error { position: absolute; bottom: 20px !important; width: -moz-fit-content;width: fit-content;}
.create-account .input-namefeild{margin: 0px !important;}
/* .createac-cancel:hover{border: none;} */
.buy-entryinput{font-weight: 500;font-size: 60px;line-height: 72px !important;text-align: center !important;color: var(--textWhite) !important;margin-top: 10px;}
.input-bordered{font-size: 16px;line-height: 50px;border: 1px solid #6269A3 !important;text-align: left !important;background-color: transparent !important;border-radius: 8px !important;height: 54px;width: 100%;padding-left: 0px;color: #fff;padding-right: 18px;}
.create-account{display: flex; justify-content: center; align-items: center;}
.create-acinput .ant-form-item-explain-error{position: absolute;bottom: -2px;width: fit-content;}
.create-acinput .ant-input {border: none;box-shadow: none;}
.create-acinput .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus{box-shadow: none;}
.ant-empty-image,.ant-collapse-image,.ant-empty-img-default{background-image: url(../images/nodata.png) !important;background-repeat:no-repeat;text-align: center;background-position: center;height: 40px;width: 51px;margin: 0 auto 8px;}
.ant-empty-img-default g{display: none;}
.ant-empty-image .ant-empty-img-simple,.ant-collapse-image .ant-empty-img-simple{display: none;}
.withdraw-head .coin-remainingbal{line-height:18px}
.ant-empty.ant-empty-normal{margin:0px auto}
.ant-empty {text-align: center;  width: fit-content; border-radius: 20px; padding: 20px; background: var(--bgCard);margin: auto;}
.ant-form-item-explain-error{text-align: left;}
.ant-form-item-has-error .input-bordered, .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input{border-color: #ff5747 !important;}
.ant-form-item-explain-error,.ant-alert-description{color: #ff5747 !important;font-size: 15px;text-align: left;}
.ant-form-item-has-error .input-bordered, .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,.ant-form-item-has-error .ant-picker{border-color: #ff5747 !important;}
.ant-form-item-explain-error,.ant-alert-description{color: #ff5747 !important;font-size:15px;font-weight: bolder;text-align: left;}
.network-field label{color: var(--textPrimary);}
.network-field .ant-form-item-explain {  position: absolute; left: 0px;bottom: -6px;}
.load-btn{background-color: transparent !important;border: none;}
.load-btn span{font-size: 16px;}
.load-btn:hover{color:#204483;}
/* crete account end */

/* ant alert  start*/
.cust-right-border {background-color: #2c2653;border: 1px solid #6269a3;border-bottom-right-radius: 8px;border-top-right-radius: 8px;font-size: 18px;line-height: 50px;text-align: center;width: 30%;height: 54px;    min-width: 200px;}
.ant-alert-close-icon .anticon-close{color: #ff5747!important;font-size: 18px;}
.error-white .ant-alert-description{color:#fff !important;}
/* .ant-layout-content{z-index: 999;} */
.ant-alert-error, .ant-alert-warning{background-color: transparent;border: 0;width: 100%;}
.alert-flex, .cust-auth-alert {align-items: center;background-color: transparent;border: 1px solid #ff5747;border-radius: 8px;color: #ff5747 !important;display: flex;justify-content: space-between;left: 0;margin: 16px 0;min-height: 50px;padding: 0 16px;text-align: left;width: 100%;}
.ant-alert-error .ant-alert-icon,.ant-alert-warning .ant-alert-icon{color:#ff5747!important;background-color: transparent;}
.ant-alert-description{text-align: left;color: #fff;}
.anticon.ant-alert-icon{ background-position: -570px -141px !important;margin-right: 10px;}
.card-paddingrm{padding: 0px !important;}
.card-paddingadd{padding: 35px 20px;}
.error-white .ant-alert-icon{color:#fff !important;}
/* .panel-card.buy-card.deposit-content{padding: 0 !important;} */
/* ant alert  end*/
/* batchpayments start */
.batchpayment-create-style{background-color: var(--bgCard);padding: 20px;border-radius: 8px;}
.cust-textarea textarea{font-size: 16px;line-height: 50px;border: 1px solid #6269A3 !important;background-color: transparent;border-radius: 8px !important;width: 100%;color: #fff;}
.batch-payment-table .ant-table-thead > tr > th, .batch-payment-table .ant-table {background: none;}
.batch-payment-table .ant-table-thead > tr > th, .batch-payment-table .ant-table-tbody > tr > td {border-bottom: 1px solid rgba(255, 255, 255, 0.20);border-top: 0;color: #fff;}
.batch-payment-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th, .batch-payment-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td{border-right: 1px solid rgba(255, 255, 255, 0.20);}
.batch-payment-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table{border-top:0;}
.batch-payment-table .ant-table.ant-table-bordered > .ant-table-container{border-left: 1px solid rgba(255, 255, 255, 0.20);border-top: 1px solid rgba(255, 255, 255, 0.20);}
.batch-payment-table .ant-table-container table > thead > tr:first-child th:first-child{border-top-left-radius: 8px;}
.batch-payment-table .ant-table-container table > thead > tr:first-child th:last-child{border-top-right-radius: 8px;}
.batch-payment-table  .ant-table-container {border-top-left-radius:8px;border-top-right-radius: 8px;border-bottom-left-radius:8px;border-bottom-right-radius: 8px;}
.batch-payment-table .ant-table-tbody > tr.ant-table-row:hover > td, .batch-payment-table .ant-table-tbody > tr > td.ant-table-cell-row-hover{background-color: transparent;}
.batch-payment-table .ant-table-container table > tbody > tr:last-child td:first-child{border-bottom-left-radius: 8px;}
.batch-payment-table .ant-table-container table > tbody > tr:last-child td:last-child{border-bottom-right-radius: 8px;}
.batch-payment-table .ant-table-wrapper table tbody tr td.ant-table-cell .ant-form-item-control-input-content .input-bordered{height: 42px;min-width: 160px;}
.batch-payment-table .ant-table-wrapper table tbody tr td.ant-table-cell, .middle-table{vertical-align: baseline;}
/* .batch-payment-table .ant-table-wrapper .ant-table-container .ant-table-content > table{width: 1600px;} */
h1.table-summary-fs{font-size: 14px;color: #ffffff;font-weight: 300;}
.table-summary-fs{flex: 0 0 185px;}
.table-card-summary{flex: 1;}
.table-card-summary .input-bordered{border: 0 !important;text-align: right;height: auto;line-height: 10px;display: inline-block;}
h1.table-summary-fs.due-date-fw{font-weight:600;}
.cust-btn-arrow{transform: scale(0.75);}
/* batchpayments end */
/* exchange start */
.exchange-loader{position: absolute;left:500px !important;}
.exc-bal{color: #ffffff;margin-bottom: 40px;}
.coin-label{display:block}
.label-flex{display: flex;align-items: center;width:160px;}
.bal-flex{display: flex;justify-content: space-between;align-items: center;padding: 10px;border-radius: 10px;margin-bottom: 10px;}
.bal-flex div:nth-child(2){margin-left: 4px;}
.flex-1{flex:1;}
.text-center{text-align: center;}
.balance-coin{margin-left: 8px;}
.exchange-bal{padding: 24px 0px 30px; min-height: 620px; background: #131024; border-radius: 12px;}
.withdraw-rightpanel .buy-token{margin: 30px auto;}
.btn-block div{display: inline-block !important;}
/* .btn-block{width: 315px;} */
.bal-btn { background: transparent !important; border: 1px solid #204483;}
.btn-block button{height: 40px !important;font-size: 16px !important;}
/* .deposit-rightpanel .buy-token{border:1px solid #ff1414;} */
/* .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{color:#99999A;} */
/* .dashb-btn .btn-style {width: 100%!important;} */

   
/* exchange start */

/* add asset screen start */
.deposit-content .ant-form-item-explain-error{text-align: left;}
.network-field .ant-select:not(.ant-select-customize-input) .ant-select-selector{background: var(--bgSelect) !important; border: transparent;border-radius: 8px;height: 50px;}
.network-field .ant-select-arrow{color: white !important;}
.ant-select-item.ant-select-item-option{color: #ffffff;}
.ant-select-item.ant-select-item-option:hover{background: #2C2653 !important;}
.ant-select-dropdown{background-color: #1f1d2c;}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after, .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item, .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {line-height: 50px;}
.network-field{margin-top: 20px;}
.ant-select-item-option-active:not(.ant-select-item-option-disabled){background-color: transparent;}
.ant-select-selection-item{color: white;}
.network-field .ant-select-selector{text-align: left;}
/* add asset screen end  */
.thankpage-header-mb{margin-bottom: 16px;}
/* thank you screen start */
.thank-btn{margin: 0px auto; margin-top: 34px;}
.thankyou-main{background-color: #131024; border-radius: 20px; backdrop-filter: blur(10px);background-image: url(../images/Group.png)!important; background-repeat: no-repeat; background-size: 100%;background-position: -20px 1px;}
.thank-header{font-size: 26px; font-weight: 600; color: white !important;}
.thank-currency{font-weight: 500; font-size: 18px; color: var(--textSecondary);}
.thankyou-link{margin-top: 40px;}
.icon.lg.close-blue{background-position: -347px -188px; margin-bottom: 20px}
.icon.lg{height: 26px; width: 26px;}
.wd-flex.transfer-header{padding: 0px !important;}
.ts-grid .bal-btns .card-btn.bal-btn{margin:0  0  0 auto;}
.invest-bal{font-weight: 600;font-size: 26px;line-height: 31px;color: #FFFFFF;}
.invest-currency{font-weight: 500;font-size: 18px;color: #99999A}
.balance-per{margin-left: 7px;}
.currency-right{margin-left: 20px;}
.exchange-success{padding: 0 16px;margin-bottom: 20px;}
.right-arrow.space-arrow{margin-left: 10px;}
.bal-btns{margin-right: 10px;font-size: 16px !important;}
/* .order-title{margin: 10px 0px 10px 0px;} */
/* thank you screen end */
/* card style  */
.bal-card{display: flex;padding: 8px 16px;position: relative; border-bottom: 1px solid #1d1833 !important;
    justify-content: space-between;align-items: center;}
	.bal-card:hover{background: #1d1833 !important;}	
	.bgcard-content{padding: 30px 0px;}
.text-secondary{color: var(--textSecondary) !important;}
.middle-content{text-align: left;width: 500px;}
.bal-card .ant-list-item-meta{align-items: center !important;margin-left: 7px;}
.bal-card .ant-list-item-meta-avatar{margin-right: 10px !important;}
   /* card style  */
/* searcn expand  */
/* .search-box:hover > .search-text{width: 240px !important;left: 0;} */
.search-btn {position: absolute;right:0 ;z-index:1;transition: 0.4s;cursor: pointer;text-decoration: none;}
.market-search .search-btn {position: absolute;right:15px !important ;z-index:1;transition: 0.4s;cursor: pointer;text-decoration: none;left: auto;}
.search-box:hover > .search-text{width: 240px;padding: 8px;border:1px solid #4b4b4b; border-radius: 6px;left: 0;}
.search-text:hover .search-box{border: 1px solid #fff;left: 0;}
.search-text {border: none;background: none;outline: none;padding: 0;color: white;font-size: 16px;font-weight: normal;transition: 0.4s;line-height: 26px;width: 0px;margin-left: 10px;}
.search-text::placeholder{color: #fff;}
.search-box input{margin-right: 20px;}
.search-box {align-items: center; border-radius: 40px; display: flex; flex: 300px 0; height: 46px; padding: 10px; position: relative;width: 260px;}
.filsection{font-weight: 900; font-size: 36px; line-height: 43px;text-align: center;color: var(--textWhite);display: flex;align-items: center;justify-content: center;}
.custodian-filter{font-size: 16px;font-weight: 600;color: #397EF6;}
.equal-m { margin: 0 16px;}
.filter-line{color: #999999;font-size: 14px;}
.ant-dropdown-menu {background-color: #221f34; color: #fff; border-radius: 10px; }
.db-hovlist{min-width: 365px;}
.db-hovlist.coin-item .ant-list-item-meta {align-items: center !important;}
.ant-dropdown-menu-item:hover{background-color: #221f34;}
.custodian-dropdown .ant-dropdown-menu {background-color: #221f34; color: #fff; border-radius: 10px; padding: 24px 0px 16px 0px !important;}
h3.coin-title{font-weight: 700; font-size: 16px;line-height: 19px;color: var(--textWhite);margin-bottom: 0px;}
.assetwallet-panel .withdraw-content{text-align: center;}
/* search expand  */
/* .layout-bg.left-panel .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot{margin: 0px; position: absolute;  top: 50%;left: 50%;} */
/* .ant-spin-blur::after{background: none;} */
.listcontent-scroll{max-height: 612px;overflow-y: scroll;}
.cust-qr canvas{padding: 10px;background-color: #ffffff;}
.middle-content-woBtns{width:465px;}
.exchange-deposit .layout-bg{background: transparent !important;border: 0 !important;padding: 0 !important;margin: 0 !important;}
.addasset-mb{margin-bottom: 72px;}
/* Media queries start */
.pannel-bg { background: #040015; border: 1px solid #204483; border-radius: 8px; box-shadow: 0 10px 20px rgba(57,126,246,.15); margin-bottom: 16px; margin-left: 0px; margin-right: 0px; padding: 16px;height: 100%;}
.crypto-panel{ height: auto;}
.db-quicklinks{background: linear-gradient(84.34deg, #6613BA 41.55%, #2D00AA 96.49%, #2D00AA 96.49%, #2C00AA 96.49%);border: 1px solid #204483;box-shadow: 0px 10px 20px rgba(57, 126, 246, 0.15);border-radius: 8px; padding: 16px;margin-bottom: 16px;}
.customicon:hover .icon-circle{background:  #F1F1F1 !important;box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.03);}
.icon-circle{width: 46px;height: 46px;border-radius: 50%;display: flex;align-items: center;justify-content: space-around;background: rgba(241, 241, 241, 0.1);box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.03);}
.ant-list-split .ant-list-item { border: none !important; cursor: pointer;}
.db-faitcard { backdrop-filter: blur(10px);background: #131024; border-radius: 8px; padding: 10px 16px;}
.db-fat-dollar { color: #a8a8a8; font-size: 20px; font-weight: 500;}
.db-faitcard-bal { color: #ffffff !important; font-size: 22px; font-weight: 500 !important;margin-left: 6px;}
.db-faitcard h3 { margin-bottom: 0px !important;}
.card-title.db-hw { margin-bottom: 3px;}
.db-hw span { color: var(--textPrimary); font-size: 40px; font-weight: 600;}
.db-hw {color: #a8a8a8 !important;}
.dashb-btn .btn-style { font-size: 18px !important;margin-bottom: 0;width: 100%!important;}
.icon.lg.square-arrow { background-position: -58px -145px; margin-top: 0px;}
.order-title h1 { margin-bottom: 0 !important;}
.ordrhstry-title { text-transform: capitalize !important;}
.order-hist-margin{margin-bottom: 16px;}
.dashboard-transactions .order-hist-margin { margin-bottom: 14px;}
.custom-table.card { align-content: stretch; align-items: start; background-color: var(--bgCard); border-radius: 10px; margin-bottom: 8px; padding: 10px 20px; gap: 16px;}
.custom-table .table-label { color: var(--placeholderText); font-size: 14px; font-weight: 600;}
.order-hstry h4 { width: 120px;}
.custom-table h4.table-text { color: var(--textPrimary); font-size: 16px; font-weight: 400; margin-bottom: 0 !important;}
.custom-table h4.table-text.date-width{width: 196px;}
.custom-table .td-success { color: #5cc500 !important;}
.dashb-btmtable { height: 440px; overflow: auto;}
.dashb-btmtable .ant-table, .dashb-btmtable th { background-color: #131024 !important; border-bottom-color: #393e64 !important; color: #fff !important;}
.dashb-btmtable .ant-table{ color: #fff !important;}
.dashb-btmtable th .ant-table-column-title{ color: var(--placeholderText)!important;font-weight: 600;}
.ant-table-tbody > tr > td, .custom-table .ant-table-thead > tr > th { border-bottom: 1px solid #393e64; border-top: 1px solid #393e64;}
.dashb-btmtable .ant-table-placeholder:hover .ant-table-cell, .dashb-btmtable tr:hover td { background-color: var(--bgCard) !important;}
.jusity-content-spacebetween{justify-content: space-between;}
.customicon:hover h5, h5.text-hover{font-size: 16px;font-weight: 500;color: #f1f1f1;}
.transactions-header{display: flex;align-items: center;justify-content: space-between;}
.custom-table .table-date { margin: 0 30px;margin-left: 20px;}
.flex-width {word-break: break-all;}
.mt-14 { margin-top: 14px!important;}
.ant-modal-body .coin-item {	background: var(--bgDark);	border: 1px solid #204483 !important;	border-radius: 12px;	box-shadow: 0 8px 10px rgba(57,126,246,.15);  }
.input-withdraw .ant-input{border: 1px solid #6269a3 !important;	border-radius: 8px }
.input-withdraw label {	color: #a8a8a8 !important;	font-size: 14px !important;	font-weight: 400 !important;}
.table-date .table-text{ width: 120px;}
.payee-crypto .loader-antd{padding: 0;}
.customlogo{width: auto;height: 36px;}
.market-search .search-box{width: 190px;}
.sticky-header { position: -webkit-sticky; position: sticky; top: 0; z-index: 1029;}
.cust-qr{text-align: center;}
.cash-address{font-size: 20px;margin-top: 12px !important;}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{box-shadow: none;}
.cust-formitem{display: flex;justify-content: start;align-items: center;text-align: left;}
.ant-select-arrow{color: #fff;}
/* .withdraw-summerypanel{flex: 1;} */
.sm-card-title {color: #fff !important;font-size: 22px !important;}
.summary-list-item{border-bottom: 1px solid var(--inputBorder);	padding: 20px 16px;}
.summary-list-item.border-none,.transaction-summary .ant-col:last-child .summary-list-item{border: none !important;}
.summary-list.p-36{padding: 0 36px;}
.mt-4{margin-top: 12px;}
.justify-content-end{justify-content: end;}
.icon.md.edit {
    background-position:-278px -147px;
}
.icon.md.edit:hover,.share-payment-link .ant-dropdown-menu-item:hover .icon.md.edit,.icon.edit-blue{
    background-position:-480px -147px;
}
.icon.md.status {
    background-position: -536px -89px !important;
}
.icon.md.status:hover {
    background-position:-568px -89px!important;
}
.icon.sm.alert-close {
    background-position: -788px -142px!important;
    cursor: pointer;
    flex: 20px 0;
    -webkit-transform: scale(.6);
    transform: scale(.6);
	border: 0;
}
.outlined-btn,.outlined-btn:focus {
    background-color: transparent;
    border: 1px solid #204483;
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    height: 40px;
    text-transform: uppercase;
    width: 100px;
}
.outlined-btn:hover,.outlined-btn:active {
    background-color: #242038 !important;
    border-color: #144baa;color: #fff;
}
/* auth o start  */
.register-blockwid {
    background: #131024;
    border-radius: 20px;
    margin: 0 auto;
    padding: 54px 12px;
}
.form-block .heading {
  color: var(--textWhite);
  font-size: 25px;
  font-weight: 800;
  line-height: 28px;
  margin-bottom: 24px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.custom-label label {
  color: #a8a8a8;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.custom-label .ant-form-item-label, .custom-label.ant-row {
  display: block !important;
  text-align: left;
}
.auth0-cust-form .ant-form-item-label {
  background: #131024;
  left: 21px;
  position: absolute;
  top: -14px;
  z-index: 1;
}
.custauth-mb {
  margin-bottom: 20px;
}
.custauth-mb .custselct-drpdwn.ant-select-single .ant-select-selection-item{ line-height: 58px !important;}
.auth0-cust-form .ant-input, .auth0-cust-form .ant-input-group-addon {
  background-color: transparent;
}
.auth0-cust-form .ant-input-group-addon { border: 0 !important;}
.cust-phone.cust-input .ant-input {
  border-left: 1px solid #6269a3;
  border-right: 0;
  border-top: 0;    border-bottom: 0;
  color: #fff;
  height: 56px;
}
.auth0-cust-form .cust-input {
  background-color: transparent !important;
  border: 1px solid #6269a3 !important;
  border-radius: 8px !important;
  color: #fff;
  height: 56px;
  margin-bottom: 0 !important;font-size: 14px;
}
.cust-input.phone-he.cust-phone .ant-input-group-addon .ant-select {
  margin: -5px -3px !important;
}
.cust-input.phone-he.cust-phone .ant-input-group-addon .ant-select .ant-select-arrow {
  margin-right: -3px !important;
}
.Approved.field-width.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  color: #fff;
  height: 48px !important;
}
.Approved.field-width.ant-select-single:not(.ant-select-customize-input) .ant-select-selector input{
  height: 30px !important;
}
.Approved.field-width.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item{
 font-size: 14px;
}
.mail-verify-contain .text-style{font-size: 16px;}
.custselct-drpdwn .ant-select-selector{border: none !important;}
.policy-content .ant-form-item-control-input-content {
  display: flex;
  gap: 0px;
}
.policy-content .ant-checkbox-inner{background-color:transparent;border: 1px solid #6613ba;}
.policy-content .check-error .ant-checkbox-inner,.policy-content .check-error .ant-checkbox-inner:hover,.policy-content .check-error .ant-checkbox-wrapper:hover .ant-checkbox-inner{background-color:transparent;border: 1px solid #ff4d4f !important;}
.policy-content .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner,.policy-content .ant-checkbox-wrapper:hover .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
	background: linear-gradient(89.35deg,#6613ba 31.31%,#2c00aa 94.88%);
	border: 1px solid #6613ba !important;
  }
.terms-text { color: #8a8a8a;}
.pop-btn.cust-color-style { background: linear-gradient(89.35deg, #6613ba 19.31%, #2c00aa 51.88%) !important;
  border: 0; border-radius: 42px !important; color: #fff; font-size: 18px; font-weight: 600; height: 67px;}
.cust-auth-btn .cust-color-style { margin-left: auto; width: 100%;}
.cust-btn-right { margin-left: auto;}
.icon.lg.greencheck {  background-position: -31px -34px;  position: absolute;  right: 20px;  top: 18px;}
.reffername {  color: #00b93f;  overflow: hidden;  position: absolute;  right: 14px;  text-align: right;  text-overflow: ellipsis;  top: -20px;  white-space: nowrap;  width: 220px;}
.cuat-auth-width .ant-radio-wrapper{color: #fff; }
/* .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner {	border: 1px solid #6613ba; } */
.policy-content .ant-checkbox-wrapper:hover .ant-checkbox-inner,.policy-content .ant-checkbox:hover .ant-checkbox-inner,.policy-content .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {	border: 1px solid #6613ba !important; }
.phone-he .ant-select-arrow{top: 36%;}
.ant-radio-checked .ant-radio-inner {border: 1px solid #204483;}
.ant-radio-inner::after {background: linear-gradient(89.35deg,#6613ba 31.31%,#2c00aa 94.88%);}
.ant-radio-inner {background: #040015;border: 1px solid #204483;height: 24px;width: 24px;}
.ant-radio-input:focus + .ant-radio-inner{box-shadow: none;}
.ant-radio-checked .ant-radio-inner::after { transform: scale(0.9);}
.ant-radio-wrapper {align-items: center;}
.busimness-mt .ant-alert-message,.mobile-verification .ant-alert-message{color: #ff4d4f !important;}
.busimness-mt .ant-alert-error{margin-bottom: 30px;border-color: transparent !important;}
.ant-form-item-has-error .phone-he :not(.ant-input-disabled):not(.ant-input-borderless).ant-input{background: transparent !important;}
.ml-10{margin-left: 10px;}
.auth-phverification{margin-left: 10px;}
.cuat-auth-width .ant-alert-error { text-align: left; margin-bottom: 30px;}
  /* autho end  */
.btn-style.payee-popup {
    display: inline-flex!important;
    height: 40px!important;
    justify-content: center;
    text-align: center!important;
    width: 100px!important;
}
.btn-style.payee-popup span {
    font-size: 18px!important;
}
.card-title {
    color: var(--textPrimary)!important;
    text-transform: capitalize!important;font-size: 22px !important; font-weight: 500 !important;
}
.pop-btn-group{padding: 0 14px 14px 0;}
.bal-title {
    color: var(--textPrimary)!important;
    font-size: 22px!important;
    font-weight: 600!important;
    line-height: 24px;
    margin: 36px 0 0;
    text-align: left;
    text-transform: capitalize;vertical-align: middle;
}
.payee-inblock {
    border-radius: 20px;
    margin-bottom: 30px;
    padding: 24px;
}
.p-absolute {
    font-weight: 400;
    left: 7px;
    position: absolute;
    top: -12px;
}
.payee-token { background: #131024; border-radius: 8px; display: inline-block; z-index: 1;color: #fff;padding-left: 9px;padding-right: 4px;}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 53px!important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px rgb(0, 0, 0) inset !important;
	-webkit-text-fill-color: #ffffff;
}
.declaration-content .ant-modal-close-x {
	position: absolute;
	right: 2px;
	top: 12px;
	z-index: 1;
  }
  .ant-table-cell-scrollbar{box-shadow: none;}
  .coin-flex .ant-list-item-meta-content{display: flex;align-items: center;gap: 8px;}
  .custom-table div:not(:nth-child(1)),.custom-table div:not(:nth-child(2)){flex-basis: 0;flex-grow: 1;}
  .transaction-pop .ant-modal-title{color: #FFF;font-size: 22px !important;    font-weight: 600 !important;line-height: normal;text-transform: uppercase;}
  .transaction-pop .ant-modal-header{padding-top: 24px;padding-bottom: 24px;}
  .transaction-pop .pop-btn-group button{width: 150px !important;font-size: 18px;}
  .transaction-details h1{text-align: left !important;margin-left: 0;margin-bottom: 2px;}
  .transaction-details h1:nth-child(2){margin-top: 0;}
  .transaction-details{padding: 0 30px 10px 0;}
  .transaction-pop .ant-modal-body .ant-row{padding: 0 16px;}
  .export-pdf .btn-style:hover{transition: 0.5s;background-image: linear-gradient( to right, #2C00AA 0%, #6613BA 50%, #2C00AA 100%) !important;}
  .selectcoin-block{display: block !important;width: 100% !important;margin: 0px auto !important;margin-bottom: 24px !important;}
  .selcet-label{font-size: 16px;color: #fff;}
  .transactions-header .grid-transaction .search-btn{margin-right: 35px;    background: linear-gradient(89.35deg, #6613BA 19.31%, #2C00AA 51.88%);    padding: 8px 10px;    border-radius: 20px;}
.transactions-header .grid-transaction .search-btn:hover,.grid-transaction .search-box:hover .search-btn{background:#000 ;}
.addon-icon{display: flex;justify-content:end;align-items: center;}	
.addon-icon .sm-card-title.bal-title{margin: 0 !important;}
td.ant-table-column-sort {background: #1a1534;}
.payee-leftdata div{text-overflow: ellipsis;
    overflow: hidden;
    flex-basis: 0;
    flex-grow: 1;}
.td-success {color: var(--textGreen)!important;}
.inactive-text {color: red;}
.mb-30{margin-bottom: 30px !important;}
.my-30{margin-top: 30px !important;margin-bottom: 30px !important;}
.bash-market-table img{filter: invert(1);}
.mt-0{margin-top: 0 !important;}
.mb-60{margin-bottom: 60px !important;}
.cryptowallets-panel .ant-list-items{overflow-y: auto;max-height: 500px;}
.cryptowallets-panel{height: 586px; overflow: hidden;}
.dashboard-portfolio{min-height: 409px;}
.payees-input {position: relative;}
.payees-input .ant-form-item-label{height: fit-content;position: absolute;top: -13px; left: 8px;background: #131024; z-index: 999;border-radius: 8px; padding: 0 4px 0 9px !important;}
.payees-input .ant-form-item-required{color: #a8a8a8;height: auto;}
.height-auto{min-height: auto !important;}
.get-code .ant-btn::before{opacity: 0;}
.get-codebtn .ant-btn:hover, .get-codebtn .ant-btn:focus, .get-codebtn .ant-btn:active{background: none !important;}
.ant-drawer-wrapper-body {
    background: var(--bgCard);
}
.drawer-maintitle {
    color: #fff!important;
    font-size: 22px;
    font-weight: 600;
    text-transform: capitalize;
}
.profile-value {
    /* color: var(--textSecondary); */
    font-size: 16px;
    font-weight: 500;
    margin-top: 8px;
	color: #f1f1f1
}
.notifications-list .profile-value{margin-top: 0px;}
.value-description {
    /* color: #f1f1f1; */
	color: var(--textSecondary);
	font-size: 16px;
}
.profile-label {
    color: #fff;
}
.firstletter-design{display: flex;align-items: center;justify-content: center;background: #212121;border: 1px solid #e5e5e5;color: #fff;border-radius: 50%;height: 46px;width: 46px;font-size: 22px;font-weight: 600;}
h1.seleted-name{ font-weight: 500; font-size: 24px; line-height: normal; color: #ffffff;margin-bottom: 0;margin-left: 8px;word-break: break-all;}
.summary-width .summary-text{ max-width: 250px;}
.buy-selectgroup .crypto.coin{margin-left: 6px;}
.ant-drawer-header {
    background: #131024;
    border-bottom: 0;
}
.side-drawer-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.ant-drawer{z-index: 2000;}
.notifications-list .ant-list-item-meta-avatar {
    border-radius: 50%;
    height: 38px;
    line-height: 38px;
    text-align: center;
    width: 38px; background-color: #4db205; margin-top: 7px;
}
.notifications-list .ant-list-item-meta {
    align-items: flex-start;
}
.addbg .ant-list-item-meta-avatar, .approvebg .ant-list-item-meta-avatar, .buybg .ant-list-item-meta-avatar, .deposit.cryptobg .ant-list-item-meta-avatar, .deposit.fiatbg .ant-list-item-meta-avatar, .depositbg .ant-list-item-meta-avatar, .payeefiatbg .ant-list-item-meta-avatar, .paymentsbg .ant-list-item-meta-avatar, .transferbg .ant-list-item-meta-avatar {
    background-color: #4db205;
}
.sellbg .ant-list-item-meta-avatar, .withdraw.cryptobg .ant-list-item-meta-avatar, .withdraw.fiatbg .ant-list-item-meta-avatar, .withdrawbg .ant-list-item-meta-avatar {
	background-color: #ff2400;
  }
.ant-list-vertical .ant-list-item-meta-title{margin-bottom: 0;}
/* .crypto-balancepanel .card-title {margin-bottom: 8px;} */
.ant-notification-notice{background: #212646;color: var(--bgWhite);top: 65px;border-radius: 8px;}
.ant-notification-notice-message{color: var(--bgWhite);margin-bottom: 0;}
.ant-notification-notice .ant-modal-close-x{transform: scale(0.8);margin-top: 0;margin-right: 0;}
.ant-modal-close{top: 34px;right: 28px;}
.py-8{padding: 0 8px !important;}
/* .ant-modal-header .card-title{text-transform: uppercase !important;} */
.mb-20{margin-bottom: 20px !important;}
.text-red{color: #ff1414;}
.text-blue{color: #1890ff;}
.text-pending{color: #ffa604 !important;}
.notifications-list .ant-list-item{cursor: default;padding-left: 0;padding-right: 0;}
.market-flex{margin-bottom: 8px;gap: 8px;align-items: center;}
.input-disabled{cursor: not-allowed;}
.overflow-ellipsiis{ white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
.px-10{padding: 0 10px;}
.coin-search .ant-input{font-size: 16px;}
.mt-30 {margin-top: 30px !important;}
.label-card {background-color: #131024;border-radius: 8px;color: var(--textSecondary);cursor: pointer;font-weight: 400;left: 13px;padding: 4px 12px;position: absolute;text-transform: none;top: -12px;z-index: 1;}
.summary-input {background: var(--bgCard) !important;background-color: transparent; border: 1px solid #6269a3 !important;border-bottom-left-radius: 8px !important;border-top-left-radius: 8px !important;font-size: 18px;height: 50px;line-height: 50px;text-align: left;}
.transaction-pop .ant-modal-close{top: 26px;}
.coin-search-input .ant-input-affix-wrapper-borderless {padding: 12px 20px;}
.hy-align {align-items: center;border-left: transparent !important;margin: auto 0;}
.email-verify{border-radius: 8px !important;font-size: 16px;}
.email-verify input,.email-verify .ant-input[disabled]{background-color: transparent;border: 0;height: 100%;font-size: 16px;padding: 0 18px;}
.get-codebtn{border-radius: 0px 5px 5px 0px;background: #2C2653;height: 100%;margin: 0 auto;width: 100%;padding: 0 16px !important;}
.hy-align .ant-btn-loading:before {background-color: transparent !important;}
.veriy-color span{color: #a9a9a9;font-size: 14px;text-transform: capitalize;}
.get-codebtn .ant-btn{padding: 0 6px 0 0;height: 100%;border: 0;color: #ffffff;font-size: 14px;display: flex; align-items: center;  justify-content: center;background: transparent !important;}
.payees-input.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input{background: transparent;}
.ant-slider-mark .ant-slider-mark-text strong{display: flex;margin-left: 0 !important;gap: 4px;}
/* .text-red-colr{color: #ff1414 !important;font-size: 14px;} */
/* .payees-input .ant-form-item-explain{ position: absolute; top: 56px;} */
.text-uppercase{text-transform: uppercase;}
.icon.md.info{background-position:-83px -350px;transform: scale(.8);width: 28px;height: 38px;}
.available-balance{font-size: 26px;font-weight: 300;color:#99999A;}
.graph-btns{text-align: right;margin-bottom: 10px;}
.graph-btns .ant-btn{background: #000; color: #adadad; padding: 4px 12px;border-color: #204483;outline: none;border-radius: 0;}
.graph-btns .ant-btn:hover,.graph-btns .ant-btn:active,.graph-btns .ant-btn:focus,.graph-btns .ant-btn.active{border-color: #204483 !important;
  background: linear-gradient(84.34deg, #6613ba 41.55%, #2d00aa 96.49%, #2d00aa 96.49%, #2c00aa 96.49%);color: #fff;}
  .graph-btns .ant-btn:first-child{border-top-left-radius:8px ;border-bottom-left-radius: 8px;}
  .graph-btns .ant-btn:last-child{border-top-right-radius:8px ;border-bottom-right-radius: 8px;}
  .db-hw-text { color: var(--placeholderText); font-size: 16px; font-weight: 400;display: -webkit-box;
	-webkit-line-clamp: 8;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;}
.buy-max:hover {border-bottom: 1px solid #397ef6 !important;color: #397ef6;}
.buy-max {border-bottom: 1px solid transparent;background-color: transparent !important;color: #fff;border: none;}
.withdraw-input .wd-entrysell.ant-input{border-bottom: 1px solid #6269a3 !important;height: auto;padding-bottom: 4px;}
.buy-max.ant-btn { outline: none;    border-bottom: 1px solid transparent;}
.walletCode-panel{margin-left: 16px;}
.buy-crads-data{min-width: 1230px;}
.dashboard-transactions .buy-crads-data{min-width: 824px;}
.markets-table .ant-table-body{overflow-y: auto !important;}
.payee-input .ant-select-selector{padding-left: 18px !important;}
.layout-bg.right-panel.panel-bg.tracnsaction-details{width: 100%;}
.summary-content{background-color: #131024; padding: 0 24px; margin: 30px 0;border-radius: 8px;}
.summary-content-darkbg{padding: 0 24px; margin: 30px 0;border-radius: 8px;border: 1px solid #204483;background: #040015;box-shadow: 0px 8px 10px 0px rgba(58, 145, 145, 0.15);}
.transfer-form{background-color: #131024; padding: 24px; margin: 30px 0;border-radius: 20px;}
.icon.lg.btn-arrow-back { background-position: -378px -187px; margin-right: 8px; -webkit-transform: rotate(180deg); transform: rotate(180deg);}
.btn-style:disabled:hover,.btn-style[disabled],
.btn-style[disabled]:hover {background: linear-gradient(89.35deg,#6613ba 19.31%,#2c00aa 51.88%) !important;color: rgba(255, 255, 255, 0.7);transition: none;cursor: not-allowed;}
/* .get-codebtn .icon.md.info{position: absolute;right: 16px;} */
.mb-26{margin-bottom: 26px !important;}
.order-hstry h4.transaction-id-text{width: 185px;}
.transactions-scroll .custom-table.card{min-width: 1290px;}
.wallet-item {background: #000;border: 1px solid #397ef6;border-radius: 8px;color: var(--textWhite); padding: 34px 30px;}
.super-menu {width: 90%;}
h1.ant-typography.super-title {color: #fff;font-size: 28px;font-style: normal;font-weight: 600;line-height: normal;}
.wallet-menu {align-items: start;background: #27233a;display: flex;padding: 20px;height: 100%;min-height: 130px;}
.wallet-menu, .wallet-menu:hover {border-radius: 8px;box-shadow: 0 10px 20px 0 rgba(57, 126, 246, .15);}
.wallet-item .icon {flex: 26px 0;flex-basis: 26px; flex-grow: 0;flex-shrink: 0;height: 26px;margin-right: 15px;width: 26px;}
h1.ant-typography.supermenu-title {color: #f1f1f1;font-size: 18px;font-style: normal;font-weight: 600;letter-spacing: .36px;line-height: normal;margin-bottom: 0;text-transform: uppercase;}
.app-loader{ height: 100vh;display: flex;align-items: center;justify-content: center;}
.cryptowallets-panel .ant-list-split .ant-list-item{cursor: default;}
.ant-tooltip-inner{  background: #040015;border: 1px solid #204483; box-shadow: 0px 10px 20px rgba(57, 126, 246, 0.15); border-radius: 20px;  padding: 10px;}
/* search expand start  */
.market-flex .coin-search input {background: var(--bgCard);cursor: pointer !important; height: 46px;padding: 0 12px;position: absolute;right: 52px;text-decoration: none;top: 0;transition: .4s;width: 115px;z-index: 1;border-top-left-radius: 25px;border-bottom-left-radius: 25px;}
.coin-search.dt-topspace.markets-search{margin-bottom: 10px;}
/* search expand end  */
.rejection-description{min-width: 214px;padding-right: 0 !important;}
.panel-balhead-title{    font-size: 24px !important; font-weight: 500 !important; margin-bottom: 24px !important;color: #fff !important;}
.mt-40{margin-top: 40px;}
.buy-max span { font-size: 18px; font-weight: 700;}
.pl-18{padding-left: 18px !important;}
.addpayee-star .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after{display: inline-block;    margin-right: 4px;    color: #ff4d4f;    font-size: 12px;       line-height: 1;    content: '*';}
.addpayee-star .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{content: '';}
.icon.bank {    background-position: -163px -242px;}
.icon.wallet-icon {    background-position: -280px -243px;}
.icon.exchange {    background-position: -239px -243px;}
.wallet-menu:hover .icon.bank {    background-position: -3px -242px;}
.wallet-menu:hover .icon.cards {    background-position: -39px -243px;}
.wallet-menu:hover .icon.exchange {    background-position: -79px -243px;}
.wallet-menu:hover .icon.wallet-icon {    background-position: -120px -243px;}
.icon.cards {    background-position: -199px -243px;}
.wallet-menu:hover {    background: linear-gradient(61deg, #6613ba 30.82%, #2c00aa);}
  /* required mark issues start */
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{margin-left: 5px;}
  .ant-form-item-required.ant-form-item-no-colon{flex-direction: row-reverse;}
  .required-reverse .ant-form-item-required.ant-form-item-no-colon{flex-direction: row;}
  /* required mark issues end */
  .refferal-code-input .ant-form-item-no-colon{padding-left: 10px;}
  .db-mb-cust{ color: hsla(0, 0%, 100%, .9) !important; font-size: 32px !important; font-weight: 800 !important;}
  .text-personal{font-size: 18px; font-weight: 700;}
  .cust-phnimage { width: 42px;margin-top: -4px}
.card-disable .card-title {    color: var(--textSecondary) !important;    text-transform: none !important;}
.ant-modal-footer {  margin-bottom: 16px;padding: 0 36px;border-radius: 0 0 8px 8px;  text-align: center;padding-bottom: 36px;}
.trans-card .summary-text{flex: 1;}
.payee-modal.custom-modal .ant-modal-header{padding: 0;}
.ant-breadcrumb a:not(:last-child){cursor: pointer;}
/* .dt-container{ padding: 30px !important;padding-top: 0px !important;} */
.dt-container .list-dot .dt-selectext{ color: #a8a8a8; font-size: 14px; font-weight: 500; margin: 15px 0 10px !important; text-align: left;}
.dt-container ::marker {color: #a8a8a8; }
  .account-progress{ background: #131024; border-radius: 14px; color: #fff; height: 350px; margin: 80px auto;padding: 40px;}
  .justify-center {justify-content: center !important;}
  .spinning-loader{width: 120px;}
  .sumSub-review,.approve-email{font-size: 16px;color: #fff;}
  .search-bg{background-color: #1b182e;}
  .withdraw-component .coin-item{ background: #040015; border: 1px solid #204483 !important; border-radius: 12px;  box-shadow: 0 8px 10px rgba(57, 126, 246, .15);}
  .withdraw-component .coin-item:hover,.withdraw-component .coin-item.active{ background: #2c2653;}
  .payee-summary-bank  .summary-list-item { border-bottom: 1px solid var(--inputBorder);padding: 20px 0px;position: relative; }
  .lable-position { background: #131024; padding: 0 6px; position: absolute; top: -12px;}
  .lable-position .payee-label{line-height: normal;text-transform: none;}
  .payee-summary-bank .summary-text{max-width: 100%;}
  .mb-36{margin-bottom: 36px !important;}
  .withdraw-network .ant-select-selection-placeholder,.withdraw-network .ant-select-selection-item{line-height: 56px !important;}
  .panel-card.text-center {padding: 30px;}
.panel-card {background-color: var(--bgLightGray);border: 1px solid var(--borderGrey);border-radius: 8px;margin: auto;}
.coin-item.active {background: #2c2653 !important;}
.withdraw-component {max-height: 400px;overflow: auto;}
.submitted-text{color:#F79604}
.verify-mail-mb {
    margin-bottom: 40px !important;
}
/* auth o strt */
.cust-text-mt.auth-mt {margin-top: 0 !important;}
.text-style {color: hsla(0, 0%, 100%, .7) !important;font-size: 14px !important;font-weight: 500 !important;margin-top: 16px !important;}
.db-main-title {color: hsla(0, 0%, 100%, .9) !important;font-size: 32px !important;font-weight: 800 !important;margin-bottom: 8px !important;}
.ant-input:focus, .ant-input-focused{box-shadow: none;}
.auth-mb-title{margin-bottom: 40px !important;}
/*auth o end  */
/* ṭoaster start */
.ant-message {bottom: 15px !important;top: auto;z-index: 9999;}
.custom-msg {font-size: 16px;font-weight: 700;}
.custom-msg .ant-message-notice-content {background: #333;color: #fff;min-width: 200px;padding: 20px 40px;}
.transfer-card {-webkit-backdrop-filter: blur(10px);backdrop-filter: blur(10px);background-color: var(--bgCard);border-radius: 20px;padding: 18px;}
/* toaster end */
input:-webkit-autofill, input:-webkit-autofill:active, input:-webkit-autofill:focus, input:-webkit-autofill:hover {
    -webkit-text-fill-color: #fff; -webkit-background-clip: text;
    box-shadow: inset 0 0 20px 20px #23232329; -webkit-transition: background-color 5000s ease-in-out 0s; transition: background-color 5000s ease-in-out 0s;}
.verifications-form .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input{border-color: #6269a3 !important;box-shadow: none;}
.netwok-stye{gap: 16px;}
.netwok-stye .coin-item.active{background: #2c2653 !important;}
.netwok-stye .coin-item{border: 1px solid #204483 !important;background: var(--bgDark);padding-top:0;padding-bottom:0}
.bg-transparent{background-color: transparent;}
.justify-content-center {    justify-content: center !important;}
.buy-token.bg-transparent{margin: 0;}
.h-auto{height: auto;}
.ant-select-dropdown .ant-select-item-option-selected,.ant-select-item-option-active{background: #2c2653 !important;}
.cust-right-border button {    padding-left: 0;}
/* payment gateway start  */
.col-space{padding: 16px;}
.label-style{color: var(--placeholderText); font-size: 14px; font-weight: 600;}
.avail-balance.ant-typography{font-weight: 600;color: #fff;margin-bottom: 0;line-height: normal;}
.icon.sm.downarrow { background-position: -272px -125px;}
.tranfer-btn.ant-btn.btn-style{font-size: 18px;height: 46px !important;width: fit-content;margin-right: 16px;}
.border-left{border-left:1px solid #204483 ;padding-left: 28px !important;}
.ml-8{margin-left: 8px;}
.ant-btn-circle{z-index: 1;background: rgb(255 255 255 / 20%);    border: transparent;color: #767172;top:50%  !important;}
.ant-btn-circle:hover,.ant-btn-circle:focus{background: rgb(255 255 255 / 20%); color: #ffffff;}
.ant-tabs-top > .ant-tabs-nav::before{border: transparent;}
.slide-card{background: #1d1935;border-radius: 8px;padding: 12px;display: flex !important;gap: 6px;}
.slide-card-label.ant-typography{font-size: 16px;font-weight: 500;color: #A8A8A8;margin-bottom: 0}
.slide-card-description.ant-typography{font-size: 14px;font-weight: 400;color: #fff;margin-bottom: 0;}
.icon-bg{border-radius: 50%;height: 38px; line-height: 38px; text-align: center; width: 38px;transform: scale(0.6);flex-shrink: 0;}
.bg-green{background: #4db205;}
.slide-top-space{margin-bottom: 8px !important;}
.p-relative{position: relative !important;} 
/* accordians start  */
.ant-collapse-item{background: #26213f;margin-bottom: 14px;border-radius: 8px !important;}
.ant-collapse-item-active .ant-collapse-header{color: #fff !important;font-weight: 600;background-color: #2c2653;border-radius: 8px 8px 0 0 !important;}
.ant-collapse-header{color: #fff !important;flex-direction: row-reverse;    justify-content: space-between;}
.ant-collapse-header p{overflow: hidden; text-overflow: ellipsis; white-space: nowrap;font-size: 16px;    font-weight: 600;}
.ant-collapse{background-color: transparent;border: transparent;height: 324px;overflow: auto;}
.ant-collapse-content{background: #131024;border: transparent;border-radius: 0 0 8px 8px !important;}
.ant-collapse > .ant-collapse-item{border-bottom: transparent;}
.anticon-right{margin-right: 0 !important;rotate: 90deg;}
.vault-accordion .anticon-right{vertical-align: -3px !important;}
.ant-collapse-item-active .anticon svg{transform: rotate(180deg) !important;}
/* accordians end  */
.marchant-table{background-color: transparent;height: auto !important; }
.marchant-table table{border: 1px solid #393e64;border-radius: 8px;}
.marchant-table table thead th:first-child{border-top-left-radius: 8px !important;}
.marchant-table table thead th:last-child{border-top-right-radius: 8px !important;}
.marchant-table table tbody tr:last-child td{border-bottom: transparent;}
.ant-collapse-content-box .coin-search-input{background-color: #252232;}
.marchant-table th{color: var(--placeholderText)!important;font-weight: 600;font-size: 16px;}
/* .payment-links{overflow: auto;height: 394px;} */
.payment-links .custom-table .order-hstry:first-child{flex: 196px 0;white-space: nowrap;}
.transaction-table .custom-table .order-hstry:nth-child(2){flex: 230px 0;white-space: nowrap;}
.view-more{font-size: 16px;vertical-align: middle;}
.payment-link{width: 100% !important;margin: 0;margin-bottom: 16px;font-size: 22px !important;height: 56px !important;}
.marchant-table td,.marchant-table th{padding: 10px 16px!important;}
.slide-btn-right button:nth-of-type(2){ rotate: -90deg;}
.input-note{font-size: 14px;font-weight: 400;color: rgba(255, 255, 255, 0.80);margin-top: 4px !important;}
.label-top{color: #a8a8a8; font-size: 14px !important; font-weight: 400 !important; background: #131024; border-radius: 8px;left: 16px; position: absolute; top: -11px;padding: 0 4px;}
.payment-links .custom-table .order-hstry h4 .coin{transform: scale(0.8);}
.text-green{color: #5CC500;}
.marchant-table thead th:nth-child(2),.marchant-table tbody tr td:nth-child(2){padding-left: 0 !important;}
.create-payement-style{background-color: var(--bgCard); border-radius: 20px;  margin: 24px auto; padding: 35px;}
.option-secondary{font-size: 12px;font-weight: 400;color: #a8a8a8;margin-bottom: 0;line-height: normal;}
.option-primary{font-size: 16px;font-weight: 400;color: #fff;margin-bottom: 0;line-height: normal;}
.option-hover:hover{background: #2c2653;}
.mr-6{margin-right: 8px;}
.mb-10{margin-bottom: 10px !important;}
.crypto.coin.sm.polygon { background-position: -763px -533px;}
.ant-form-item-no-colon{color: var(--placeholderText) !important;}
.invoice-logo{width: 20px;flex-shrink: 0;}
.invoice-name{font-size: 18px;font-weight: 500;color: #fff;word-wrap: break-word;}
.invoice-btc{font-size: 24px;font-weight: 500;color: #fff;margin: 6px 0;}
.invoice-text{font-size: 14px;font-weight: 500;color: #fff;}
.invoice-label{font-size: 14px;font-weight: 500;color: #a8a8a8;}
.icon.lg.info-icon{ background-position: -84px -357px;}
.gap-8{gap: 8px;}
.mb-16{margin-bottom: 16px !important;}
.ant-divider-horizontal.ant-divider-with-text{border-top-color: rgb(75 74 74);}
.share-icons{display: flex;align-items: center;justify-content: start;gap: 10px;margin-top: 8;}
.share-icons .ant-menu-item-selected{padding: 0 !important;background-color: transparent !important;}
.scanner-barcode{width: 165px;}
.invoice-modal .ant-modal-content{padding: 0;}
.invoice-modal .ant-modal-header{padding: 24px 36px;background: #171a30;}
.invoice-modal .ant-modal-body{padding: 36px ;}
.invoice-modal .ant-modal-close { top: 23px;}
.transactions-search.d-flex {	gap: 16px;}
.ant-picker-suffix,.ant-picker-range-separator .ant-picker-separator{color: #fff;}
.ant-picker.payee-input{padding-left: 12px;}
.ant-picker-input input{color: #fff;font-size: 16px;}
.flex-wrap{flex-wrap: wrap;}
.view-block .ant-row{width: 100%;}
.coin-select-overlay ul li{display: flex;align-items: center;gap: 4px;}
.coin-select-overlay{width: 145px;}
.ant-collapse .coin-item{background:#1c1735 ;}
.p-20{padding: 20px;}
.mb-4{margin-bottom: 4px;}
.invoice-header{padding: 24px 27px;background: #171a30;border-top-left-radius: 8px;border-top-right-radius: 8px;}
.pt-0,.withdraw-content.pt-0{padding-top: 0 !important;}
.always-default-epinner .ant-spin-dot-spin .ant-spin-dot-item{ display: block; transform: scale(0.75); animation: antSpinMove 1s infinite linear alternate;}
.always-default-epinner .ant-spin-dot-spin{width: 1em; height: 1em;transform: rotate(45deg); animation: antRotate 1.2s infinite linear; background-image:none;}
.select-spinner-position{position: absolute;right: 16px;top: 13px;}
.select-arrow-hidden .ant-select-arrow{visibility: hidden;}
.icon.sm.negation{background-position: -145px -18px;width: 22px; height: 10px;margin-bottom: 3px;}
.placehoder-inherit .ant-select-selection-placeholder,.placehoder-inherit .ant-select-selection-item{line-height: inherit !important;}
.usd-today-value{font-size:14px;font-weight: 500;color: #fff;}
.error-block .ant-form-item-explain{position: static;}
.input-bordered.ant-select-disabled .ant-select-selector{background: transparent !important;}
/* payment gateway end  */
/* payment section start  */
.payment-section{border-radius: 8px;border: 1px solid #A0A1A2;box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);width: 488px;margin: 30px auto;background: #fff;padding: 16px;}
.logo-flex{display: flex;align-items: center;gap: 6px;justify-content: center;}
.logo-flex .logo-style{width: 20px;height: auto;}
.logo-flex h3{font-size: 26px;font-weight: 600;color: #000;margin-bottom: 0;}
.scanner-style img{width: 246px;}
.scanner-style{text-align: center;}
.payment-section input{border: 1px solid #9FA2B4;border-radius: 5px;height: 46px;width: 100%;outline: none; padding: 0 12px;}
.payment-section .ant-form-item{position: relative;}
.payment-section .ant-form-item-label{position: absolute;top: -10px;left: 10px;z-index: 1;height: auto;line-height: normal;background: #fff;padding: 0 4px;font-size: 14px;font-weight: 400;color: #000;}
.payment-section .ant-form-item-label label{line-height: normal;height: auto;color: #000 !important;}
.payment-section .ant-form-item-label label::after{display: none;}
.payment-section input:-webkit-autofill, .payment-section input:-webkit-autofill:hover, .payment-section input:-webkit-autofill:focus, .payment-section input:-webkit-autofill:active{-webkit-box-shadow:0 0 0 30px rgb(255 255 255) inset !important;color: #000;-webkit-text-fill-color:#000;}
.payment-section h4{color: #000;font-size: 16px;font-weight: 500;margin-bottom: 10px;}
.payment-section .metamask-connect{background: #EEF0F4;padding: 9px;border-radius: 8px;display: flex;justify-content: space-between;align-items: center;cursor: pointer;}
.mb-18{margin-bottom: 18px !important;}
.payment-section .address-text{display: flex;align-items: center;justify-content: center;gap: 10px;font-size: 14px;font-weight: 500;color: #000;margin-top: 10px;}
.payment-section .balance-text{font-size: 14px;font-weight: 500;color: #000;text-align: center;}
.payment-section .text-gray{color: rgba(0, 0, 0, 0.50);}
.payment-section .mx-6{margin: 6px 0;}
.payment-section .submit-btn{border-radius: 6px;border: 1px solid #4096FF;background: #4096FF;color: #fff;text-align: center;height: 41px;width: 100%;font-weight: 500;}
.filters-wrap{flex-wrap: wrap;row-gap: 16px;}
.commisions-terms{color: rgba(255, 255, 255, 0.80);font-size: 14px;}
/* payment section end  */
.page-White{background: #fff;padding: 30px 0;}
.merchants-section .coin-search.dt-topspace{margin-top: 4px !important;}
.ant-picker-range .ant-picker-clear { right: 17px;}
.icon.excel { background-position: -542px -31px !important; height: 24px; width: 24px;}
.icon.statechange-icon{background-position:-605px -31px !important; height: 24px; width: 24px;}
.export-pdf .btn-style {  margin: 0px; padding: 0 11px;height: 46px !important;white-space: nowrap;transform: scale(0.8);border-radius: 50% !important;}
.mx-0{margin-left: 0 !important;margin-right: 0 !important;}
.icon.add-links { background-position: -303px -146px !important; height: 24px; width: 24px;}
.checkbox-column{max-width: 60px;width: 50px;margin-top: 6px;}
.share-payment-link.ant-dropdown-menu{background: #040015; border: 1px solid #204483; box-shadow: 0px 10px 20px rgba(57, 126, 246, 0.15); border-radius: 20px; padding:10px !important;}
.share-payment-link .ant-menu-item:active,.share-payment-link .ant-menu-item:hover{background: transparent !important;}
.checkbox-column .ant-form-item{margin-bottom: 0;}
.icon.share-icon { background-position: -577px -31px !important; height: 24px; width: 24px;}
.share-payment-link .share-adrs.ant-menu-vertical .ant-menu-item {padding-bottom: 0 !important;height: auto !important;}
.paylinks-dropdown .ant-row.ant-form-item.ant-menu-item-only-child{margin-bottom: 0 !important;}
.merchants-section .ant-list-split .ant-list-item{cursor: default;}
.merchants-section .coin-item .ant-list-item-meta-avatar{margin-top: 0;}
.create-payement-style .ant-select-open .ant-select-selector,.create-payement-style .ant-form-item-has-error .ant-select-open .ant-select-selector{box-shadow: none !important;border:none !important;}
.create-payement-style .ant-select-disabled,.create-payement-style input:disabled{cursor: not-allowed;}
.mb-2{margin-bottom: 2px;}
.edit-scale{transform: scale(0.7);}
.edit-scale.icon.md.edit { background-position: -277px -147px;}
.rounded-5{border-radius: 5px;}
.margin-none.dropdown-list{ margin: 0px 0px;}
.share-payment-link.rounded-8{border-radius: 8px;padding: 6px !important;}
.margin-none.dropdown-list li{ padding: 2px 16px 2px 10px;}
.recent-transactions .id-width,.recent-transactions .date-feild h4,.all-transactions .id-width,.all-transactions .date-feild h4{width: 200px;    word-break: normal;}
.recent-transactions .date-feild,.all-transactions .date-feild{flex: 200px 0 0;}
/* .recent-transactions,.all-transactions{min-width: 1260px;} */
.all-transactions .date-feild .table-date{ margin: 0px 0px;}
.h-full{height: 100%;}
/* welcome screen start  */
.banner-shadow{background-image: url("../images/banner_shadow.svg");background-position: center;padding-bottom: 50px;padding-top: 50px;}
.crypto-img{width: 710px;}
.banner-shadow::before { content: ''; position: absolute; top: 50px; left: 0;width: 100%; height: 100%;
  background-image: url('../images/galaxy_bg.svg');  background-size: 140%; background-position: center; background-repeat: no-repeat; z-index: -1; }
  .wallets-title{font-size: 40px;font-weight: 800;color: #fff;}
.wallets-title span{font-size: 40px;line-height: normal;display: inline-block;font-weight: 500;}
.align-items-center{align-items: center;}
.justify-end{justify-content: end;}
.mt-24{margin-top: 24px;}
.text-linkblue{color: #397ef6;}
.option-flex li{font-size: 18px;}
.mb-12{margin-bottom: 12px;}
.line-hnormal{line-height: normal;}
.login-filled-btn{background: linear-gradient( 89.35deg, #6613ba 19.31%, #2c00aa 51.88%) !important;font-size: 16px;font-weight: 600;color: #fff;height: 46px;width: fit-content;border-radius: 42px; padding: 0 26px;text-transform: uppercase;display: flex; align-items: center; gap: 30px;border: transparent;margin: 0 auto;}
.head-login-btn{background: transparent;color: #fff;border: 1px solid #6613ba;border-radius: 20px;cursor: pointer; line-height: normal; padding: 8px 22px;}
.head-login-btn:hover,.head-login-btn:active,.head-login-btn:focus{background-image: linear-gradient(84.34deg, #6613ba 41.55%, #2d00aa 96.49%, #2d00aa 96.49%, #2c00aa 96.49%);}
/* welcome screen end  */
/* mail login start  */
.xar-container{background-color: #1d1838 !important;}
#xar-modal{ backdrop-filter: blur(3px);}
.xar-header-heading{color: #fff;}
.xar-email-login__input{ background-color: transparent !important; border: 1px solid #6269a3 !important; border-radius: 8px; font-size: 18px; line-height: 50px;  text-align: left;}
.xar-btn{ min-height: 3rem !important; border-radius: 0.5rem !important; padding: 0px 2rem !important; width: 100% !important; border: 0px !important; color: rgb(255, 255, 255) !important; background-color: rgb(20, 97, 219) !important;}
.xar-header-subtext{color: #fff;}
.markets-input-search .align-center.market-flex{align-items: start;}
.markets-input-search .coin-search.dt-topspace{margin-top: 0 !important;}
.xar-otp-input{border: 1px solid #6269a3 !important;}
.leftcard.crypto-wallet .ant-empty.ant-empty-normal{ margin: 2px auto;}
.ant-btn.disconnect-btn{border: 1px solid var(--bgSelect); border-radius: 42px; padding: 10px; width: 100%; background: transparent; height: auto; line-height: normal;color: #fff;}
.ant-btn.disconnect-btn:hover, .disconnect-btn:hover{ background: linear-gradient(89.35deg, #6613ba 31.31%, #2c00aa 94.88%);}
.btn-transparent{background: transparent;border: 0;padding: 0; color: var(--textPrimary) !important;}
.w-fit-auto{justify-content: center;}
.current-network{color: #397ef6;font-size: 14px;font-weight: 500;}
.change-network .ant-select-selector{height: auto !important;width: 118px !important;}
.switch-network{display: flex;align-items: center;gap: 10px;justify-content: center;margin: 6px 0 8px 0;}
.switch-network .ant-select-selector .ant-select-selection-placeholder{line-height: normal !important;}
.switch-network .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{height: auto !important; }
.switch-network .ant-select-arrow{margin-right: 0;}
.switch-network .ant-select-selection-item{color: #fff;}
.switch-btn{background-image: linear-gradient(to right, #2c00aa 0%, #6613ba 50%, #2c00aa 100%) !important;  color: var(--textWhite);font-size: 14px;border: none;border-radius: 24px;}
.network-loader .ant-spin-spinning{transform: scale(0.6);height: 40px;}
/* mail login end  */
.h-full{height: 100% !important;}
.transparent-btn,.ant-btn.transparent-btn{background: transparent;border: 0;}
.ant-picker-suffix{cursor: pointer;pointer-events: all;}
.dashboard-breadcumb .app-breadcrumb span:not(:last-child) .ant-breadcrumb-link a:hover{color: #fff !important;cursor: default !important;}
.ant-menu-submenu-popup > .ant-menu{background: #040015;border-radius: 20px;    border: 1px solid #204483;}
.ant-menu-submenu-popup > .ant-menu .ant-menu-item-selected{background-color: #2c2653;}
.anticon.anticon-ellipsis{color: #fff;font-size: 26px;}
.right-menu{display: flex;justify-content: end;gap: 16px;}
.dashboard-breadcumb .ant-breadcrumb-link a{cursor: default !important;}
.xar-header-logo__container{display: block !important;}
.ant-notification-notice-close{color: #fff !important;}
.gap-4{gap: 4px;}
.ml-auto{margin-left: auto !important;}
/* .summary-list-item:last-of-type{border-bottom: 0;} */
.icon.Edit-links{background-position: -275px -146px !important; height: 24px; width: 24px;}
.recentpayments-table tr{background: #131024;}
.recentpayments-table td,.alltransactions-table td{background: #131024; padding: 10px;align-content: start;}
.recentpayments-table,.alltransactions-table{border-collapse: separate;border-spacing:0 10px;}
.recentpayments-table td:first-of-type,.alltransactions-table td:first-of-type{border-top-left-radius: 10px;border-bottom-left-radius: 10px;}
.recentpayments-table td:last-of-type,.alltransactions-table td:last-of-type{border-top-right-radius: 10px;border-bottom-right-radius: 10px;}
.paymentlinks-checkbox{border-spacing: 0 !important;border-collapse: initial;}
.ant-select-disabled,input.input-bordered:disabled, .ant-picker.ant-picker-disabled, textarea.input-bordered:disabled{cursor: not-allowed;color: #ffffff;background: transparent;}
.ant-picker-input > input[disabled]{color: #ffffff;}
/* .payee-field:has(.ant-select-disabled) .payee-token::after, .payee-field:has(.ant-input-disabled) .payee-token::after, .payee-field:has(input:disabled) .payee-token::after{ content: "";  width: 100%; height: 13px; background: #434343;  bottom: -3px; left: 0; position: absolute; z-index: -1;} */
.ant-picker.ant-picker-disabled .ant-picker-suffix {color: rgb(255 255 255);}
.payee-field {margin-bottom: 36px;padding-right: 8px;}
.payee-coinselect {background-color: #131024;padding: 0 6px;}
/* .payees-input:has(input.input-bordered:disabled) .ant-form-item-label{line-height: normal;} */
/* .ant-form-item:has(input.input-bordered:disabled) .ant-form-item-label::after { content: "";  width: 100%; height: 11px; background: #434343;  bottom: -1px; left: 0; position: absolute; z-index: -1;} */
.amount-label .ant-form-item-label .ant-form-item-no-colon{height: auto;} 
.amount-label .ant-form-item-label{top: -12px;}    
.payees-input:has(input.input-bordered:disabled) .payees-input{cursor: not-allowed;}
/* .lineheight-normal .ant-form-item-label{line-height: normal !important;} */
/* .ant-form-item-label > label{display: block !important;} */
.ant-form-item-label > label.ant-form-item-no-colon::after{content: none;}
.payee-token .payee-label span{margin-right: 4px;}
.orderid-input.payees-input .ant-form-item-label{top: -12px;}
.orderid-input.ant-form-item:has(input.input-bordered:disabled) .ant-form-item-label::after{bottom: -1px;}

 /* send payments start  */
.ant-modal-mask{display: none;}
.ant-modal-body .ant-input-affix-wrapper,.address-search .ant-input-affix-wrapper{background-color: #040015;border-radius: 26px !important;}
.add-remove .add-coin{display: none;}
.add-remove .merchants-section{height: 100%;}
.add-remove .ant-collapse{height: auto;}
.cust-cards {width: 465px !important;    margin: 0px auto;    margin-bottom: 15px;  }
.earn-card {border: 1px solid var(--borderPanel);    border-radius: 12px;    min-height: 98px;    padding: 16px 32px;    width: 258px;}
.flip-icon {position: relative;    width: 11px;}
.icon.xlg.flip {background-position: -69.5px -683.5px;    height: 64px;    width: 47px;}
.flip-icon .icon.xlg.flip { position: absolute;    right: -18px;    top: 20px;    -webkit-transform: translateY(-15px);    transform: translateY(-15px);}
.exchange-rate {margin: 15px 0;}
.earn-card h2 {color: var(--textPrimary) !important;    font-size: 24px !important;    font-weight: 500;    margin-bottom: 0;}
.input-label {color: var(--textSecondary);	font-size: 14px;	font-weight: 400;	line-height: normal;}
.buy-switchtext {color: var(--textPrimary);    font-size: 14px;    font-weight: 400;    margin-bottom: 8px !important;    text-align: center;display: flex;    justify-content: center;}
.summary-list-item.summaryList-total {    background: #282344;    border: 1px solid var(--borderPanel);    border-radius: 12px;    padding: 16px;}
.verify-btn {    color: #fff;    font-size: 14px !important;}
.grid-scroll{overflow: auto;}
 /* send payments end  */
 .section-border{ border: 1px solid #204483;  box-shadow: 0 10px 20px rgba(57, 126, 246, .15);}
 .batch-payment-tableview.batch-payment-table .ant-table-tbody > tr > td {color: rgba(255, 255, 255, 0.50);}
 .delete-modal .ant-modal-title{color: #fff;font-size: 22px;}
 .modal-footer button:first-child{background-color: transparent;border: 1px solid #204483;border-radius: 24px;color: #fff;height: 40px;min-width: 100px;}
 .modal-footer button:last-child{background: linear-gradient(89.35deg, #6613BA 19.31%, #2C00AA 51.88%);border: transparent;border-radius: 24px;color: #fff;height: 40px;min-width: 100px;}
 .btn-plane,.btn-plane:hover,.btn-plane:active{background-color: transparent;border: transparent;}
 .ant-table-tbody > tr.ant-table-placeholder:hover > td {    background: transparent;}
 .quickpay-carsl .text-hover{white-space: nowrap;}
 .quickpay-carsl{scrollbar-width: none;}
 .payment-filter{border: none !important;}
 .payment-filter .ant-select-selection-item{font-size: 22px;font-weight: 500;}
 .payment-filter .ant-select-arrow{font-size: 18px;top: 50%;}
 .payment-filter .ant-select-selector{padding: 0 !important;}
 .edit-icon{background-position: -231px -18px;}
 .ant-picker-input .ant-picker-clear,.ant-picker-range .ant-picker-clear,.ant-picker-range .ant-picker-clear:hover,.ant-picker-input .ant-picker-clear:hover,.ant-select .ant-select-clear, .ant-select .ant-select-clear:hover{background: black; color:white}
 .amount-box{border: 1px solid #204483;padding: 20px;border-radius: 20px;color: #fff;}
 .amount-box div:not(:last-of-type){margin-bottom: 12px;}
 .amount-box p{font-size: 14px;font-weight: 400;color: #fff;}
 .amount-box div:last-of-type p {font-size: 20px;font-weight: 600;color: #fff;}
 .payment-terms{color: #fff;}
 .payment-terms span{font-size: 14px;font-weight: 500; color: #fff;}
 .box-date{border: 1px solid #444444;border-radius: 10px;margin-left: 20px;min-width: 160px;color: #fff;overflow: hidden;}
 .box-date p:first-child{border-bottom: 1px solid #444444;padding: 12px;}
 .box-date p:last-child{padding: 12px;background-color: #00040C;}
 .batchpayment-textlarge.invoice-text{color: #397EF6 !important;font-size: 55px ;text-transform: uppercase;text-align: right;font-weight: 600;}
 .date-labels p{color: #fff;font-size: 16px;margin-top: 14px;}
 .artha-section p:first-child{font-size: 24px;font-weight: 600;color: #fff;}
 .artha-section p{margin: 5px 0;}
 .invoice-table .ant-table-thead tr:last-child{display: none;}
 .invoice-table .ant-table-thead th{border-bottom: 1px solid #393939 !important;}
 .box-date .edit,.box-date .edit-blue{float: right;scale: 1;cursor: pointer;}
.payment-icons{margin-top: 6px;display: flex;gap: 16px;}
.payment-icons span{cursor: pointer;}
.custom-radiobtn{border-radius: 8px;border: 1px solid #204483;background: #040015;box-shadow: 0px 10px 20px 0px rgba(57, 126, 246, 0.15);}
.custom-radiobtn .ant-radio-inner::after{background: #ffffff;}
.custom-radiobtn .ant-radio-inner{border: 1px solid #ffffff;background: #04001500;}
.custom-radiobtn span{color: #ffffff;line-height: normal;}
.custom-radiobtn .ant-radio-wrapper span:not(.ant-radio, .ant-radio-inner){margin-top: 6px;}
.custom-radiobtn .ant-radio-wrapper:nth-child(1){background: #040015;border-radius: 8px 0px 0px 8px;padding: 16px 36px;}
.custom-radiobtn .ant-radio-wrapper{background: #040015;border-radius: 0px 8px 8px 0px;padding: 16px 36px;}
.custom-radiobtn .ant-radio-wrapper:nth-child(1).ant-radio-wrapper-checked{border-radius: 8px 0px 0px 8px;background: linear-gradient(89deg, #6613BA 31.31%, #2C00AA 94.88%);padding: 16px 36px;}
.custom-radiobtn .ant-radio-wrapper:nth-child(2).ant-radio-wrapper-checked{border-radius: 0px 8px 8px 0px;background: linear-gradient(89deg, #6613BA 31.31%, #2C00AA 94.88%);padding: 16px 36px;margin-right: 0;}
.form-field-bg{background-color: #131024;border-radius: 8px;padding: 30px;}
.ant-picker{font-size: 16px;line-height: 50px;border: 1px solid #6269A3 !important;background-color: transparent;border-radius: 8px !important;height: 54px;width: 100%;color: #fff;padding-right: 18px;}
.ant-checkbox-checked::after, .ant-checkbox-checked .ant-checkbox-inner::after{display:none;}
.ant-checkbox-inner{border: 0 !important;transition: none;}
.ant-form-item-control-input-content:has(> .checkbox-text){display: flex;align-items: center;}
h1.form-title-input{font-size: 18px;font-weight: 500;color: #ffffff;}
.custom-radiobtn .policy-content .ant-checkbox-inner{border: 0;}
.invoice-currency p{font-size: 16px;font-weight: 500;color: rgba(255, 255, 255, 0.55)	;}
.invoice-currency p span{color: #fff;display: inline-block;word-break: break-word;}
.share-text{font-size: 14px;font-weight: 400;color: rgba(255, 255, 255, 0.55);}
.text-medium{font-size: 14px;font-weight: 500;}
.network-text{font-size: 16px;font-weight: 600;}
.smbtn-minw{min-width: 170px;}
.k-icon.k-i-more-vertical {width: 0.5em;}
.status-modal .payees-input .ant-select-selector{align-items: center; padding-left: 16px;}
.ant-modal-title{font-size: 24px;color: #fff;font-weight: 500;padding-right: 34px;}
.form-title-input.text-white{margin: 34px 0 16px 0;}
.close-btn,.close-btn:hover,.close-btn:active,.close-btn:focus{min-width: 160px; border: 1px solid #4096FF;padding: 12px;color: #fff;background-color: transparent;height: 46px;border-radius: 36px;}
.share-adrs .ant-menu-item-selected{background-color: transparent !important;}
table{width: 100% !important;}
.k-icon.k-i-more-vertical {width: 0.5em;}
.ant-table-cell .ant-row{margin-bottom: 0;}
.ant-picker-separator{display: inline-block;background: var(--darkThemeIcons);vertical-align: middle;background-position: -293px -192px;    width: 46px;    scale: 0.8;}
.anticon-swap-right{display: none;}
.select-payee .rc-virtual-list-holder{overflow-x: hidden;max-height: 400;height: 200px !important;}
/* tinymce start  */
.tox .tox-toolbar-overlord,.tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary,.tox:not(.tox-tinymce-inline) .tox-editor-header,.tox .tox-edit-area__iframe,.tox .tox-statusbar{background-color: #131024 !important;}
.tox-tinymce{border: 2px solid #6269a3 !important; }
.tox .tox-edit-area::before {
    border: 2px solid #6269a3 !important;}
	.tox .tox-tbtn--disabled, .tox .tox-tbtn--disabled:hover, .tox .tox-tbtn:disabled, .tox .tox-tbtn:disabled:hover,.tox .tox-tbtn{background:rgb(38 40 61) !important;}
	.tox .tox-tbtn--disabled svg, .tox .tox-tbtn--disabled:hover svg, .tox .tox-tbtn:disabled svg, .tox .tox-tbtn:disabled:hover svg,.tox .tox-tbtn svg,.tox .tox-statusbar__branding svg{fill: #fff !important;}
	.tox .tox-tbtn,.tox .tox-statusbar,.tox .tox-statusbar a, .tox .tox-statusbar__path-item, .tox .tox-statusbar__wordcount{color: #fff !important;}
	.tox .tox-statusbar{border-top: 1px solid #6269a3 !important;}
/* tinymce end  */
input[type='checkbox']:disabled + .custom-checkbox {cursor: not-allowed !important;}
.ant-upload{width: 100%;margin-bottom: 16px;}
.ant-upload .ant-btn{padding: 30px 16px;background-color: transparent;border: 1px dashed #6269a3;color: #fff;border-radius: 8px;display: inline-block;height: auto;width: 100%;}
.ant-upload-list .ant-upload-list-item-info{margin-bottom: 24px;padding: 12px;background-color: #3d4757 !important;min-height: 38px;border-radius: 8px;}
.ant-upload-list-item-name,.anticon-paper-clip,.anticon-delete{color: #397ef6 !important;}
.ant-upload-list-item-name{cursor: pointer;}
.ant-upload-list-item{margin-bottom: 24px;}
.cust-steps-design .ant-steps-item-content .ant-steps-item-title{color: #fff !important;white-space: normal;font-size: 12px;line-height: normal;}
.cust-steps-design .ant-steps-item-content .ant-steps-item-description,.steps-content .recive-runtext{color: #adadad  !important;margin-bottom: 8px;}
.cust-steps-design .ant-steps-item-content .recive-copy .fait-subtext.ant-typography,.recive-copy .fait-subtext.ant-typography{color: #397ef6 !important;}
.cust-steps-design .ant-steps-item-finish .ant-steps-icon{color: #fff !important;}
.cust-steps-design .ant-steps-item-icon{background: linear-gradient(89.35deg,  #6613BA 19.31%,   #2C00AA 51.88%) !important;border-color: transparent;}
.cust-steps-design .ant-steps-item:nth-last-of-type(2) .ant-steps-item-description{margin-bottom: 20px;}
.cust-steps-design .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after { background-color: #ae60fd;}
.cust-steps-design .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after{background-color: #6613ba;}
.pr-16{padding-right: 16px;}
.ant-steps-item-icon, .ant-steps-item-content{vertical-align: middle;}
.steps-action{text-align: right;}
/* .steps-action button:first-child{background: linear-gradient(89.35deg,  #6613BA 19.31%,   #2C00AA 51.88%) !important;border-color: transparent;border-radius: 24px;  min-width: 100px;  min-height: 40px;text-transform: uppercase;}
.steps-action button:last-child,.steps-action button:last-child:active{background: transparent;border-color: #6613BA !important;color: #fff;border-radius: 24px;  min-width: 100px;  min-height: 40px;text-transform: uppercase;} */
.ant-steps-item-title::after{top: 4px;}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {  color: rgb(255 255 255 / 35%);}
.address-bold{margin-bottom: 8px;  display: inline-block;}
.cust-valid-field .ant-input,.cust-valid-field .ant-input-group-addon {  background-color: transparent;  border: 0;  color: #fff;}
.iban-input input {    font-size: 18px;    height: 52px;}
.ant-input-group-addon .ant-btn.validate {    background: #2c2653;    border: transparent;    border-left: 1px solid #2c2653;    border-radius: 0 8px 8px 0;    height: 52px;    min-width: 130px;}
.ant-input-group-addon {    padding: 0;}
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover, .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover, .ant-form-item-has-error :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper, .ant-form-item-has-error :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
    background-color: transparent !important;    color: #fff;}
.payee-input.cust-valid-field button:disabled {color: #ccc;}
.payee-input.cust-valid-field input:focus {    border: transparent;    box-shadow: none !important;}
.addpayee-summary .summary-list {    border-bottom: 1px solid #2c2841;    border-radius: 0;}
.addpayee-summary .summary-list:last-child {    border-bottom: 0}
.word-wrap{word-break: break-all;}
.close-success{position: absolute;top: 24px;right: 24px;z-index: 1;}
.table-summary-fs+.ant-row .payee-input{line-height: normal;word-break: break-all;}
/* .pay-inform .payee-field .ant-col.ant-form-item-control .ant-form-item-explain{bottom: -28px;} */
.icon.xxl.vaults{background-position:-570px -620px;transform: none;}
.infinityScrollEndMsg{color:#fff;display: flex; justify-content: center;}
.ant-select.input-bordered{padding-right: 0px !important;}
/* .payee-rightpanel .d-lg-flex.h-auto + div .ant-form-item-control-input-content input{padding: ;} */
.align-startimp .ant-form-item-control-input-content{align-items: start !important;}
.align-startimp .ant-checkbox-wrapper{margin-top: -2px;}
.graph-minh{min-height: 177px;align-content: center;}
.home-payin-select .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{height: 30px !important;}
.home-payin-select .ant-select-arrow{top: 41%;}
.home-payin-select .input-bordered{height: auto;}
.transactions-header{margin-bottom: 16px;}
.home-payin-select{margin-top: -4px;}
.home-payin-select .payment-filter{ 
	/* border-bottom: 1px solid #373c4b !important; */
	 border-radius: 0 !important; padding-bottom: 6px;}
	 .mt-align{margin-top: -4px;margin-bottom: 4px;}
	 .k-i-more-vertical::before{visibility: hidden;}
	 .k-icon.k-i-more-vertical{width: 18px;height: 18px;background-position:-573px -125px;}
.transactions-header .input-bordered{line-height: normal;height: 42px !important;}
.transactions-header .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{ height: 42px !important;}
.transactions-header .ant-picker{ height: 42px; line-height: normal;}
.transactions-header .coin-search-input .ant-input-affix-wrapper-borderless {  padding: 8px 20px;}
.invoice-value{font-size: 18px;font-weight: 600;color: #fff;}
.mt-10{margin-top: 10px;}
.terms-label{color: #a8a8a8 !important; font-size: 16px !important; font-weight: 400 !important;}
.ant-btn.delete-btn,.ant-btn.delete-btn:hover,.ant-btn.delete-btn:focus{background: transparent;border: none;}
.fiat-select .ant-select-selection-placeholder{align-content: center;text-align: left;}
.fiat-select.auth0-cust-form .cust-input{width: 100%;}
.fiat-select .ant-select-selection-item{text-align: left;align-content: center;}
.network-label{ color: #a8a8a8; font-size: 14px !important; font-weight: 400 !important; position: absolute; top: -11PX; left: 10PX;background: #131024;z-index: 1;padding: 0 4px;}
.currency-price{position: absolute;top: 12px;right: 10%;}
.currency-price p{font-size: 18px;font-weight: 500;color: #fff;}
.currency-price .loader-antd{ margin-top: -21px;margin-left: -16px;}
.ql-toolbar.ql-snow{ border: 1px solid #6269a3 !important;box-sizing: unset !important;border-top-left-radius: 8px;border-top-right-radius: 8px;border-bottom: none !important;background: #2c2653 !important;}
.ql-container.ql-snow{ border-color: #6269a3 !important;box-sizing: unset !important;border-bottom-left-radius: 8px;border-bottom-right-radius: 8px;color: #fff;min-height: 150px;}
.ql-snow .ql-picker{color: #fff !important;}
.ql-snow .ql-stroke{stroke: #fff !important;}
.ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill{fill: #fff !important;}
.ant-form-item-has-error .ql-toolbar.ql-snow,.ant-form-item-has-error .ql-container.ql-snow,.ql-container.ql-snow.note-editor-error-border,.ql-toolbar.ql-snow.note-editor-error-border{border-color: #ff5747 !important;}
.ant-form-item-has-error .ql-toolbar.ql-snow,.ql-toolbar.ql-snow.note-editor-error-border{border-bottom-width: 0 !important;}
.ql-snow .ql-picker-options { background-color: #1f1d2c !important;border: none !important;border-radius: 8px;}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label{border: transparent !important;}
.payout-accounts.merchants-section .ant-collapse{height: auto;max-height: 669px;}
.justify-between{justify-content: space-between;} 
.flex1{flex: 1;}
.payment-terms{border: 1px solid #6269A3;padding: 20px;position: relative;border-radius: 10px;color: #fff;}
.payment-terms span{font-size: 14px;font-weight: 500; color: #fff;position: absolute;    top: -12px;    background: #131024;}
.share-payment-link{width: 90px;margin: 0 !important;background: #040015; border: 1px solid #204483; box-shadow: 0px 10px 20px rgba(57, 126, 246, 0.15); border-radius: 20px;}
.share-adrs{width: 100%;text-align: center;}
.share-payments{background: transparent !important;} 
.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active{background-color: #242038 !important; color: #fff; cursor: not-allowed; border: 1px solid #204483;}
.payout-finalamount {display: flex;}
.payout-finalamount .ant-row,.payout-finalamount .payee-field {flex: 1;}
.payout-finalamount .ant-row:first-child .input-bordered {border-radius: 8px 0px 0px 8px !important;}
.payout-finalamount .payee-field .input-bordered.final-amount {border-radius: 0 8px 8px 0 !important;}
.final-amount .loader-antd{text-align:right !important}
.final-amount .on-loading-text{color:var(--placeholderText);position: absolute; top:2px; left:20px;}
.final-amount-loader svg{fill:#fff !important}
.show-counter{display: inline-block; margin: 0px 5px 0px 5px;}
/* cases carousal start  */
.cases-slider.slick-slider{margin-bottom: 24px;background-color: #131024;padding: 16px;border-radius: 16px;}
.see-details{color: #397EF6; font-size: 16px; cursor: pointer;flex-shrink: 0 ;margin-left: 24px;}
.carousal-title{color: #f9c049;font-size: 18px;font-weight: 600;}
.carousal-content{color: #e0ae57;font-size: 16px;}
.slick-dots .slick-active button{background: #397EF6 !important;}
.icon-close{background-position: -814px -137px;width: 26px;height: 26px;}
.cases-carousal{border: 1px solid #5a4416;padding: 16px; border-radius: 16px;}
.ant-form-item .ant-input-textarea-show-count:after{color:#fff !important}
.ql-editor.ql-blank::before{color:#bfbfbf !important}
@media (min-width: 768px){
	.payee-rightpanel .d-lg-flex.min-height + div{height: 660px !important;}
	.payee-rightpanel .d-lg-flex.h-auto + div{height: auto !important;}
	.linegraph-scroll{height: 180px;overflow: auto;}
	.bank-name {margin: auto;width: 460px;}
	.wd-entry {width: 460px;}
	.addpayee-summary .summary-list { align-items: center; display: flex; justify-content: space-between; }
	.chart div[data-highcharts-chart]{height: auto;}
	.db-quicklinks,.min-h-200{min-height: 212px;}
	.pannel-bg.custom-table-form-summary{width: 500px;margin-left: auto;}
	.summary-list-item.summaryList-total {margin: auto auto 10px;width: 465px; }
	.cust-cards {margin: 0 auto 15px; width: 465px !important; display: flex;   }
	/* payment gateway start  */
	.merchant-infoicon{margin-top: 2px;}
	.tranfer-btn.ant-btn.btn-style{margin-top: 8px;}
	.md-justify{justify-content: space-between;}
	.carosel-width{width: 80% !important;}
	.slide-btn-right{position: absolute;right: 0;top: 25%;}
	.d-md-flex{display: flex;}
	.add-merchant .ant-modal{width: 600px !important;}
	.delete-modal.add-merchant .ant-modal{width: 520px !important;}
	.payment-filters .payee-field,.payment-filters .ant-picker{min-width: 224px;}
	/* payment gateway end  */
	.coinbal-tabs{gap: 6px;}
	.coin-itemslist-scroll {
		height: fit-content;max-height: 920px;}
	.email-verify{height: 54px;}
	.super-menu {left: 69.406px !important;}
	.app-loader{ padding: "300px"}
	.register-blockwid { padding: 54px 64px;  width: 600px; }
	.trans-card{width: 700px;margin: auto;}
	.transfer-form,.form-title{width: 600px;margin: auto;}
	.comingsoon-section{height: 80vh;background-image: url("https://devdottstoragespace.blob.core.windows.net/neoimages/neo-coming-soon.png");background-position: center;background-size: contain;background-repeat: no-repeat;
		display: flex;align-items: center;justify-content: center;}
	.modal-footer-content{max-width: 465px;margin: 0 auto;}
	.buy-token .buy-from{text-transform: uppercase;font-weight: 600;}
	.main-menu .list-item.active::after, .main-menu .list-item:hover::after{content: '';position: absolute;bottom: -10px; height: 3px;left: 0;right: 0;
		background: var(--bgWhite) !important;border-radius: 3px 3px 0 0;}
	.main-menu .list-item.active::after, .main-menu .list-item:hover::after{content: '';position: absolute;bottom: -10px; height: 3px;left: 0;right: 0;background: #397EF6 !important;border-radius: 3px 3px 0 0;}
	.disable-pop{ width: 723px;left: 194px;top: 34px;}
	.buy-token{width: 465px;}
	.mobile-drop .ant-modal{display: none;}
	.summary-width{width: 465px;margin: 0 auto;}
	.howit-works button{margin-top: 30px;}
	.transaction-pop .ant-modal-content{width: 1127px;left: -325px !important;height: 100vh !important;top: -24px;}
	.cust-height{min-height: 558px;}
	.mobile-dt-header{margin-left: 50px;}
	.left-items-stretch {margin: 0 15px 15px;}
	.market-search.grid-search .search-box:hover > .search-text { width: 165px !important;padding-right: 24px;}
	.howit-works{min-height: 378px;}
	.crypto-balancepanel{min-height: 160px;}
	/* .panel-card.buy-card{width: 696px;} */
	.cryptowallets-panel .coin-container{min-height: 509px;overflow-y: auto;max-height: 547px;}
	.icon-circle{width: 65px;height: 65px;margin: 0 auto;}
	.icon.xxl.cart{background-position: -8px -616px;transform: none;}
	.icon.xxl.uparrow{background-position:-95px -617px;transform: none;}
	.icon.xxl.downarrow{background-position:-266px -618px;transform: none;}
	.icon.xxl.wallet{background-position:-181px -617px;transform: none;}
	.icon.xxl.add-wallet{background-position:-336px -622px;transform: none;}	
	.customicon:hover .icon.xxl.vaults{background-position:-570px -560px;transform: none;}
	.customicon:hover .icon.xxl.cart{background-position: -8px -550px;transform: none;}
	.customicon:hover .icon.xxl.downarrow{background-position:-266px -551px;transform: none;}
	.customicon:hover .icon.xxl.uparrow{background-position:-95px -550px;transform: none;}
	.customicon:hover .icon.xxl.wallet{background-position:-181px -552px;transform: none;}
	.customicon:hover .icon.xxl.add-wallet{background-position:-336px -558px;transform: none;}
	.cust-xxl.crypto.coin.sm.gbp {background-position: -501.3px -533px;transform:scale(1.8);}
	.cust-xxl.crypto.coin.sm.chf{background-position: -174.3px -571px;transform:scale(1.8);}
	.panel-card{margin: 0 auto;}
	.custom-steps{margin: -58px 0 20px 0;padding: 0; background-color: transparent;}
	.summary-lbl {font-size: 14px;}
	.summary-val {font-size: 16px;}
	.summary-list-item{display: flex; align-items: center;justify-content: space-between;gap: 20px;}
	.summary{margin: 24px auto 36px;}
	.history-data:not(:last-child){margin-bottom: 0;}
	.orderhistory .order-item{display: flex;}
	.custom-table .ant-table{background: transparent;overflow-x: auto;}
	.custom-table .ant-table-tbody > tr > td{color: var(--textPrimary);}
	.btn-style.ant-btn{font-size: 30px;width: 465px;}
	.comingsoon-section .btn-style{max-width: 233px;font-size: 16px !important;margin-top: 150px;height: 46px !important;padding: 0 24px;}
	/* .createac-btn{width: 280px;} */
	.deposit-content.coin-container{background: #131024; width: 580px;margin: 50px auto;border-radius: 20px;}
	.deposit-content{width: 100% !important;}
	.custom-modal .ant-modal-content{padding: 36px 36px;}
	.custom-modal .ant-modal-header{padding: 0px 0px;}
	.custom-modal .coin-item:hover{background: #2C2653;}
	.custom-modal .coin-item:hover,.custom-modal .coin-item.active{background: #2C2653;}
	.custom-modal .ant-modal-centered .ant-modal{	top: 0;	display: inline-block;	padding-bottom: 0;text-align: left;vertical-align: middle;}
	.ant-modal-centered {text-align: right;  /* width: 1150px;  */margin: auto;	}
	.ant-modal-content {height: auto !important;}
	.buy-from{font-size: 16px !important;}
	.deposit-rightpanel .ref-code{margin: 22px auto;}
	.ref-code{width: 465px;margin: 30px auto;background: #2C2841	; border-radius: 5px;height: 45px;position: relative;}
	.address-content{font-weight: 700;font-size: 16px;color: #F1F1F1;margin: 0 !important;}
	.copy-btn .ant-typography-copy{color: #397EF6;}
	.search-width{width: 346px;}
	.thankyou-main{background-color: #131024; border-radius: 20px; backdrop-filter: blur(10px); padding: 60px;background-image: url(../images/Group.png)!important; background-position: -3px 7px; background-repeat: no-repeat;}
	.filsection{position: relative;left: 120px;}
	.deposit-content .network-drpdwn{width: 465px;}
	.deposit-content .network-drpdwn.wd{width: 100%;}
	.deposit-networkfeild{width: 465px;margin: 20px auto;}
	.deposit-networkfeild .network-drpdwn{margin-top:0px !important;}
	.createac-content{width:460px;margin: 0 auto;}
	.account-wallet-head{margin-top: -10px;}
	.custodian-dblist .left-items-stretch{width: 445px;margin: 0 auto;}
	.mb-show{display: none;}
	.buy-slider.ant-slider { width: 464px; margin: 0 auto 28px;}
	.btn-block{display: flex;}
	.qr-image {height: 250px !important;width: 250px !important;}
	.static-invoicecode .qr-image {height: 250px !important;width: 250px !important;}
	.verify-btn { font-size: 18px !important;}
	.otp-verify .get-code button {height: 100%;}
	.max-content {margin: auto;width: 460px;}
	.payee-label {font-size: 14px !important;line-height: 20px;}
	/* .batch-payment-table .invoice-table .ant-table-container{border: 1px solid rgba(255, 255, 255, 0.20); width: fit-content;} */


}
@media (min-width: 992px){
	.quickpay-carsl{gap: 8px;}
	.customicon:hover h5, h5.text-hover{font-size: 14px;}
	.icon-circle { transform: scale(0.8);}
	.payee-inblock, .summary-panel {width: 465px;}
	.earn-card {width: 224px;}
	.ant-drawer-content-wrapper {width: 520px!important;}
	.account-progress{width: 800px;}
	.market-flex .coin-search input:focus { width: 192px; }
	.market-flex .coin-search input {  width: 100px; }
	.cuat-auth-width {width: 900px !important; padding: 54px 64px;}
	.panel-card.buy-card{min-height: 620px;}
	.main-menu .list-item.active::after, .main-menu .list-item:hover::after{content: '';position: absolute;bottom: -10px; height: 3px;left: 0;right: 0;background: #397EF6 !important;border-radius: 3px 3px 0 0;}
	.ant-modal-wrap.ant-modal-centered{ top: 116px;}
	.ant-modal-mask{background-color: rgb(0 0 0 / 84%);		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;}
	.custom-modal .ant-modal-content {width: 600px;transform: translate(-16px, 140px);}
	.md-none{display: none;}
	.d-lg-flex{display: flex !important;justify-content: space-between; align-items: center;}
	.payees-input{ width: 100%;margin: 0 auto;}
	.sideropen{display: none;}
	.list-item.visible-mobile{display: none !important;}
	.left-panel .coin-container{display: block;}
	.mb-coin-lookup{display: none;}
	.header-right{display: block;flex-grow: 1;flex-shrink: 1;text-align: right;}
	.row-stretch{align-items: stretch;justify-content: space-between;flex-flow: row; display: flex !important;}
	.left-items-stretch{width: 375px;}
	.layout-bg.right-panel.main-db {width: 900px;margin: 36px auto !important;}
	.layout-bg.left-panel, .layout-bg.right-panel{margin-bottom: 16px;}
	.row-stretch-right{align-items: stretch;justify-content: space-between;flex-flow: row; display: flex !important;}
	.db-card.cast-db-card{margin: 8px;width: 100%;}
	.db-card.cast-db-card .btn-style{height: 56px !important;}
	.btn-style.ant-btn.db-btn-style{font-size: 18px;}
.custom-modal .ant-modal-body .selectbank-list .ant-list-items{height:248px;}
.custom-modal .ant-modal-body{width: 458px;/*padding: 0px 96px 0;*/margin: 26px auto;}
	.custom-modal .ant-modal-centered .ant-modal{	top: 0;	display: inline-block;	padding-bottom: 0;text-align: left;vertical-align: middle;}
	.custom-modal {width: 730px !important;}
	.ant-modal-centered {text-align: right;	width: 1220px;margin: auto;	}
	/* .ant-modal-mask{display: none;} */
	.btn-style.asset-btn{margin-top: 30px;}
	.panel-card.buy-card.deposit-content{min-height: 630px;}
	.transactions-tablegrid .ant-table .ant-table-body{background: var(--bgCard);width: 100%;}
	.deposit-rightpanel{min-height: auto !important;}
	.wallet-cardlist .ant-list-items{text-align: center;}
    .wd-entry {width: 460px;}
	

}
@media (min-width:1024px){
	/* .summary{width: 580px;} */
	.app-breadcrumb, .summary{padding: 0;}
	.custom-steps .ant-steps-item-title{font-size: 18px;}
	.custom-steps .ant-steps-item-description {font-size: 12px;}
	.custom-steps{padding: 0 65px;margin: -65px 0 20px 0;}
	.custom-modal {width: calc(100% - 571px) !important;margin: 0 12px 0 0;}
	.custom-modal .ant-modal-content{width: 584px;transform: none;height: calc(100vh - 100px) !important;}
	.withdraw-content{width: 465px;margin: 0px auto !important;padding: 30px 0px !important;text-align: center;}
	
	
}
@media (min-width: 1200px){
	.payment-terms{height:100%;}
	.avail-balance.ant-typography.fs36{font-size: 32px;}
	.avail-balance.ant-typography{font-size: 24px;}
	.layout-bg.right-panel.panel-bg { margin: 0 15px 15px 0 !important; width: 400px; }
	.main-container{width: 1150px;margin: auto;}
	/* .createac-content {padding: 36px 79px;} */
	.input-namefeild{margin: 10px 0px 0px;}
	.custom-modal .ant-modal-content{height: calc(100vh - 45px);width: 724px;top: 6px;}
	.ant-modal-wrap > .dep-modal{left:0px !important;}
	.custom-modal {width: calc(100% - 446px) !important;margin: 0 12px 0 0;}
	.custom-modal.dep-modal{width: 768px !important;}
	.custom-modal.dep-modal .ant-modal-content{height: calc(100vh - 100px);}
	.custom-modal .ant-modal-content .ant-modal-body .coin-container .ant-list-items{height: 132px;}
	.custom-modal.dep-modal .ant-modal-content .ant-modal-body .dt-topspace .ant-list-items{height: 185px;}
	.ant-modal-content{left: -21px !important;}
	.custodian-dashboard .db-card{padding: 40px;}
	
}
@media screen and (min-width: 1024px) and (max-width: 1200px) {
	.custom-modal .ant-modal-content {width: 723px;	}
	.ant-modal-wrap {left: -118px;}
	.custom-modal {width: calc(100% - 447px) !important;}
}
@media (max-width: 1400px){
    
	.person-name{white-space: nowrap; overflow: hidden;text-overflow: ellipsis;width: 237px;}
	.middle-content {text-align: left;width: 180px;}
}

@media (min-width: 1400px){
	.quickpay-carsl{gap:16px}
	.customicon:hover h5, h5.text-hover{font-size: 16px;}
	.icon-circle { transform: scale(1);}
	.market-flex .coin-search input:focus {  width: 234px; }
	.transaction-pop .ant-modal-content {width: 1290px;left: -388px	 !important;}
	.dashb-btmtable { height: 441px; overflow: auto;}
	.howit-works { min-height: 375px;}
	.btn-style.ant-btn.db-btn-style{font-size: 22px;}
	.ant-modal-centered {text-align: right;	width: 1325px;margin: auto;	}
	.custom-modal {width: calc(100% - 426px) !important;}
	.main-container{width: 1325px;margin: auto;}
	.custom-modal .ant-modal-header{padding: 0px 24px;}
	.custom-modal .coin-item:hover{background: #2C2653;}
	.custom-modal .ant-modal-body{/*padding: 0px 93px 0;*/width: 458px;margin-bottom: 26px;margin-top: 26px;}
.custom-modal .ant-modal-centered .ant-modal{	top: 0;	display: inline-block;	padding-bottom: 0;text-align: left;vertical-align: middle;}
.custom-modal {margin: 0 20px 0 0;}
.ant-modal-wrap > .dep-modal{width: 800px;left: 12px;}
.custom-modal	.ant-modal-centered {text-align: right;	width: 1150px;margin: auto;	}
.custom-modal	.ant-modal-mask{display: none;}
.custom-modal .ant-modal-content .ant-modal-body .coin-container .ant-list-items{height: 220px;}
.custom-modal .ant-modal-content{height: calc(100vw - 60vw) !important;left: -5px !important;width: 902px;}
.exchange-bal .bal-card button{width: 119px;display: flex;justify-content: center;padding: 0px;line-height: 20px;}
.batch-payment-table .invoice-table .ant-table-container table{width: 1600px;}
.batch-payment-table .invoice-table .ant-table-container .ant-table-content{overflow: auto;}
.custodian-dashboard .db-card{padding: 55px;}
.middle-content {text-align: left;width: 300px;}

}
@media (max-width: 1200px){
    /* .ant-modal-content{ left: 61px !important;} */
	.custom-table{overflow: auto;}
	.mobile-padding{ padding: 36px 20px !important;}
	.transactions-tablegrid .ant-table{overflow: auto;}
	.mobile-header .menu-item {display: block;padding-left: 0;}	
	.ant-row.row-stretch{margin-right: 0px !important;margin-left: 0px !important;}
	.ant-row{margin-right: 0px !important;margin-left: 0px !important;}
	.avail-balance.ant-typography{font-size: 32px;}
	.avail-balance.ant-typography.fs36{font-size: 24px;}
}
@media (max-width: 1024px){
	.main-container{margin: 0 8px;}
}
@media (max-width: 991.98px){
	.mobile-header .list-item.active{color: #397ef6;}
	.ant-layout-sider-zero-width.sideropen{ max-width: 200px !important;min-width: 200px !important;width: 200px !important;}
	.custom-flex.view-block{display: block;}
	.d-lg-flex{display: block;}
	.coin-search-input, .coin-search-input .ant-input-group-addon{background: #040015;    border: 1px solid #204483 !important;}
	.withdraw-rightpanel{padding: 30px 16px !important;}
	.transaction-details {padding: 0 30px 24px 0;	}
	.ant-modal {top: 52px;}
	.buy-token { background: var(--bgSelect); border-radius: 25px;min-width: 150px; display: inline-block; margin: 29px auto;}
	.buy-token.md-none .buy-coinselect{justify-content: space-between;}
	.customlogo {width: auto;height: 25px;}
	/* .ant-modal-content {height: 100vh !important;left: 0 !important;} */
	.mobile-drop .ant-modal-footer{display: none;}
	.mobile-drop .ant-modal-close-x{display: none;}
	.sm-none{display: none;}
	.dashboard-portfolio{margin-left: 0 !important;}
	.active-mobile a{color: #397EF6 !important;}
	.custom-header { padding: 8px 10px;}
	.layout-bg.right-panel.dashboard-portfolio{margin-right: 0; margin-left: 0;}
	.transactions-header .grid-transaction .search-btn{background: none;margin-right: 22px;}
	.ant-layout.ant-layout-has-sider {flex-direction: inherit;}
	.sideropen { background: #131024; border-radius: 0; margin-left: -16px; margin-top: 5px; min-height: 100vh; padding: 12px; position: absolute; right: 0;top: 54px;z-index: 1;}
	.mobile-header .menu-item li {padding: 8px;}
	.mobile-dt-header, .head-textfield{display: none;}
	.mobile-dt-header{display: none;}
	.deposit-content .withdraw-content{padding: 20px 40px;}
	.layout-bg.right-panel{margin-left: 10px;margin-right: 10px;}
	.layout-bg.left-panel{margin-left: 10px;margin-right: 10px;flex: 1;}
	.market-flex .coin-search .coin-search-input {background: var(--bgCard);border: 0 !important;}
	.exchange-bal .bal-card button{width: 119px;display: flex;justify-content: center;padding: 0px;line-height: 20px;}
	.wallet-cardlist{text-align: center;}
	.tabview-exchangecard{text-align: center;}
	.card-list.exchange-bal{overflow: auto;width: 100%;}
	.bal-card{overflow: auto;width: 100%;}
	.middle-content{width: 250px;}
	.panel-card.buy-card.withdraw-content{text-align: center;}
	.custodian-dblist{display: block;width: 70%;margin: 16px auto;}
	.buy-toamount{margin: 20px auto;}
	.market-flex .coin-search input:focus {width:268px;}
	.ant-modal-centered {text-align: left; }
	.custom-modal {width: 100% !important; }
	.ant-modal, .ant-modal-wrap.ant-modal-centered {bottom: auto;top: 60px;}
	.ant-modal-content {height: 100vh !important;left: 0 !important; }
}


@media (max-width: 767.98px){
	.btn-plane.buy-coinselect{width: 100%;}
	.custom-table-form-summary .payee-input.p-16{padding-left: 0 !important;}
	.custom-table-form-summary .d-flex{display: block !important;}
	.text-right.mb-24 button{margin-bottom: 16px;}
	.icon.xxl.vaults{background-position:-570px -620px;transform: scale(0.6);}
	.icon.xxl.transactions{transform: scale(0.5);}
	.crypto-view, .fiat-view { padding: 16px !important;    }
	.coinbal-tabs .tabs-button {width: 50%; }
	.coinBal-flex,.coinbal-tabs { flex: 1 1;width: 100%; }
	.form-field-bg.mt-30{padding: 16px;}
	.ant-radio-group .ant-radio-wrapper{padding: 16px !important;}
	.right-panel{margin-top: 16px;}
	.label-card {font-size: 10px;left: 5px;  top: -10px;}
	.summary-list .withdraw-content{padding-left: 0 !important;padding-right: 0 !important;}
	.earn-card {margin-top: 12px;}
	.earn-card,.cust-cards{width: 100% !important;}
	.flip-icon .icon.xlg.flip {right: -167px;top: -27px;-webkit-transform: rotate(89deg); transform: rotate(89deg); }
	.mobile-btnalign .cust-right-border {
        border-bottom-left-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
        border-left: 1px solid #6269a3 !important;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;width: 100%;
    }
	/* payment gateway start  */
	.merchant-infoicon{margin-top: 4px;}
	.side-drawer.ant-drawer-right .ant-drawer-content-wrapper{right: unset;}
	.crypto-img{width: 100%;}
	.add-merchant .ant-modal-content { height: auto !important; }
	.transaction-mobile-actions{width: 100%;}
	.transaction-mobile-actions .export-pdf .btn-style{margin-right: 8px; margin-left: auto;}
	.ant-modal-header .card-title{font-size: 16px !important;}
	.card-title.ordrhstry-title.xs-fs-16,.card-title.ordrhstry-title{margin-bottom: 0;}
	.xs-flex-end{justify-content: end;gap: 8px;}
	.ant-picker-date-range-wrapper{min-width: auto !important;}
	.ant-picker-panels{flex-wrap: wrap !important;}
	.xs-block .ant-col-xs-24 { flex: 0 0 100% !important;}
	.invoice-modal .ant-modal-header {padding: 22px 14px;}
	.xs-fs-16{font-size: 16px !important;}
	.sm-mb-10{margin-bottom: 10px !important;}
	.sm-mt-16{margin-top: 16px;}
	.create-payement-style .ant-row{ flex-direction: column;row-gap: 24px;}
	.create-payement-style{padding: 16px;}
	.border-left{border-left: 0;padding-left: 8px !important;}
	.slide-btn-right{margin-top: 10px;text-align: right;}
	.tranfer-btn.ant-btn.btn-style{margin-right: auto;margin-left: 0;}
	.avail-balance.ant-typography{font-size: 22px;}
	.payment-filters{flex-direction: column;}
	/* payment gateway end  */
	.withdraw-network .ant-form-item .ant-form-item-label > label{height: auto !important;}
	.withdraw-network .auth0-cust-form .ant-form-item-label{top: -11px;padding-bottom: 0;}
	.buy-max span {font-size: 10px;font-weight: 700;}
	.ant-modal-close {top: 26px;right: 28px;}
	/* .custom-modal .ant-modal-content {
		padding: 40px 10px;
	} */
	.coin-itemslist-scroll {
		height: 50vh;
		overflow-y: auto;}
	.payees-rightpanel,.panel-card.buy-card.payees-screen{padding: 24px 16px !important;}
	.buy-from{line-height: normal;font-size: 12px;}
	.cryptowallets-panel{ max-height: 586px;height: fit-content;}
	.dashboard-portfolio{min-height: auto;}
	.coin-flexgrow .ant-list-item-meta {flex: 124px 0;}
	.coin-item .ant-list-item-action {margin-left: 8px !important;}
	.verifications-form .ant-form-item-extra span:nth-child(2){float: right !important;}
	.get-codebtn .icon.md.info{position: absolute;right: 26px;}
	.otp-verify .ant-form-item-extra span{float:right !important;}
	.mobile-btnalign.d-flex{display: block !important;}
	.mobile-btnalign .get-codebtn{border-bottom-right-radius: 8px !important;border-bottom-left-radius: 8px !important;border-top-left-radius: 0 !important;border-top-right-radius: 0px !important;border-left:1px solid #6269a3 !important;width: 100%;height: 54px !important;}
	.mobile-btnalign input{border-bottom-right-radius: 0px !important;border-bottom-left-radius: 0px !important;border-top-left-radius: 8px !important;border-top-right-radius: 8px !important;text-align: center !important;height: 54px !important;}
	.register-blockwid{margin: 0 10px;}
	.auth-phverification .ant-form-item-label{padding-bottom: 0 !important;height: auto !important;}
	.auth-phverification .ant-form-item-label label{font-size: 8px !important;}
	.comingsoon-section .btn-style{margin-top: 110px;}
  .comingsoon-section{background-image: url("https://devdottstoragespace.blob.core.windows.net/neoimages/neo-coming-soon.svg");background-position: top;background-size: 100%;background-repeat: no-repeat;
    display: flex;align-items: center;justify-content: center;height: 80vh;}
	.ant-slider-mark {width: 70%;margin: 0 39px;}
	.transactions-header .coin-search{margin: 0 8px;}
	.sm-w{width: 100%;}
	.sm-w button{flex: 1;}
	.buy-token { width: 100% !important;}
	.summary-list-item .coin-title{text-align: left !important;}
	.summary-list-item .payee-label{text-align: left !important;}
	.summary-list-item .summary-text{text-align: left !important;}
	.sm-mb-20{margin-bottom: 20px;}
	.firstletter-design{flex: 46px 0 0; flex-grow: 0; flex-shrink: 0; flex-basis: 46px;}
	.mobile-drop .ant-modal-body{overflow: unset;}
	.loader-antd,.buy-from, .buy-fromcurrency,.summary-text{margin-left: 0;}
	.panel-card.buy-card{padding: 0 ;}
	.panel-card.buy-card.exchange-bal{padding: 24px 16px !important;}
	.tabs-button{font-size: 16PX;padding: 10px 24px;}
	.coinBal-flex.flex-mb{flex-direction: column;gap: 14px;align-items: start;}
	.menu-item .list-item{margin: 0px 0px;}
	.transaction-details .payee-label{font-size: 16px;}
	.payee-leftdata{gap: 14px;}
	.transactions-header{flex-direction: column;justify-content: start !important;align-items: start !important;}
	.transactions-header .d-flex.align-center{display: block !important;width: 100% !important;}
	.transactions-header .search-box{width: 100% !important;}
	.grid-transaction .search-box:hover .search-btn {background: #071633;}
	.transactions-header .search-box .search-text{width: 100% !important;border: 1px solid #4b4b4b;height: 44px;padding: 8px;margin: 0;}
	.table-grid .custom-table{width: 1000px;}
	.sm-flex{flex: 1;padding-left: 0 !important;padding-right: 0 !important;}
	.quickpay-carsl .customicon{display: flex;align-items: center;flex-direction: column;gap: 8px;}
	.icon.xxl.cart{background-position: -8px -616px;}
	.icon.xxl.uparrow{background-position:-95px -617px;}
	.icon.xxl.downarrow{background-position:-266px -618px;}
	.icon.xxl.wallet{background-position:-181px -617px;transform:scale(0.5) !important;}
	.icon.xxl.add-wallet{background-position:-336px -622px;transform:scale(0.5) !important;}
	.db-quicklinks .icon.sell,.db-quicklinks .icon.xxl.uparrow,.db-quicklinks .icon.xxl.downarrow{transform: scale(0.5) !important;rotate: 145deg;}
	.createac-btns{display: block;}
	.deposit-content .withdraw-content{padding: 20px 16px;}
	.withdraw-rightpanel .btn-style.ant-btn{width: 100%;}
	.exc-bal{width: 600px;}
	.balances-rpanel .ant-spin-container{overflow: auto; }
	.mbl-flex{display: block;padding: 0px !important;}
	.balance-title{width: fit-content;text-align: center;margin: 10px auto;}
	.custom-modal .btn-style{width:335px;}
	.account-wallet-head{display: block;}
	.wallet-cardlist{text-align: center;}
	.layout-bg.right-panel{margin-left: 8px;margin-right: 8px;padding: 16px;}
	.layout-bg.left-panel{margin-left: 8px;margin-right: 8px;}
	.main-container .layout-bg.right-panel{margin-left: 0px;margin-right: 0px;}
	.main-container{margin: 0 8px;}
	.custodian-dashboard .db-card{padding: 30px;}
	.custodian-dblist{display: block;width: 90%;margin: 0px auto;}
	.filsection{margin: 16px 10px;}
	
	.deposit-content .network-drpdwn{width: 90%;}
	.copy-btn{padding: 0;}
	.network-field .ant-form-item-control-input-content .item-card{color: #ffffff; border: 1px solid var(--bgSelect); padding: 5px 0px; border-radius: 8px; cursor: pointer;width: 150px;margin:10px auto;height: 42px;display: flex;text-align: left;}
	.transactions-tablegrid .ant-table{background-color:transparent}
	.sideropen{margin-left: -16px;padding: 12px;margin-top: 5px;border-radius: 0;min-height: 100vh; position: absolute;right: 0;z-index:999;top: 54px;background: var(--bgCard);}
	.custom-header {position: relative;width: 100%;}
	.network-field .ant-form-item-control-input-content{display: block;margin: 0px auto;}
	.item-card label{justify-content: left;}
	.card-list.exchange-bal{overflow: auto;width: 100%;}
	.bal-card{width: 800px;}
	.middle-content{width: 290px;}
	.person-name{white-space: nowrap; overflow: hidden;text-overflow: ellipsis;width: 150px;}
	.deposit-number{margin-top: 0px !important;}
	.buy-token.md-none{width: 100%;}
	.buy-token.md-none .buy-coinselect{margin-left: 0px;margin-right: 0px;}
	.withdraw-rightpanel .withdraw-content{text-align: center;}
	.deposit-content .address .address-content{width: 220px;}
	.deposit-copy{margin: 20px 0px;}
	.panel-card.buy-card.deposit-content form{padding: 20px 10px;}
	.db-hovlist{min-width: 265px;}
	.custodian-dropdown{position: relative;right: -17px;min-width: 340px;}
	.withdraw-mbl {margin: 50px 0px;}
	.balances-rpanel .middle-content{margin-left: 20px;}
	.mb-show{flex: 1;}
	.input-maxbtn{position: absolute;top:65px ;}
	/* .mb-hide{display: none;} */
	.mb-show .buy-token{margin: 0px !important;width: 100% !important;}
	.withdraw-rightpanel .buy-token { margin: 0px auto 30px;}
	.withdraw-rightpanel .deposit-rightpanel .buy-token { margin: 30px auto 30px;}
	.withdraw-rightpanel .btn-text{margin-top: 8px;}
	.btn-block{display: flex;}
	.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {top: -11%;left: 40%;}
.Card-title.tab-view{font-size: 22px !important;}
.withdraw-input.create-acinput.deposit-number.wd-input .input-label .ant-form-item-control-input-content .max-content{flex-wrap: wrap;}
.ant-modal-wrap.ant-modal-centered {top: 52px;}
.ant-modal-content {padding: 22px;left: 0 !important;width: 100%;height: 100vh !important;}
.wd-search .panel-card.buy-card,.sm-p-space{padding: 16px !important;}
.declaration-content .ant-modal-close-x{right: 14px;}
.mobile-space .withdraw-rightpanel{padding-top: 0 !important;padding-bottom: 0 !important;}
.custom-crypto-btns{flex-wrap: wrap;padding: 16px 0;}
.custom-crypto-btns button{padding: 12px 16px !important;margin-bottom: 0;}
.wallet-item .ant-row .ant-col{margin-top: 8px;}
.view-block .export-pdf .btn-style{margin-left: auto;margin-right: 0;}
.view-block .export-pdf{width: 100%;}
.batch-payment-table .ant-table-container{overflow: auto;}
.batch-payment-table .ant-table-cell input{width: 200px;}
}
@media (max-width: 280px){
	.custodian-dashboard .db-card {  padding: 10px;}
	.db-card{margin:10px 0px;}
	.custodian-dashboard h4.Card-title{font-size: 24px !important;}
}

.loader-antd{text-align: center;color: var(--bgWhite);}
/* Media queries end*/

.quickpay-carsl{display: flex;justify-content: space-between;overflow: auto;}
.customicon{display: inline-block;width: 100%;text-align: left;color: #fff;}
.dropdown-list {background: var(--bgLightGray); color: var(--textWhite);list-style: none; margin: 10px 2px;padding: 0;text-align: left;}
.depwith-drpdown .ant-dropdown-menu { background: #242038;border-radius: 8px; max-width: 100px!important;width: 100px!important;}
.dropdown-list li { padding: 4px 16px;}
.dropdown-list li a {color: var(--textPrimary);}
.dropdown-list li:hover {background: #2c2841;}
.download-btn{height: 42px;color: #fff !important;border: transparent;font-size: 18px !important;text-transform: uppercase;margin: 0;}
.estimate-text {
	font-size: 16px !important;
	font-weight: 400 !important;
	padding: 4px 16px;
	margin: 8px 4px;
	display: inline-block;
	border-radius: 8px;
	cursor: pointer;
	background: linear-gradient(
	  89.35deg,
	  #6613ba 19.31%,
	  #2c00aa 51.88%
	) !important;
  }
  .estimate-text.manage-ac{border: none !important;}
.text-link{color: #397ef6 !important;}


/* kendo grid start */
.k-grid {border-color: #204483 !important;background-color: #040015 !important;}
.k-grid-header, .k-header, th.k-header, .k-grid-header-wrap, .k-grouping-header, .k-grouping-header .k-group-indicator, .k-grid td, .k-grid-footer, .k-grid-footer-wrap, .k-grid-content-locked, .k-grid-footer-locked, .k-grid-header-locked, .k-filter-row > td, .k-filter-row > th{border-color:#204483 !important;}
.k-grid-header, .k-grid .k-grid-filter, .k-grid .k-header-column-menu, .k-grid .k-hierarchy-cell .k-icon{color: #99999A !important;}
.k-grouping-header, .k-grid-add-row, .k-grid-footer, .k-grid-content{background-color: #040015 !important;color: #f1f1f1;}
.k-grid-header{background-color: #2C2653 !important;}
.k-grid tr.k-alt, .k-grid tr:hover:not(.k-grid-header table tr:hover), .k-pager-wrap {background-color: #131024 !important;}
.k-dropdown, .k-dropdowntree{background-color: #1d1935 !important;color: #f1f1f1 !important;}
.k-pager-wrap{color: #f1f1f1 !important;}
.k-grid-header .k-grid-filter:hover, .k-grid-header .k-header-column-menu:hover, .k-grid-header .k-hierarchy-cell .k-icon:hover{background-color: transparent !important;}
.k-widget.k-grid, .k-grid-header{border-top-right-radius: 8px !important;border-top-left-radius: 8px !important;}
.k-widget.k-grid, .k-pager-wrap{border-bottom-left-radius: 8px;border-bottom-right-radius: 8px;}
.k-pager-numbers .k-link.k-state-selected, .k-pager-numbers .k-link:hover, .k-pager-numbers .k-link.k-state-hover, .k-popup{color: #f1f1f1 !important;background-color: #1d1935 !important;}
.k-pager-numbers .k-link{color: #f1f1f1 !important;}
.k-list__item.k-state-selected, .k-list .k-state-selected.k-item, .k-state-selected.k-list-optionlabel, .k-list__item:hover, .k-list .k-item:hover, .k-list-optionlabel:hover, .k-list__item.k-state-hover, .k-list .k-state-hover.k-item, .k-state-hover.k-list-optionlabel, .k-pager-nav.k-link:hover, .k-pager-refresh:hover, .k-state-hover.k-pager-nav.k-link, .k-state-hover.k-pager-refresh, .k-columnmenu-item.k-state-selected {color: white !important;background-color: #040015 !important;}
.k-column-list-item:hover, .k-columnmenu-item:hover, .k-textbox,.k-numerictextbox{background-color: #040015 !important;color: #f1f1f1 !important;border-color:#204483 !important;}
.k-textbox{border-color:#204483 !important;}
.k-numerictextbox .k-link:hover, .k-numerictextbox .k-link.k-state-hover,.k-numerictextbox .k-select{background-color: #2c2653 !important;}
.k-numerictextbox .k-link{color: #fff !important;}
.k-dateinput{background-color: #040015 !important;color: #f1f1f1 !important;border-color:#204483 !important;}
.k-datepicker .k-select{background-color: #131024 !important;color: #f1f1f1 !important;}
.k-datepicker:hover, .k-datepicker.k-state-hover, .k-datepicker{border-color:#204483 !important;}
.k-grid-header .k-i-sort-asc-sm, .k-grid-header .k-i-sort-desc-sm, .k-grid-header .k-sort-order{color: #ffffff !important;}
.k-button {border-color: #204483 !important;color: #f1f1f1 !important;background-color: #242038 !important;background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));border-radius: 8px !important;}
.k-button.k-primary{background: linear-gradient(89.35deg, #6613BA 19.31%, #2C00AA 51.88%) !important;}
.custom-checkbox input[type="checkbox"]:checked + span, .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner{ width:26px; height:26px; cursor: pointer; background-position:-13px -304px !important;}
.custom-checkbox input[type=checkbox]+span , .ant-checkbox-inner{ width:26px; height:26px; cursor: pointer; background-position:-79px -304px !important;}
input[type='checkbox']{display: none;}
.cust-list.main-container .k-icon{font-size: 14px !important;}
/* kendo grid end */
.ant-form-item-has-error .ant-input-number, .ant-form-item-has-error .ant-picker{background-color: transparent !important;}
.batch-payment-table .invoice-table .ant-table-container{border: 1px solid rgba(255, 255, 255, 0.20);}
.add-merchant.invoice-modal.cust-preview-modal .ant-modal{width: 1320px !important;}
.add-merchant.invoice-modal.cust-preview-modal .ant-modal-content {left: -8px !important;}
.ant-modal-root:has(.invoice-modal.cust-preview-modal) .ant-modal-mask{display: block;}
/* dashboard graph */
.highcharts-background{fill:transparent !important;color:#ffffff !important}
.highcharts-grid, .highcharts-title{color:#ffffff !important;fill:#ffffff !important;font-weight:500 !important;font-size:22px !important;}
.highcharts-axis-labels.highcharts-yaxis-labels text, .highcharts-axis-labels.highcharts-xaxis-labels text, .highcharts-legend-item.highcharts-line-series text{fill:#ffffff !important}
.payment-links.bd-payment-links .k-grid-container{height:400px}
.merchants-section .ant-collapse{height:436px;}
/* send payments */
.wd-entry {border-bottom: 1px solid #6269a3 !important;}
.summary {background-color: var(--bgCard);border-radius: 20px;margin: 30px 0;padding: 30px 0;}
.address-search .ant-input-affix-wrapper {background: #1b182e;border-radius: 25px !important;cursor: pointer !important;}
.payee-inblock, .summary-panel {margin: auto;padding: 12px 0;}
.get-code .c-notallowed, .get-code .text-yellow {color: #a9a9a9;}
.otp-verify .ant-form-item-extra span {color: #a9a9a9;font-size: 14px;text-transform: capitalize;}
.text-red-colr {color: #ff5e43 !important;font-weight: 500 !important;}
.payee-inblock .bal-title {margin-bottom: 35px;margin-top: 10px;}
.ant-alert-message, .star {color: #ff4d4f;}
.note-editor-error-border{border-color: #ff4d4f !important}
.icon-darkbg {background: hsla(0, 0%, 95%, .1);border-radius: 50%;padding: 10px;height: 52px;width: 52px;display: flex;justify-content: center; transform: scale(0.8);}
.fiat-view .summary .summary-list-item:last-child {border-bottom: none;}
.summary-list-item:last-child {margin-bottom: 0;}
hr {background-color: #2a2a2a;border: none;height: 1px;width: 465px;}
.ant-switch {background: linear-gradient(89.35deg, #6613ba 19.31%, #2c00aa 51.88%) !important;margin: 0 8px;}
.accounttype-radio .newcustome-radiocheck .ant-radio-inner {background-color: #040015;border-color: #204483 !important;}
.newcustome-radiocheck span {color: rgba(250, 252, 254, .8);}
.netwok-stye .coin-item.active {background: linear-gradient(89deg, rgba(102, 19, 186, .6) 31.31%, rgba(44, 0, 170, .6) 94.88%) !important;border: none !important;color: #fff;}
.deposit-crypto .qr-image {background: #fff;margin: 30px 0;padding: 10px;}
.status-label {border-radius: 8px;color: #fff;font-size: 16px;font-weight: 500;padding: 4px 9px;}
.smm-icons .icon {margin: 0 6px;}
.coin-title.td-red {color: red;}
.bg-red {background-color: red;}
.nodate-payees{display: flex;align-items: center;justify-content: center;height: 500px;}